import { ModalElement as KassmaModalElement, IModalElementProps } from '@kassma-team/kassma-toolkit/lib';
import { useDispatch } from 'react-redux';

const ModalElement = (props: IModalElementProps) => {
  const dispatch = useDispatch();

  return <KassmaModalElement {...props} dispatch={dispatch} />;
};

export default ModalElement;
