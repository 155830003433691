import useUpdatingForm from '../../../hooks/widgets/useUpdatingForm';
import { WidgetType } from '../../../utils/enums';
import ModalForm from '../../modals/ModalForm';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { IFormField, IWidgetModal, widgetItemSelector, widgetModalSelector } from '@kassma-team/kassma-toolkit';
import { useSelector } from 'react-redux';
import { IIntranetCurrency, IIntranetCurrencyFormData } from '../../../interfaces/widgets/intranetCurrencies';
import { intranetCurrencyActionCreators } from '../../../actions/widgets/currency';
import { Dispatch } from 'redux';
import useCurrencyFields from '../../../hooks/widgets/currencies/useCurrencyFields';

export const INTRANET_CURRENCY_UPDATE_FORM = `INTRANET_CURRENCY_UPDATE_FORM`;

const onSubmit = (values: IIntranetCurrencyFormData, dispatch: Dispatch) => {
  dispatch(intranetCurrencyActionCreators.update(values));
};

const CurrencyUpdating = (props: InjectedFormProps) => {
  const modal: IWidgetModal = useSelector(widgetModalSelector(WidgetType.INTRANET_CURRENCIES));
  useUpdatingForm<IIntranetCurrency[]>({
    widget: WidgetType.INTRANET_CURRENCIES,
    actionCreators: intranetCurrencyActionCreators,
    initialize: props.initialize,
    payload: {
      codes: [modal?.code],
    },
    itemAdapter: (item) => {
      const currentItem = item[0];
      if (!currentItem.name && !currentItem.is_crypto) {
        currentItem.name = currentItem.code;
      }

      return currentItem;
    },
  });

  const item: any = useSelector(widgetItemSelector(WidgetType.INTRANET_CURRENCIES));

  const values = useSelector(getFormValues(INTRANET_CURRENCY_UPDATE_FORM));

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const is_crypto = values?.is_crypto;

  const fields: IFormField[] = useCurrencyFields({
    is_crypto,
    creating: false,
  });

  return (
    <ModalForm
      {...props}
      onSubmit={onSubmit}
      actionCreators={intranetCurrencyActionCreators}
      submitText="common.update"
      fields={fields}
      title="currencies.currencyUpdating"
      widget={WidgetType.INTRANET_CURRENCIES}
      loading={!item}
    />
  );
};

export default reduxForm({ form: INTRANET_CURRENCY_UPDATE_FORM })(CurrencyUpdating as any);
