import { useState } from 'react';

import { LogsColumn } from 'utils/enums';

const useLogsTableTitles = (): ITableTitleField[] => {
  const [titles] = useState([
    {
      title: `logs.walletType`,
      id: LogsColumn.WALLET_TYPE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `logs.walletIdentifier`,
      id: LogsColumn.IDENTIFIER,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `logs.errorType`,
      id: LogsColumn.ERROR_TYPE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `logs.category`,
      id: LogsColumn.CATEGORY,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `logs.message`,
      id: LogsColumn.MESSAGE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `logs.date`,
      id: LogsColumn.DATE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
  ]);

  return titles;
};

export default useLogsTableTitles;

interface ITableTitleField {
  title: string;
  id: string;
  desktop?: boolean;
  mobile?: boolean;
  disabled?: boolean;
  hide?: boolean;
}
