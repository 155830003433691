import { AxiosPromise } from 'axios';
import {
  ICounterpartyCreatingFormData,
  ICounterpartyItemResponse,
  ICounterpartyListResponse,
  ICounterpartyUpdatingFormData,
} from '../../interfaces/widgets/counterparties';
import { IAxiosResponseData } from '@kassma-team/kassma-toolkit';
import { BaseApi } from '../common/BaseApi';

interface ICFilterItem {
  id: number;
  name: string;
}

interface ICFilterListResponse extends IAxiosResponseData {
  data: ICFilterItem[];
}

interface IEndToEndAuthorizedParams {
  counterparty_id: number;
}

export class CounterpartyApi extends BaseApi {
  createCounterparty = (data: ICounterpartyCreatingFormData): AxiosPromise<ICounterpartyItemResponse> => {
    return this.makeFetch(``, { method: `POST`, data, formData: true });
  };

  loadCounterparties = (queryParams: any): AxiosPromise<ICounterpartyListResponse> =>
    this.makeFetch(``, { queryParams });

  loadCounterparty = (id: number): AxiosPromise<ICounterpartyItemResponse> => this.makeFetch(`{id}`, {}, { id });

  updateCounterparty = (id: number, data: ICounterpartyUpdatingFormData): AxiosPromise<ICounterpartyItemResponse> => {
    return this.makeFetch(`{id}`, { data, method: `PUT` }, { id });
  };

  deleteCounterparty = (id: string): AxiosPromise => {
    return this.makeFetch(`remove`, { method: `POST`, data: { counterparty_id: id } });
  };

  approveCounterparty = (id: string): AxiosPromise => {
    return this.makeFetch(`approve`, { method: `POST`, data: { counterparty_id: id } });
  };

  loadCounterpartiesFilterList = (): AxiosPromise<ICFilterListResponse> => this.makeFetch(`for-filter`);

  loadCounterpartyCreatingData = (): AxiosPromise => this.makeFetch(`deploy-components`);

  refreshCounterpartiesStatuses = (): AxiosPromise<ICounterpartyListResponse> => {
    return this.makeFetch(`refresh-status`, { method: `POST` });
  };

  getEndToEndAuthorized = (data: IEndToEndAuthorizedParams): AxiosPromise => {
    return this.makeFetch(`referral`, { method: `POST`, data });
  };
}

const counterpartyApi = new CounterpartyApi(`counterparty`);

export default counterpartyApi;
