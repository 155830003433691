import startsWith from 'lodash/startsWith';
import size from 'lodash/size';
import isObject from 'lodash/isObject';
import each from 'lodash/each';
import moment from 'moment';
import { AxiosError } from 'axios';

export const getNormalizedUrl = (url: string): string => (url.endsWith(`/`) ? url : `${url}/`);

export const amountField = (value: string): string => {
  value = value.replace(/,/g, `.`);
  // eslint-disable-next-line
  value = value.replace(/[^\d\.]/g, ``);

  if (startsWith(value, `0`) && size(value) > 1 && !startsWith(value, `.`, 1)) {
    value = `0.${value.slice(1)}`;
  }

  return value;
};

export const isValidURL = (str: string): boolean => {
  const pattern = new RegExp(
    `^(https?:\\/\\/)` + // protocol
      `((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|` + // domain name
      `((\\d{1,3}\\.){3}\\d{1,3}))` + // OR ip (v4) address
      `(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*` + // port and path
      `(\\?[;&a-z\\d%_.~+=-]*)?` + // query string
      `(\\#[-a-z\\d_]*)?$`,
    `i`
  ); // fragment locator

  return pattern.test(str);
};

export const isValidHttpsURL = (str: string): boolean => {
  const pattern = new RegExp(
    `^(https:\\/\\/)` + // protocol
      `((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|` + // domain name
      `((\\d{1,3}\\.){3}\\d{1,3}))` + // OR ip (v4) address
      `(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*` + // port and path
      `(\\?[;&a-z\\d%_.~+=-]*)?` + // query string
      `(\\#[-a-z\\d_]*)?$`,
    `i`
  ); // fragment locator

  return pattern.test(str);
};

export const displayingDateInputFormat = (date: string, time = ``): string => {
  if (!date) {
    return ``;
  }

  if (time) {
    return `${moment(date).format(`DD-MM-YYYY`)} ${time}`;
  }

  return moment(date).format(`DD-MM-YYYY`);
};

export const normalizeErrorMessage = (err: Record<string, unknown> | string, onlyValue = false): string => {
  if (isObject(err)) {
    let message = ``;

    each(err, (value, key) => {
      if (onlyValue) {
        message += value;
      } else {
        message += `${key}: ${value}`;
      }
      message += `\n`;
    });

    return message;
  }

  return err;
};

export const getErrorMessage = (
  err?: AxiosError,
  {
    normalize = false,
    defaultValue = ``,
    onlyValue = false,
  }: {
    normalize?: boolean;
    defaultValue?: string;
    onlyValue?: boolean;
  } = {}
): string | Record<string, unknown> => {
  const message = err?.response?.data?.message;

  if (!message) {
    return defaultValue || err?.message || ``;
  }

  return normalize ? normalizeErrorMessage(message, onlyValue) : message;
};

export interface IError {
  target: string;
  code: number;
  message: string;
}

export const formatErrors = (errors: IError[]): Record<string, unknown> => {
  const obj: Record<string, unknown> = {};
  errors.forEach((err) => {
    const { target, message } = err;
    obj[target] = message;
  });

  return obj;
};
