import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { authReducer, formReducer, configReducer } from '@kassma-team/kassma-toolkit/lib';

import statistics from 'reducers/statistics';
import { widgets } from 'reducers/widgets/widgets';

import rootHistory from 'rootHistory';

const appReducer = combineReducers({
  router: connectRouter(rootHistory),
  form: formReducer,
  loadingBar: loadingBarReducer,
  statistics,
  widgets: widgets,
  auth: authReducer,
  toastr: toastrReducer,
  config: configReducer,
});

export default appReducer;
