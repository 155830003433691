import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './AddButton.scss';

type Props = {
  onClick: () => void;

  children?: never;
};

export const AddButton: FC<Props> = ({ onClick }) => {
  const [t] = useTranslation();

  return (
    <button data-test-id="add-hint-button" type="button" className="AddButton" onClick={onClick}>
      {t(`common.add`)}
    </button>
  );
};
