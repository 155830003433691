import { Permission, WidgetType } from 'utils/enums';
import { settingsActionCreators } from 'actions/widgets/settings';
import { PageContainer, WidgetContainer } from '@kassma-team/kassma-toolkit';
import { Helmet } from 'react-helmet';
import Header from '../../../components/header/Header';
import SettingsBlockWrap from './commonSettings/SettingsBlockWrap';
import ProfileSettings from './commonSettings/ProfileSettings';
import TimezoneSettings from './commonSettings/TimezoneSettings';
import RouteTabs from '../../../components/widgets/RouteTabs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { hasAccessSelector } from '../../../selectors/auth';
import { ISettingsFormData } from '../../../interfaces/widgets/settings';
import { Dispatch } from 'redux';
import pick from 'lodash/pick';
import { InjectedFormProps, reduxForm } from 'redux-form';
import useUpdatingForm from '../../../hooks/widgets/useUpdatingForm';
import RegularForm from '../../../components/form/RegularForm';
import { SETTINGS_FORM_NAME } from '../../../utils/constants';

const onSubmit = (values: ISettingsFormData, dispatch: Dispatch) => {
  dispatch(settingsActionCreators.updateItem(pick(values, `counterparty_refresh_period`)));
};

let Form: any = (props: InjectedFormProps<Partial<ISettingsFormData>>) => {
  const [t] = useTranslation();

  useUpdatingForm<ISettingsFormData>({
    widget: WidgetType.SETTINGS,
    actionCreators: settingsActionCreators,
    initialize: props.initialize,
  });

  return (
    <RegularForm
      {...props}
      fields={[
        {
          name: `counterparty_refresh_period`,
          numeric: true,
          required: true,
          label: t(`settings.counterpartyUpdatingPeriod`),
        },
      ]}
      submitText={t(`common.save`)}
    />
  );
};

Form = reduxForm<ISettingsFormData>({
  form: SETTINGS_FORM_NAME,
  onSubmit,
})(Form as any) as any;

const Settings = () => {
  const [t] = useTranslation();

  const setTimezoneAccess = useSelector(hasAccessSelector(Permission.TIMEZONE_SET));

  return (
    <PageContainer>
      <Helmet>
        <title>{t(`settings.settings`)}</title>
      </Helmet>
      <Header />
      <WidgetContainer title="settings.settings">
        <div className="block p-20">
          <Form />
          <SettingsBlockWrap title="settings.profileSettings">
            <ProfileSettings />
          </SettingsBlockWrap>
          {setTimezoneAccess && (
            <SettingsBlockWrap title="settings.timeProfile">
              <TimezoneSettings />
            </SettingsBlockWrap>
          )}
        </div>
      </WidgetContainer>
    </PageContainer>
  );
};

export default Settings;
