import { counterpartiesActionCreators } from '../../../actions/widgets/counterparties';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import RemovingModal from '../../modals/RemovingModal';
import { WidgetType } from '../../../utils/enums';

const CounterpartyDelete: FC = () => {
  const [t] = useTranslation();

  return (
    <RemovingModal
      title={t(`counterparties.counterpartyConfirmation`)}
      actionCreators={counterpartiesActionCreators}
      widget={WidgetType.COUNTERPARTIES}
      content={t(`counterparties.areYouSureWantToConfirmCounterparty`)}
    />
  );
};

export default CounterpartyDelete;
