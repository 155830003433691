import React, { FC, useState, useCallback, useEffect, useRef } from 'react';
import { nanoid } from 'nanoid';
import { FormType, Hint } from 'api/translations/TranslationsApi';
import { useTranslation } from 'react-i18next';
import { stateToHTML } from 'draft-js-export-html';
import { Field } from '../../components/Field';
import { EditorState } from 'draft-js';
import {
  useHintsCtx,
  useCurrentFormTypeCtx,
  useCurrentPaymentSystemCtx,
  useLanguagesCtx,
  useModifiedHintsCtx,
  useFormWasSubmittedCtx,
} from '../../ctx';
import { AddButton } from '../../components/AddButton';

import './TranslatesList.scss';

type Args = {
  hints: Array<Hint>;

  paymentSystemId?: string;
  typeOfForm?: FormType[`type`];
};

const isHintWasModified = (initialHint?: Hint, currentHint?: Hint): boolean => {
  if (initialHint && currentHint) {
    // Если количество переводов не равно. Был удален, или добавлен.
    if (initialHint.translations.length !== currentHint.translations.length) {
      return true;
    }

    // Если есть отличие хотя бы в 1 языке
    if (
      currentHint.translations.some(
        ({ language }) => !initialHint.translations.find((trans) => trans.language === language)
      )
    ) {
      return true;
    }

    // Если есть отличие хотя бы в 1 тексте
    if (
      currentHint.translations.some(
        ({ text }) => !initialHint.translations.find((trans) => trans.text.normalize() === text.normalize())
      )
    ) {
      return true;
    }
  }

  return false;
};

const findTranslatesInHint = ({ paymentSystemId, hints, typeOfForm }: Args) => {
  console.log({ hints });

  return hints.find(({ uuid, form_type }) => uuid === paymentSystemId && form_type === typeOfForm);
};

export const TranslatesList: FC = () => {
  const [t] = useTranslation();
  const { currentPaymentSystem } = useCurrentPaymentSystemCtx();
  const { currentFormType } = useCurrentFormTypeCtx();
  const { modifiedHints, setModifiedHints } = useModifiedHintsCtx();
  const { hints, handleUpdateHint, handleCreateHint, isHintUpdating } = useHintsCtx();
  const { languages } = useLanguagesCtx();
  const initialHint = useRef<Hint | undefined>(undefined);
  const [localHint, setLocalHint] = useState<Hint | undefined>(undefined);
  const { isFormWasSubmitted, setFormSubmitted } = useFormWasSubmittedCtx();
  const isNeedToCreateHint = useRef(false);
  const [isWithoutEngErr, setIsWithoutEngErr] = useState(false);

  const isWasM = isHintWasModified(initialHint.current, localHint);

  useEffect(() => {
    if (isWasM) {
      localHint && setModifiedHints([...modifiedHints, localHint]);
    } else {
      setModifiedHints(modifiedHints.filter((el) => el.uuid !== localHint?.uuid));
    }
  }, [isWasM]);

  useEffect(() => {
    if (currentFormType?.type && currentPaymentSystem?.code) {
      initialHint.current = findTranslatesInHint({
        hints,
        typeOfForm: currentFormType?.type,
        paymentSystemId: currentPaymentSystem?.code,
      });

      setLocalHint(
        findTranslatesInHint({
          hints,
          typeOfForm: currentFormType?.type,
          paymentSystemId: currentPaymentSystem?.code,
        })
      );
    }
  }, [hints, currentFormType?.type, currentPaymentSystem?.code]);

  const handleAddTranslate = () => {
    if (localHint) {
      setLocalHint({
        ...localHint,
        translations: [
          ...localHint.translations,
          {
            clientId: nanoid(),
            language: ``,
            text: ``,
          },
        ],
      });

      return;
    }

    isNeedToCreateHint.current = true;

    const newHint: Hint = {
      uuid: `client-id-${nanoid()}`,
      wallet_type: currentPaymentSystem?.code || ``,
      created_at: ``,
      updated_at: ``,
      form_type: currentFormType?.type || ``,
      is_default: true,
      name: `${currentPaymentSystem?.name} (default)`,
      translations: [
        {
          clientId: nanoid(),
          language: ``,
          text: ``,
        },
      ],
    };

    setLocalHint(newHint);
    setModifiedHints([...modifiedHints, newHint]);
  };

  const handleChangeLang = useCallback(
    (language: string, clientId?: string) => (option: { value: string; text: string } | string) => {
      if (localHint) {
        if (typeof option === `object`) {
          if (option.value === `en` && isWithoutEngErr) setIsWithoutEngErr(false);
        }

        if (typeof option === `string`) {
          if (option === `en` && isWithoutEngErr) setIsWithoutEngErr(false);
        }

        if (clientId) {
          setLocalHint({
            ...localHint,
            translations: localHint.translations.map((trans) => {
              if (trans.clientId === clientId) {
                return { ...trans, language: typeof option === `object` ? option.value : option };
              }

              return trans;
            }),
          });

          return;
        }

        setLocalHint({
          ...localHint,
          translations: localHint.translations.map((trans) => {
            if (trans.language === language) {
              return { ...trans, language: typeof option === `object` ? option.value : option };
            }

            return trans;
          }),
        });
      }
    },
    [localHint]
  );

  const handleChangeText = useCallback(
    (language: string, clientId?: string) => (text: EditorState) => {
      if (localHint) {
        if (clientId) {
          setLocalHint({
            ...localHint,
            translations: localHint.translations.map((trans) => {
              if (trans.clientId === clientId) {
                return {
                  ...trans,
                  text: stateToHTML(text.getCurrentContent(), {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error Null - отключает тег. undefined, как описано в типе - нет.
                    defaultBlockTag: null,
                    inlineStyles: {
                      BOLD: { element: `b` },
                    },
                  }),
                  prevState: text,
                };
              }

              return trans;
            }),
          });

          return;
        }

        setLocalHint({
          ...localHint,
          translations: localHint.translations.map((trans) => {
            if (trans.language === language) {
              return {
                ...trans,
                text: stateToHTML(text.getCurrentContent(), {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error Null - отключает тег. undefined, как описано в типе - нет.
                  defaultBlockTag: null,
                  inlineStyles: {
                    BOLD: { element: `b` },
                  },
                }),
                prevState: text,
              };
            }

            return trans;
          }),
        });
      }
    },
    [localHint]
  );

  const handleRemove = useCallback(
    (language: string, clientId?: string) => () => {
      if (localHint) {
        if (clientId) {
          const translations = localHint.translations.filter((trans) => trans.clientId !== clientId);

          if (!translations.length && initialHint.current === undefined) {
            setLocalHint(undefined);
            setModifiedHints([]);

            return;
          }

          setLocalHint({
            ...localHint,
            translations: translations,
          });

          return;
        }

        setLocalHint({
          ...localHint,
          translations: localHint.translations.filter((trans) => trans.language !== language),
        });
      }
    },
    [localHint]
  );

  return (
    <ul className="TranslatesList">
      {currentFormType && <AddButton onClick={handleAddTranslate} />}
      {localHint &&
        localHint.translations.map(({ clientId, language, text }, i) => {
          return (
            <li key={clientId || `${language}-${i}`} className="TranslatesList__item">
              <Field
                lang={language}
                text={text}
                selectedLangs={localHint.translations.map(({ language }) => language)}
                onChangeLang={handleChangeLang(language, clientId)}
                onChangeText={handleChangeText(language, clientId)}
                onRemove={handleRemove(language, clientId)}
                langs={languages}
                isRemoveBtnShowed={language !== `en`}
                isFormSubmitted={isFormWasSubmitted}
              />
            </li>
          );
        })}
      {modifiedHints.length > 0 && localHint && (
        <>
          {isWithoutEngErr && <p className="TranslatesList__error">{t(`common.engIsRequired`)}</p>}

          <button
            className="TranslatesList__save-btn"
            onClick={() => {
              setFormSubmitted(true);

              if (
                localHint?.translations.some(
                  ({ language, text }) => !language || !text || text === `<br>` || text.length > 300
                )
              ) {
                return;
              }

              if (!localHint?.translations.find(({ language }) => language === `en`)) {
                setIsWithoutEngErr(true);

                return;
              } else {
                setIsWithoutEngErr(false);
              }

              isNeedToCreateHint.current
                ? handleCreateHint({
                    wallet_type: localHint?.wallet_type || ``,
                    name: localHint?.name || ``,
                    form_type: localHint?.form_type || ``,
                    translations:
                      localHint?.translations?.map((el) => ({
                        language: el.language,
                        text: el.text,
                      })) || [],
                  })().then(() => (isNeedToCreateHint.current = false))
                : handleUpdateHint(localHint?.uuid || ``, {
                    name: localHint?.name || ``,
                    translations: localHint?.translations || [],
                  })();
            }}
            type="button"
            disabled={isHintUpdating}
          >
            <span className="TranslatesList__save-btn_content">
              {isHintUpdating ? <i className="fa fa-spinner fa-spin" /> : t(`common.save`)}
            </span>
          </button>
        </>
      )}
    </ul>
  );
};
