import { widgetActionCreators } from '@kassma-team/kassma-toolkit/lib';
import { WidgetType } from 'utils/enums';
import { CREATE_HIDDEN_FIELDS_FORM_NAME, HIDDEN_SETTINGS_FORM_NAME, SETTINGS_FORM_NAME } from 'utils/constants';

export const settingsActionCreators = widgetActionCreators({
  widget: WidgetType.SETTINGS,
  labels: { itemLabel: `params` },
  itemUpdatingForm: SETTINGS_FORM_NAME,
});

export const timezonesActionCreators = widgetActionCreators({
  widget: WidgetType.TIMEZONES,
  labels: { listLabel: `available_timezones` },
});

export const hiddenSettingsActionCreators = widgetActionCreators({
  widget: WidgetType.HIDDEN_SETTINGS,
  labels: { listLabel: `fields` },
  itemUpdatingForm: HIDDEN_SETTINGS_FORM_NAME,
});

export const hiddenFieldsActionCreators = widgetActionCreators({
  widget: WidgetType.HIDDEN_FIELDS,
  creatingForm: CREATE_HIDDEN_FIELDS_FORM_NAME,
  labels: { listLabel: `data` },
});
