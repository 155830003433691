import axios from 'axios';
import { useState, useEffect } from 'react';
import api, { WalletType } from 'api/translations/TranslationsApi';

export type UsePaymentSystems = {
  isLoading: boolean;
  paymentSystems: Array<WalletType>;
};

// TODO Вместо флага передавать функцию сравнения.
export function usePaymentSystems({ isSorted }: { isSorted?: boolean }): UsePaymentSystems {
  const [isLoading, setIsLoading] = useState(false);
  const [paymentSystems, setPaymentSystems] = useState<Array<WalletType>>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const { data } = await api.fetchPaymentSystems();

        if (data.status === `success`) {
          setPaymentSystems(data.data);
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          console.log(e);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return {
    isLoading,
    paymentSystems: isSorted ? paymentSystems.sort((a, b) => a.name.localeCompare(b.name)) : paymentSystems,
  };
}
