import React, { FC, memo } from 'react';
import './RemoveButton.scss';
import cn from 'classnames';

type Props = {
  onRemove: () => void;

  hidden?: boolean;
  children?: never;
};

export const RemoveButton: FC<Props> = memo(({ onRemove, hidden }) => {
  return (
    <button type="button" className={cn(`RemoveButton`, { 'RemoveButton--invisible': hidden })} onClick={onRemove}>
      <i className="fa fa-close" />
    </button>
  );
});
