import { IHiddenFieldRowProps } from 'interfaces/widgets/hiddenFields';
import MobileTableRow from '../../../../components/widgets/MobileTableRow';
import { useTranslation } from 'react-i18next';
import { FieldCast, ModalType } from '../../../../utils/enums';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { hiddenFieldsActionCreators } from '../../../../actions/widgets/settings';

export const HiddenFieldsRow = ({ desktopSize, id, name, description, value_cast, value }: IHiddenFieldRowProps) => {
  const [t] = useTranslation();

  const castLabels: Record<FieldCast, string> = {
    [FieldCast.STRING]: `hiddenFields.string`,
    [FieldCast.INTEGER]: `hiddenFields.integer`,
    [FieldCast.BOOLEAN]: `hiddenFields.boolean`,
    [FieldCast.JSON]: `hiddenFields.json`,
    [FieldCast.ARRAY]: `hiddenFields.array`,
  };

  const castField = t(castLabels[value_cast]);

  const valueField = useMemo(() => {
    if (value_cast === FieldCast.BOOLEAN) {
      if (+value) {
        return `true`;
      }

      return `false`;
    }

    return value;
  }, [value_cast, value]);

  const dispatch = useDispatch();

  const onDeleteClick = useCallback(() => {
    dispatch(hiddenFieldsActionCreators.showModal({ type: ModalType.DELETE, id, name }));
  }, [dispatch, id]);

  const controls = (
    <div className="counterparty-btn__group">
      <button
        // className="dropdown__list-button"
        onClick={onDeleteClick}
      >
        <i
          className="fa fa-trash"
          style={{
            fontSize: `20px`,
          }}
        />
      </button>
    </div>
  );

  if (desktopSize) {
    return (
      <tr>
        <td>{id}</td>
        <td>{name}</td>
        <td>{castField}</td>
        <td>{description}</td>
        <td>{Array.isArray(valueField) ? JSON.stringify(valueField) : valueField}</td>
        <td>{controls}</td>
      </tr>
    );
  }

  return (
    <MobileTableRow>
      <tr className="first-mobile-row">
        <td>{t(`hiddenFields.id`)}</td>
        <td>{id}</td>
      </tr>
      <tr>
        <td>{t(`common.name`)}</td>
        <td>{name}</td>
      </tr>
      <tr>
        <td>{t(`hiddenFields.type`)}</td>
        <td>{castField}</td>
      </tr>
      <tr>
        <td>{t(`hiddenFields.description`)}</td>
        <td>{description}</td>
      </tr>
      <tr>
        <td>{t(`hiddenFields.value`)}</td>
        <td>{valueField}</td>
      </tr>
      <tr>
        <td>{t(`common.actions`)}</td>
        <td>{controls}</td>
      </tr>
    </MobileTableRow>
  );
};
