import axios from 'axios';
import { useState, useEffect } from 'react';
import api, { Lang } from 'api/translations/TranslationsApi';

export type UseLanguages = {
  isLoading: boolean;
  languages: Array<Lang>;
};

export function useLanguages(): UseLanguages {
  const [isLoading, setIsLoading] = useState(false);
  const [languages, setLanguages] = useState<UseLanguages[`languages`]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const { data } = await api.fetchLangs();

        if (data.status === `success`) {
          setLanguages(data.data);
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          console.log(e);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return {
    isLoading,
    languages,
  };
}
