import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { replace } from 'connected-react-router';
import { authStatusSelector, AuthStatus } from '@kassma-team/kassma-toolkit/lib';

const userIsAnonymous = connectedRouterRedirect({
  redirectPath: `/`,
  authenticatedSelector: (state: any) => {
    const status = authStatusSelector(state);

    return status === AuthStatus.GUEST;
  },
  wrapperDisplayName: `UserIsAnonymous`,
  allowRedirectBack: false,
  redirectAction: replace,
});

export default userIsAnonymous;
