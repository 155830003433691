import { BaseApi } from '../common/BaseApi';
import { AxiosPromise } from 'axios';
import { IServerFormData } from '../../interfaces/widgets/servers';

export class ServerApi extends BaseApi {
  createServer = (data: IServerFormData): AxiosPromise => this.makeFetch(``, { method: `POST`, data });

  loadDeletedServers = (): AxiosPromise => this.makeFetch(`with-deleted`);

  loadServers = (): AxiosPromise => this.makeFetch(``);

  deleteServer = (id: number): AxiosPromise => this.makeFetch(`{id}`, { method: `DELETE` }, { id });
}

const serverApi = new ServerApi(`server`);

export default serverApi;
