import { ReactNode, useCallback, useState } from 'react';
import * as React from 'react';
import { push } from 'connected-react-router';
import { Tooltip, Checkbox } from '@kassma-team/kassma-toolkit/lib';
import { serversActionCreators } from 'actions/widgets/servers';
import { ModalType, Permission, WidgetType } from 'utils/enums';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { hasAccessSelector } from 'selectors/auth';
import { IWidgetHeadTitles } from 'interfaces/widgets/widgets';

interface IResult {
  controlPanel: ReactNode;
  titles: IWidgetHeadTitles[];
}

const useServerWidgetParams = (widget: WidgetType): IResult => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const managingAccess = useSelector(hasAccessSelector(Permission.COUNTERPARTY_MANAGE));

  const onCreateRoleClick = useCallback(() => {
    dispatch(serversActionCreators.showModal({ type: ModalType.CREATE }));
  }, [dispatch]);

  const controlPanel = (
    <div className="servers__table-controls">
      {managingAccess && (
        <div className="servers__table-controls-item">
          <button onClick={onCreateRoleClick} className="btn btn-alt-primary mr-10">
            {t(`servers.addServer`)}
          </button>
          <Tooltip insideContent={<span>{t(`servers.addingServerInfo`)}</span>}>
            <i className="fa fa-question-circle-o" />
          </Tooltip>
        </div>
      )}
      <Checkbox
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.checked) {
            dispatch(push(`/deleted-servers`));
          } else {
            dispatch(push(`/servers`));
          }
        }}
        name="deleted-servers-toggled"
        checked={widget === WidgetType.DELETED_SERVERS}
        label={t(`servers.showOnlyDeleted`)}
        wrapClassName="mb-0 servers__table-controls-item"
      />
    </div>
  );

  const [titles] = useState<IWidgetHeadTitles[]>([
    {
      title: `servers.ipAddress`,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `servers.registerDate`,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `servers.expiration`,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.status`,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `servers.specifications`,
      desktop: true,
      mobile: true,
      disabled: true,
    },
  ]);

  return { titles, controlPanel };
};

export default useServerWidgetParams;
