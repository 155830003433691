import { Reducer } from 'redux';
import {
  SHOW_ROLE_SUCCESS_MESSAGE,
  ROLE_REMOVE_REQUESTED,
  ROLE_REMOVE_SUCCEEDED,
  ROLE_CREATING_COUNTERPARTY_STATUS_SUCCEEDED,
} from 'actionTypes';

const actionTypes = new Set([
  SHOW_ROLE_SUCCESS_MESSAGE,
  ROLE_REMOVE_REQUESTED,
  ROLE_REMOVE_SUCCEEDED,
  ROLE_CREATING_COUNTERPARTY_STATUS_SUCCEEDED,
]);

const reducer: Reducer = (state = {}, { type, payload = {}, meta = {} }) => {
  switch (type) {
    case ROLE_REMOVE_REQUESTED:
      return {
        ...state,
        modal: {
          ...state?.modal,
          submitting: true,
        },
      };
    case ROLE_REMOVE_SUCCEEDED:
      return {
        ...state,
        modal: {
          ...state?.modal,
          submitting: false,
        },
      };
    case SHOW_ROLE_SUCCESS_MESSAGE:
      return {
        ...state,
        modal: {
          ...state?.modal,
          showRoleAddMessage: payload,
        },
      };
    case ROLE_CREATING_COUNTERPARTY_STATUS_SUCCEEDED:
      return {
        ...state,
        modal: {
          ...state?.modal,
          data: payload,
        },
      };
    default:
      return state;
  }
};

const roles = { actionTypes, reducer };

export default roles;
