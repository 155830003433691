import { createSelector } from 'reselect';
import map from 'lodash/map';
import toString from 'lodash/toString';
import { widgetSelector, ISelectData } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { IRootState } from 'interfaces/common';
import { ICounterpartiesState, ICounterpartyCreatingData } from 'interfaces/widgets/counterparties';

interface ICounterpartyFilterItem {
  value: string;
  text: string;
}

export const lastUpdatedOfCounterpartiesSelector = createSelector<IRootState, ICounterpartiesState, string | undefined>(
  widgetSelector<ICounterpartiesState>(WidgetType.COUNTERPARTIES),
  (state) => state?.updated_at
);

export const counterpartiesFilterListSelector = createSelector<
  IRootState,
  ICounterpartiesState,
  ICounterpartyFilterItem[]
>(
  widgetSelector<ICounterpartiesState>(WidgetType.FILTER_COUNTERPARTY_LIST),
  (state) =>
    map<any, ICounterpartyFilterItem>(state.items, ({ value, text }) => ({ value: toString(value), text })) || []
);

export const counterpartyCreatingDataLoadingSelector = createSelector<IRootState, ICounterpartiesState, boolean>(
  widgetSelector<ICounterpartiesState>(WidgetType.COUNTERPARTIES),
  (state) => state?.creatingDataLoading || false
);

export const counterpartyCreatingDataErrorSelector = createSelector<
  IRootState,
  ICounterpartiesState,
  string | null | undefined
>(widgetSelector<ICounterpartiesState>(WidgetType.COUNTERPARTIES), (state) => state?.creatingError);

export const counterpartyCreatingDataSelector = createSelector<
  IRootState,
  ICounterpartiesState,
  ICounterpartyCreatingData | null | undefined
>(widgetSelector<ICounterpartiesState>(WidgetType.COUNTERPARTIES), (state) => state?.creatingData);

export const counterpartyAvailableDomainsSelector = createSelector<
  IRootState,
  ICounterpartyCreatingData | null | undefined,
  ISelectData[]
>(counterpartyCreatingDataSelector, (data) => map(data?.available_domains, ({ id, text }) => ({ value: id, text })));

export const counterpartyAvailableServersSelector = createSelector<
  IRootState,
  ICounterpartyCreatingData | null | undefined,
  ISelectData[]
>(counterpartyCreatingDataSelector, (data) => map(data?.available_servers, ({ id, text }) => ({ value: id, text })));

export const counterpartyScrapersSelector = createSelector<
  IRootState,
  ICounterpartyCreatingData | null | undefined,
  ISelectData[]
>(counterpartyCreatingDataSelector, (data) => map(data?.scrapers, ({ id, name }) => ({ value: id, text: name })));

export const counterpartyFileTemplatePathsSelector = createSelector<
  IRootState,
  ICounterpartyCreatingData | null | undefined,
  { template: string }[]
>(counterpartyCreatingDataSelector, (data) => map(data?.templates, ({ path }) => ({ template: path })));

export const counterpartyFileTemplateNamesSelector = createSelector<
  IRootState,
  ICounterpartyCreatingData | null | undefined,
  string[]
>(counterpartyCreatingDataSelector, (data) => map(data?.templates, ({ name }) => name));
