import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getSearch } from 'connected-react-router';
import isObject from 'lodash/isObject';
import queryString from 'query-string';

const useSearchParams = (): Record<string, unknown> => {
  const search = useSelector(getSearch as any) as string;

  return useMemo(() => {
    let obj = queryString.parse(search);
    obj = isObject(obj) ? { ...obj } : {};

    return obj;
  }, [search]);
};

export default useSearchParams;
