import { ReactElement, useState } from 'react';
import { Dispatch } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { IFormField } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { CREATE_SERVER_FORM_NAME } from 'utils/constants';

import ModalForm from 'components/modals/ModalForm';
import { serversActionCreators } from 'actions/widgets/servers';
import { IServerFormData } from 'interfaces/widgets/servers';
import { useTranslation } from 'react-i18next';

const onSubmit = (values: IServerFormData, dispatch: Dispatch) => {
  dispatch(serversActionCreators.create(values));
};

const ServerCreating = (props: InjectedFormProps<IServerFormData>): ReactElement => {
  const [t] = useTranslation();

  const [fields] = useState<IFormField[]>([
    {
      name: `ip`,
      label: t(`servers.ipAddress`),
      required: true,
      ip: true,
    },
    {
      name: `login`,
      label: t(`servers.login`),
      required: true,
      maxLength: 20,
    },
    {
      name: `password`,
      label: t(`common.password`),
      required: true,
      password: true,
      maxLength: 20,
    },
    {
      name: `ram`,
      label: t(`servers.ram`),
      required: true,
      numeric: true,
      description: t(`servers.ramFieldInfo`),
      maxAmount: 4294967295,
    },
    {
      name: `cpu`,
      label: t(`servers.cpu`),
      required: true,
      numeric: true,
      description: t(`servers.cpuFieldInfo`),
      maxAmount: 4294967295,
    },
    {
      name: `rom`,
      label: t(`servers.rom`),
      required: true,
      numeric: true,
      description: t(`servers.romFieldInfo`),
      maxAmount: 4294967295,
    },
  ]);

  return (
    <ModalForm
      {...props}
      onSubmit={onSubmit}
      actionCreators={serversActionCreators}
      submitText="common.create"
      fields={fields}
      title="servers.serverAdding"
      widget={WidgetType.SERVERS}
    />
  );
};

export default reduxForm({ form: CREATE_SERVER_FORM_NAME })(ServerCreating as any);
