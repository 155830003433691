import { BaseApi } from '../common/BaseApi';
import { AxiosPromise } from 'axios';

export class TimezonesApi extends BaseApi {
  loadTimezoneList = (): AxiosPromise => this.makeFetch(`list`);
}

const timezonesApi = new TimezonesApi(`timezones`);

export default timezonesApi;
