import { FC, useContext, createContext, useState, Dispatch, SetStateAction } from 'react';
import { Hint } from 'api/translations/TranslationsApi';

type ModifiedHintsCtx = {
  setModifiedHints: Dispatch<SetStateAction<Array<Hint>>>;
  modifiedHints: Array<Hint>;
};

const modifiedHintsCtx = createContext<ModifiedHintsCtx | undefined>(undefined);

export const ModifiedHintsCtx: FC = ({ children }) => {
  const [modifiedHints, setModifiedHints] = useState<Array<Hint>>([]);

  const val: ModifiedHintsCtx = {
    modifiedHints,
    setModifiedHints,
  };

  return <modifiedHintsCtx.Provider value={val}>{children}</modifiedHintsCtx.Provider>;
};

export function useModifiedHintsCtx(): ModifiedHintsCtx {
  const context = useContext(modifiedHintsCtx);

  if (context === undefined) {
    throw new Error(`Is need to use useModifiedHintsContext inside a ModifiedHintsContext component`);
  }

  return context;
}
