import { ReactElement, useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import { Dropdown, IBadgeProps, IDeleteModalPayload } from '@kassma-team/kassma-toolkit/lib';
import { useDispatch, useSelector } from 'react-redux';

import { ModalType, Permission, ServerStatus, WidgetType } from 'utils/enums';
import { formatDate } from 'utils/formatDate';
import { serversActionCreators } from 'actions/widgets/servers';
import { hasAccessSelector } from 'selectors/auth';
import { IServerRowProps } from 'interfaces/widgets/servers';

import MobileTableRow from 'components/widgets/MobileTableRow';

import { ReactComponent as Details } from 'assets/media/icons/details.svg';
import cpuIcon from 'assets/media/icons/cpu.svg';
import ramIcon from 'assets/media/icons/ram-memory.svg';
import romIcon from 'assets/media/icons/rom.svg';

const statusProps: IBadgeProps = {
  [ServerStatus.ENGAGED]: {
    text: `servers.engaged`,
    badge: `badge-danger`,
  },
  [ServerStatus.FREE]: {
    text: `servers.free`,
    badge: `badge-success`,
  },
  [ServerStatus.NOT_AVAILABLE]: {
    text: `servers.notAvailable`,
    badge: `badge-dark`,
  },
  [ServerStatus.DELETED]: {
    text: `servers.deleted`,
    badge: `badge-secondary`,
  },
};

interface ISpecification {
  postfix: string;
  icon: string;
  value: number;
}

const Specification = ({ postfix, icon, value }: ISpecification) => {
  return (
    <div className="server-row__specifications-item">
      <img src={icon} alt="" className="server-row__specifications-item-logo" />
      <span>
        {value} {postfix}
      </span>
    </div>
  );
};

const ServerRow = ({
  status,
  ip,
  system_parameters,
  id,
  registered_at,
  valid_till,
  desktopSize,
  widget,
  deleted_at,
}: IServerRowProps): ReactElement => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const managingAccess = useSelector(hasAccessSelector(Permission.COUNTERPARTY_MANAGE));

  const onDeleteClick = useCallback(() => {
    dispatch(
      serversActionCreators.showModal<IDeleteModalPayload>({
        type: ModalType.DELETE,
        id,
      })
    );
  }, [dispatch, id]);

  const rowStatus = deleted_at ? ServerStatus.DELETED : status;

  const statusField = (
    <div className="multi-badge-cell">
      <span className={classNames(`badge`, statusProps[rowStatus]?.badge)}>{t(statusProps[rowStatus]?.text)}</span>
    </div>
  );

  const controls = widget === WidgetType.SERVERS && (
    <Dropdown
      className="table-actions-button-wrap"
      insideContent={(onClose: () => void) => (
        <button
          className="dropdown__list-button"
          onClick={() => {
            onDeleteClick();
            onClose();
          }}
        >
          <i className="fa fa-trash" />
          {t(`common.delete`)}
        </button>
      )}
    >
      <button type="button">
        <Details />
      </button>
    </Dropdown>
  );

  const specificationsViewData: ISpecification[] = [
    {
      postfix: `Gb`,
      icon: ramIcon,
      value: system_parameters.ram,
    },
    {
      postfix: `CPU`,
      icon: cpuIcon,
      value: system_parameters.cpu,
    },
    {
      postfix: `Gb`,
      icon: romIcon,
      value: system_parameters.rom,
    },
  ];

  const specificationsField = (
    <div className="server-row__specifications">
      {map(specificationsViewData, (props, key) => (
        <Specification {...props} key={key} />
      ))}
    </div>
  );

  const formattedRegisteredAt = formatDate(registered_at);

  if (desktopSize) {
    return (
      <tr>
        <td>{ip}</td>
        <td>{formattedRegisteredAt}</td>
        <td>{valid_till}</td>
        <td>{statusField}</td>
        <td className="domains-row__comment-cell">{specificationsField}</td>
        {managingAccess && <td>{controls}</td>}
      </tr>
    );
  }

  return (
    <MobileTableRow>
      <tr className="first-mobile-row">
        <td>{t(`servers.ipAddress`)}</td>
        <td>{ip}</td>
      </tr>
      <tr>
        <td>{t(`servers.registerDate`)}</td>
        <td>{formattedRegisteredAt}</td>
      </tr>
      <tr>
        <td>{t(`servers.expiration`)}</td>
        <td>{valid_till}</td>
      </tr>
      <tr>
        <td>{t(`common.status`)}</td>
        <td>{statusField}</td>
      </tr>
      <tr>
        <td>{t(`servers.specifications`)}</td>
        <td>{specificationsField}</td>
      </tr>
      {managingAccess && (
        <tr>
          <td>{t(`common.actions`)}</td>
          <td>{controls}</td>
        </tr>
      )}
    </MobileTableRow>
  );
};

export default ServerRow;
