import axios from 'axios';
import { useState, useEffect } from 'react';
import api, { FormType } from 'api/translations/TranslationsApi';

export type UseFormTypes = {
  isLoading: boolean;
  formTypes: Array<FormType>;
};

export function useFormTypes(): UseFormTypes {
  const [isLoading, setIsLoading] = useState(false);
  const [formTypes, setformTypes] = useState<UseFormTypes[`formTypes`]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const { data } = await api.fetchFormTypes();

        if (data.status === `success`) {
          setformTypes(data.data);
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          console.log(e);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return {
    isLoading,
    formTypes,
  };
}
