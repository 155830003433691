import { toastr } from 'react-redux-toastr';
import authApi from '../../api/auth/AuthApi';

import { call, select, put } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import { ToastrEmitter } from 'react-redux-toastr';
import get from 'lodash/get';
import axios, { AxiosError, AxiosPromise } from 'axios';
import {
  AUTH_CHECK_SUCCEEDED,
  i18n,
  REFRESH_TOKEN_FAILED,
  REFRESH_TOKEN_SUCCEEDED,
  refreshPromiseSelector,
  refreshTokenRequest,
} from '@kassma-team/kassma-toolkit';
import { getErrorMessage, IRefreshSagaParams } from '@kassma-team/kassma-toolkit/lib';
import { passwordSelector, userSelector } from '../../selectors/auth';

function* resetUserSaga(e: AxiosError) {
  const status = get(e, `response.status`);
  if (status === 401) {
    yield put({ type: REFRESH_TOKEN_FAILED });
    yield put(push(`/login`));
    yield localStorage.removeItem(`token`);
    yield localStorage.removeItem(`refresh_token`);
  }
}

export interface IGetRefreshSagaParams {
  refreshToken: (refresh_token: string) => AxiosPromise;
  toastr: ToastrEmitter;
}

// Todo: get rid of @ts-ignore
const getRefreshSaga = ({ refreshToken, toastr }: IGetRefreshSagaParams) =>
  function* refreshSaga({
    request,
    onError,
    onSuccess,
    onFinally,
    redirectWhenNoPermissions = false,
    callErrorWhenNoPermissions = false,
    showToastrWhenNoPermissions = true,
    isEndToEndAuthorization,
  }: IRefreshSagaParams & { isEndToEndAuthorization?: boolean }) {
    if (!onError) {
      onError = (err: any) => {
        toastr.error(i18n.t(`common.error`), getErrorMessage(err as AxiosError) as string);
      };
    }

    let refreshPromise = yield select(refreshPromiseSelector);
    if (!refreshPromise) {
      let resp;
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resp = yield call(request);
        if ((resp?.status !== 401 && onSuccess) || resp?.status === `success`) {
          yield call(onSuccess, resp);
        } else if (resp?.data.code === 50001) {
          yield put({ type: REFRESH_TOKEN_FAILED });
          yield put(push(`/login`));
          yield localStorage.removeItem(`token`);
          yield localStorage.removeItem(`refresh_token`);
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          const status = get(e, `response.status`);
          if (status === 403) {
            if (redirectWhenNoPermissions) {
              yield put(replace(`/no-permissions`));
            } else if (showToastrWhenNoPermissions) {
              toastr.error(i18n.t(`common.error`), i18n.t(`common.noRights`));
            }
            if (callErrorWhenNoPermissions) {
              yield call(onError, e);
            }
          } else if (status !== 401 && onError) {
            yield call(onError, e);
          }
        }
      } finally {
        if (onFinally) {
          yield call(onFinally);
        }
      }
      const refresh_token: string = localStorage.getItem(`refresh_token`) || ``;
      // if (refresh_token === `undefined`) {
      //   refresh_token = ``;
      // }
      if (resp && refresh_token && (resp.status === 401 || resp.data.code === 50003)) {
        refreshPromise = yield select(refreshPromiseSelector);
        if (!refreshPromise) {
          try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const promise = refreshToken(refresh_token);
            yield put(refreshTokenRequest(promise));
            const { data, status } = yield promise;
            if (!isEndToEndAuthorization && (status === 401 || data.code === 50003 || data.code === 50001)) {
              throw new Error(`Token refreshing has been failed`);
            }

            if (isEndToEndAuthorization && data.code === 50001) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              if (status === 401 || data.code === 50003) {
                throw new Error(`Token refreshing has been failed`);
              }

              const user = yield select(userSelector);
              const password = yield select(passwordSelector);
              const resp = yield authApi.login({ name: user.name, password });
              yield localStorage.setItem(`token`, resp.data.token);
              yield localStorage.setItem(`refresh_token`, resp.data.refresh_token);
              const promise = refreshToken(resp.data.refresh_token);
              yield put(refreshTokenRequest(promise));
              const { data } = yield promise;

              yield put({ type: REFRESH_TOKEN_SUCCEEDED, payload: data });

              yield put({ type: AUTH_CHECK_SUCCEEDED, payload: { user, refresh_token: resp.data.refresh_token } });
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const block_resp = yield call(request);
              if (onSuccess) {
                yield call(onSuccess, block_resp);
              }

              return;
            }

            yield put({ type: REFRESH_TOKEN_SUCCEEDED, payload: data });
            const user = yield select(userSelector);
            yield put({ type: AUTH_CHECK_SUCCEEDED, payload: { user, refresh_token } });
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const block_resp = yield call(request);
            if (onSuccess) {
              yield call(onSuccess, block_resp);
            }
            if (data.token) {
              yield localStorage.setItem(`token`, data.token);
              yield localStorage.setItem(`refresh_token`, data.refresh_token);
            }
          } catch (e) {
            if (axios.isAxiosError(e)) {
              if (onError) {
                yield call(onError, e);
              }
              yield resetUserSaga(e);
            } else {
              yield put({ type: REFRESH_TOKEN_FAILED });
              yield put(push(`/login`));
              yield localStorage.removeItem(`token`);
              yield localStorage.removeItem(`refresh_token`);
            }
          } finally {
            if (onFinally) {
              yield call(onFinally);
            }
          }
        }
      } else if (!refresh_token) {
        yield put({ type: REFRESH_TOKEN_FAILED });
        yield put(push(`/login`));
        yield localStorage.removeItem(`token`);
        yield localStorage.removeItem(`refresh_token`);
      }
    }

    if (refreshPromise) {
      try {
        yield refreshPromise;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const resp = yield call(request);
        if (onSuccess) {
          yield call(onSuccess, resp);
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          if (onError) {
            yield call(onError, e);
          }
          yield resetUserSaga(e);
        }
      } finally {
        if (onFinally) {
          yield call(onFinally);
        }
      }
    }
  };

const refreshSaga: IRefreshSagaParams = getRefreshSaga({ refreshToken: authApi.refreshToken, toastr });

export default refreshSaga;
