import { IWidgetsMeta, widgetActionCreators } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';

const meta: IWidgetsMeta = {
  widget: WidgetType.PAYMENT_SYSTEMS,
  labels: { listLabel: `payment-systems` },
};

export const paymentSystemActionCreators = widgetActionCreators(meta);

export const filterListPSActionCreators = widgetActionCreators({
  widget: WidgetType.FILTER_LIST_PAYMENT_SYSTEMS,
  labels: { listLabel: `payment-systems` },
});
