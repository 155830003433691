import authApi, { AuthApi } from './auth/AuthApi';
import counterpartyApi, { CounterpartyApi } from './counterparties/CounterpartyApi';
import currenciesApi, { CurrenciesApi } from './currency/CurrenciesApi';
import domainApi, { DomainApi } from './domains/DomainApi';
import hiddenSettingsApi, { HiddenSettingsApi } from './settings/HiddenSettingsApi';
import paymentSystemsApi, { PaymentSystemsApi } from './paymentSystems/PaymentSystemsApi';
import serverApi, { ServerApi } from './servers/ServerApi';
import settingsApi, { SettingsApi } from './settings/SettingsApi';
import statisticsApi, { StatisticsApi } from './statistics/StatisticsApi';
import timezonesApi, { TimezonesApi } from './timezones/TimezonesApi';
import hiddenFieldsApi, { HiddenFieldsApi } from './settings/HiddenFieldsApi';
import trainslationsApi, { TranslationsApi } from './translations/TranslationsApi';
import logsApi, { LogsApi } from './logs/LogsApi';
import walletApi, { WalletApi } from './wallet/walletApi';

export class Api {
  wallet: WalletApi = walletApi;
  logs: LogsApi = logsApi;
  auth: AuthApi = authApi;
  counterparty: CounterpartyApi = counterpartyApi;
  currencies: CurrenciesApi = currenciesApi;
  domain: DomainApi = domainApi;
  hiddenSettings: HiddenSettingsApi = hiddenSettingsApi;
  paymentSystems: PaymentSystemsApi = paymentSystemsApi;
  server: ServerApi = serverApi;
  settings: SettingsApi = settingsApi;
  statistics: StatisticsApi = statisticsApi;
  timezones: TimezonesApi = timezonesApi;
  hiddenFields: HiddenFieldsApi = hiddenFieldsApi;
  translations: TranslationsApi = trainslationsApi;
}

const api = new Api();

export default api;
