import { FC, useContext, createContext, useState, Dispatch, SetStateAction } from 'react';
import { WalletType } from 'api/translations/TranslationsApi';

type CurrentPaymentSystemCtx = {
  currentPaymentSystem: WalletType | null;
  setCurrentPaymentSystem: Dispatch<SetStateAction<WalletType | null>>;
};

const currentPaymentSystemCtx = createContext<CurrentPaymentSystemCtx | undefined>(undefined);

export const CurrentPaymentSystemCtx: FC = ({ children }) => {
  const [currentPaymentSystem, setCurrentPaymentSystem] = useState<WalletType | null>(null);

  const val: CurrentPaymentSystemCtx = {
    currentPaymentSystem,
    setCurrentPaymentSystem,
  };

  return <currentPaymentSystemCtx.Provider value={val}>{children}</currentPaymentSystemCtx.Provider>;
};

export function useCurrentPaymentSystemCtx(): CurrentPaymentSystemCtx {
  const context = useContext(currentPaymentSystemCtx);

  if (context === undefined) {
    throw new Error(`Is need to use useCurrentPaymentSystemContext inside a CurrentPaymentSystemContext component`);
  }

  return context;
}
