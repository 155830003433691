import map from 'lodash/map';
import { Switch, Route } from 'react-router-dom';

import userIsAnonymous from 'routes/userIsAnonymous';
import userIsAuthenticated from 'routes/userIsAuthenticated';

import Login from 'pages/Login';
import NotFoundPage from 'pages/errorPages/NotFoundPage';
import NoPermissionsPage from 'pages/errorPages/NoPermissionsPage';
import Counterparties from 'pages/widgets/counterparties/Counterparties';
import Domains from 'pages/widgets/counterparties/Domains';
import Servers from 'pages/widgets/counterparties/servers/Servers';
import DeletedServers from 'pages/widgets/counterparties/servers/DeletedServers';
import HiddenSettings from 'pages/widgets/HiddenSettings/hiddenSettings/HiddenSettings';
import DefaultTranslates from 'pages/widgets/DefaultTranslates';
import Currencies from 'pages/widgets/Currencies';
import Settings from 'pages/widgets/settings/Settings';
import HiddenFields from 'pages/widgets/HiddenSettings/hiddenFIelds/HiddenFields';
import Logs from 'pages/widgets/Logs';

let appRoutes: any[] = [
  {
    path: `/`,
    component: Counterparties,
    exact: true,
  },
  {
    path: `/deleted-servers`,
    component: DeletedServers,
  },
  {
    path: `/logs`,
    component: Logs,
  },
  {
    path: `/default-settings`,
    component: DefaultTranslates,
  },
  {
    path: `/settings`,
    component: Settings,
  },
  {
    path: `/hidden_settings`,
    component: HiddenSettings,
  },
  {
    path: `/hidden_fields`,
    component: HiddenFields,
  },
  {
    path: `/currencies`,
    component: Currencies,
  },
];

appRoutes = map(appRoutes, (item) => ({ ...item, component: userIsAuthenticated(item.component as any) }));

const routes = [
  ...appRoutes,
  {
    path: `/login`,
    component: userIsAnonymous(Login as any) as any,
  },
  {
    path: `/no-permissions`,
    component: NoPermissionsPage,
  },
  {
    path: `*`,
    component: NotFoundPage,
  },
];

const Root = () => (
  <Switch>
    {map(routes, (route, key) => (
      <Route key={key} {...route} />
    ))}
  </Switch>
);

export default Root;
