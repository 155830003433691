import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '../../../../utils/formatDate';
import { paymentSystemLogoSelector } from '../../../../selectors/widgets/paymentSystems';
import { hasAccessSelector } from '../../../../selectors/auth';
import { LogsColumn, ModalType, Permission } from '../../../../utils/enums';
import { walletActionCreators } from '../../../../actions/widgets/wallet';
import { WalletImg } from '@kassma-team/kassma-toolkit/lib';
import { logsActionCreators } from '../../../../actions/widgets/logs';
import { useFilterColumns } from '@kassma-team/kassma-toolkit';
import { ILogRowProps } from '../index';

const LogRow = ({
  desktopSize,
  error_type,
  message,
  created_at,
  wallet_uuid,
  wallet_type_code,
  wallet_identifier,
  hiddenColumns,
  category,
}: ILogRowProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const formattedCreatedAt = formatDate(created_at);

  const logo = useSelector(paymentSystemLogoSelector(`upi_l`));

  // TODO: The logic of permission will be implemented at the next stage as part of another task
  const showWalletFilter = useSelector(hasAccessSelector(Permission.WALLET_LIST));
  // TODO: Consider adding a modal related to the wallets later on
  const walletIdentifier = showWalletFilter ? (
    <button
      className="button-link"
      onClick={() =>
        dispatch(
          walletActionCreators.showModal({
            id: wallet_uuid,
            type: ModalType.SHOW,
          })
        )
      }
    >
      {wallet_identifier}
    </button>
  ) : (
    <div>{wallet_identifier}</div>
  );

  const columns = [
    {
      id: LogsColumn.WALLET_TYPE,
      desktop: (
        <td>
          {/*TODO: up to uncomment when the logo would be there*/}
          {/*<WalletImg title={wallet_type_code} src={logo} />*/}
          {wallet_type_code}
        </td>
      ),
      mobile: (
        <tr className="first-mobile-row">
          <td>{t(`wallets.walletType`)}</td>
          <td>
            {/*TODO: up to uncomment when the logo would be there*/}
            {/*<WalletImg title={wallet_type_code} src={logo} />*/}
            {wallet_type_code}
          </td>
        </tr>
      ),
    },
    {
      id: LogsColumn.IDENTIFIER,
      desktop: <td>{walletIdentifier}</td>,
      mobile: (
        <tr>
          <td>{t(`wallets.walletIdentifier`)}</td>
          <td>{walletIdentifier}</td>
        </tr>
      ),
    },
    {
      id: LogsColumn.ERROR_TYPE,
      desktop: (
        <td>
          <span className="text-danger">{error_type}</span>
        </td>
      ),
      mobile: (
        <tr>
          <td>{t(`logs.errorType`)}</td>
          <td>
            <span className="text-danger">{error_type}</span>
          </td>
        </tr>
      ),
    },
    {
      id: LogsColumn.CATEGORY,
      desktop: (
        <td>
          <span>{category}</span>
        </td>
      ),
      mobile: (
        <tr>
          <td>{t(`logs.category`)}</td>
          <td>
            <span>{category}</span>
          </td>
        </tr>
      ),
    },
    {
      id: LogsColumn.MESSAGE,
      desktop: (
        <td>
          <button
            className="button-link"
            onClick={() =>
              dispatch(
                logsActionCreators.showModal({
                  type: ModalType.LOG_MESSAGE,
                  message,
                })
              )
            }
          >
            {message.substr(0, 255)}
          </button>
        </td>
      ),
      mobile: (
        <tr>
          <td>{t(`logs.message`)}</td>
          <td>
            <button
              className="button-link"
              onClick={() =>
                dispatch(
                  logsActionCreators.showModal({
                    type: ModalType.LOG_MESSAGE,
                    message,
                  })
                )
              }
            >
              {message.substr(0, 255)}
            </button>
          </td>
        </tr>
      ),
    },
    {
      id: LogsColumn.DATE,
      desktop: <td className="logs__cell--date">{formattedCreatedAt}</td>,
      mobile: (
        <tr>
          <td>{t(`common.date`)}</td>
          <td className="logs__cell--date">{formattedCreatedAt}</td>
        </tr>
      ),
    },
  ];

  return useFilterColumns({ columns, desktopSize, hiddenColumns });
};

export default LogRow;
