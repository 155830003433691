import { all } from 'redux-saga/effects';

import authSagas from 'sagas/auth';
import filtersSagas from 'sagas/filters';
import widgetSagas from 'sagas/widgets/widgets';
import counterpartiesSagas from 'sagas/widgets/counterparties';
import statisticsSagas from 'sagas/statistics';

export default function* rootSaga() {
  yield all([...authSagas, ...widgetSagas, ...filtersSagas, ...counterpartiesSagas, ...statisticsSagas]);
}
