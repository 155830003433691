import { createSelector } from 'reselect';
import map from 'lodash/map';
import toString from 'lodash/toString';
import { widgetSelector, IWidgetsState, widgetListSelector } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { IRootState } from 'interfaces/common';

export const paymentSystemsFilterListSelector = createSelector<IRootState, IWidgetsState, IPaymentSystemFilterItem[]>(
  widgetSelector<IWidgetsState>(WidgetType.FILTER_LIST_PAYMENT_SYSTEMS),
  (state) =>
    map<any, IPaymentSystemFilterItem>(state.items, ({ value, text }) => ({ value: toString(value), text })) || []
);

export const paymentSystemsSelector = createSelector<IRootState, IPaymentSystemsItem[], IPaymentSystemsItem[]>(
  widgetListSelector(WidgetType.PAYMENT_SYSTEMS),
  (list) => list || []
);

export const paymentSystemLogoSelector = (walletType: string) =>
  createSelector(
    paymentSystemsSelector,
    (list: IPaymentSystemsItem[]) =>
      (list.find((item) => item.value === walletType || item.code === walletType) || { logo: `` }).logo
  );

interface IPaymentSystemFilterItem {
  value: string;
  text: string;
}

type IPaymentSystemsItem = {
  value: string;
  text: string;
  payment_type: string;
  is_use_possible_transactions: boolean;
  supported_currencies: string[];
  logo: string;
} & WalletType;

type WalletType = {
  id: string;
  code: string;
  name: string;
  created_at: string;
  updated_at: string;
  is_control: boolean;
  use_possible_transactions: boolean;
  is_standard: boolean;
  is_settlement: boolean;
  standard_threshold: number;
  plugin_visibility: boolean;
  is_enabled: boolean;
  enable_notifications: boolean;
  show_transaction_id: boolean;
  valid_trans_id_from: number;
  valid_trans_id_to: number;
  show_payment_bill: boolean;
  has_wallets: boolean;
  payment_type: PaymentType;
  applied_currencies: ReadonlyArray<string>;
  supported_currencies: ReadonlyArray<string>;
  supported_wallet_currencies: ReadonlyArray<string>;
  logo: string;
};

type PaymentType = `P@P` | `API` | `MANUAL`;
