import { widgetActionCreators } from '@kassma-team/kassma-toolkit/lib';
import { WidgetType } from 'utils/enums';
import { CREATE_SERVER_FORM_NAME } from 'utils/constants';

export const serversActionCreators = widgetActionCreators({
  widget: WidgetType.SERVERS,
  labels: { listLabel: `servers`, itemLabel: `server` },
  creatingForm: CREATE_SERVER_FORM_NAME,
});

export const deletedServersActionCreators = widgetActionCreators({
  widget: WidgetType.DELETED_SERVERS,
  labels: { listLabel: `servers`, itemLabel: `server` },
});
