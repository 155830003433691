import { FormField as KassmaToolkitFormField, widgetListSelector, IFormField } from '@kassma-team/kassma-toolkit/lib';
import { useSelector } from 'react-redux';
import { Field, FieldArray } from 'redux-form';

import { WidgetType } from 'utils/enums';

const FormField = (props: IFormField) => {
  const currencies = useSelector(widgetListSelector(WidgetType.CURRENCIES));

  return (
    <KassmaToolkitFormField
      {...props}
      currencies={currencies}
      ReduxFormField={Field}
      ReduxFormFieldArray={FieldArray}
    />
  );
};

export default FormField;
