import { ReactElement, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { IModalData, InputType, getSubmitHandler } from '@kassma-team/kassma-toolkit/lib';

import { ModalType, Permission, WidgetType } from 'utils/enums';
import { counterpartiesActionCreators, refreshCounterparty } from 'actions/widgets/counterparties';
import { hasAccessSelector } from 'selectors/auth';
import { lastUpdatedOfCounterpartiesSelector } from 'selectors/widgets/counterparties';

import { IWidgetHeadTitles } from 'interfaces/widgets/widgets';
import { ICounterpartyRowProps } from 'interfaces/widgets/counterparties';

import Widget from 'components/widgets/Widget';
import CounterpartyRow from 'components/widgets/counterparties/CounterpartyRow';
import CounterpartyCreating from 'components/widgets/counterparties/CounterpartyCreating';
import CounterpartyUpdating from 'components/widgets/counterparties/CounterpartyUpdating';
import counterpartiesRouteTabs from 'pages/widgets/counterparties/counterpartiesRouteTabs';
import CounterpartyConfirm from '../../../components/widgets/counterparties/CounterpartyConfirm';
import CounterpartyDelete from '../../../components/widgets/counterparties/CounterpartyDelete';
import { reduxForm } from 'redux-form';
import Filters from 'components/widgets/filters/Filters';

const headTitles: IWidgetHeadTitles[] = [
  {
    title: `counterparties.identifier`,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `counterparties.ip`,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `counterparties.domainName`,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `counterparties.group`,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `common.actions`,
    desktop: true,
    mobile: true,
    disabled: true,
  },
];

const listMapping = (item: ICounterpartyRowProps) => {
  return <CounterpartyRow {...item} key={item.id} />;
};

const modals: IModalData[] = [
  {
    component: CounterpartyCreating,
    widget: WidgetType.COUNTERPARTIES,
    type: ModalType.CREATE,
  },
  {
    component: CounterpartyUpdating,
    widget: WidgetType.COUNTERPARTIES,
    type: ModalType.UPDATE,
  },
  {
    widget: WidgetType.COUNTERPARTIES,
    type: ModalType.SHOW,
    component: CounterpartyConfirm,
  },
  {
    widget: WidgetType.COUNTERPARTIES,
    type: ModalType.DELETE,
    component: CounterpartyDelete,
  },
];

const FORM_NAME = `counterparty-filters`;

const FilterForm = reduxForm({
  form: FORM_NAME,
  onSubmit: getSubmitHandler(),
})(Filters);

const Counterparties = (): ReactElement => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const manageAccess = useSelector(hasAccessSelector(Permission.COUNTERPARTY_MANAGE));

  const lastUpdated = useSelector(lastUpdatedOfCounterpartiesSelector);

  const onCreateRoleClick = useCallback(() => {
    dispatch(counterpartiesActionCreators.showModal({ type: ModalType.CREATE }));
  }, [dispatch]);

  const onRefresh = useCallback(() => {
    dispatch(refreshCounterparty());
  }, [dispatch]);

  let tableHeaderContent;
  if (lastUpdated) {
    tableHeaderContent = (
      <span className="mr-10">
        {t(`counterparties.statusesLastUpdate`, { date: moment(lastUpdated).format(`DD.MM.YYYY HH:mm`) })}
      </span>
    );
  }

  return (
    <Widget
      widget={WidgetType.COUNTERPARTIES}
      actionCreators={counterpartiesActionCreators}
      pageTitle="counterparties.counterparties"
      tableTitle="counterparties.counterpartyList"
      headTitle="counterparties.counterparties"
      tableHeadTitles={headTitles}
      listMapping={listMapping}
      filterForm={
        <FilterForm
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          formName={FORM_NAME}
          values={{
            group: 1,
          }}
          leftFields={[
            {
              name: `group`,
              type: InputType.SELECT,
              // value: null,
              placeholder: t(`counterparties.group`),
              data: [
                {
                  text: t(`common.all`),
                  value: null,
                },
                {
                  text: `A`,
                  value: `1`,
                },
                {
                  text: `B`,
                  value: `2`,
                },
                {
                  text: `C`,
                  value: `3`,
                },
                {
                  text: `D`,
                  value: `4`,
                },
              ],
            },
          ]}
          onSubmit={(values, dispatch) => {
            if (!values.group) {
              delete values.group;
            }

            dispatch({
              type: `WIDGET_LIST_REQUESTED`,
              payload: values,
              meta: {
                widget: `counterparties`,
                labels: {
                  listLabel: `counterparties`,
                  itemLabel: `counterparty`,
                },
              },
              creatingForm: `create-counterparty`,
              listItemUpdatingForm: `update-counterparty`,
              supportNewFormat: {
                itemCreating: true,
                listItemUpdating: true,
              },
            });
          }}
        />
      }
      modals={modals}
      onRefresh={onRefresh}
      tableHeaderContent={tableHeaderContent}
      routeTabs={counterpartiesRouteTabs}
    />
  );
};

export default Counterparties;
