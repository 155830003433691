import React, { FC } from 'react';
import { Portal } from 'react-portal';
import { useTranslation } from 'react-i18next';
import './SaveModal.scss';

type Props = {
  onClose: () => void;
  onSave: () => void;

  children?: never;
};

export const SaveModal: FC<Props> = ({ onClose }) => {
  const [t] = useTranslation();

  return (
    <Portal node={document && document.getElementById(`page-container`)}>
      <div className="SaveModal">
        <div className="SaveModal__overlay">
          <div className="SaveModal__body">
            <div className="SaveModal__header">
              <button onClick={onClose} type="button">
                <i className="si si-close" />
              </button>
            </div>
            <p className="SaveModal__main">{t(`common.unsaveChanges`)}</p>
            <div className="SaveModal__footer">
              <button onClick={onClose} className="cancel-btn" type="button">
                {t(`common.closeWindow`)}
              </button>
              <button onClick={onClose} className="cancel-btn" type="button">
                {t(`common.save`)}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};
