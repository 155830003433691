import { FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import { Dispatch } from 'redux';

interface IProps {
  form: string;
  onSubmit: (value: any, dispatch: Dispatch) => void;
}

const useFormSubmissionHandler = ({ form, onSubmit }: IProps) => {
  const values = useSelector(getFormValues(form));
  const dispatch = useDispatch();

  return (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(values, dispatch);
  };
};

export default useFormSubmissionHandler;
