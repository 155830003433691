import { ReactElement, useEffect, useMemo } from 'react';
import { Dispatch } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import {
  widgetModalSelector,
  IWidgetModal,
  widgetItemSelector,
  FieldType,
  InputType,
} from '@kassma-team/kassma-toolkit/lib';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';

import { WidgetType } from 'utils/enums';
import { UPDATE_COUNTERPARTY_FORM_NAME } from 'utils/constants';
import { counterpartiesActionCreators } from 'actions/widgets/counterparties';
import useUpdatingForm from 'hooks/widgets/useUpdatingForm';

import {
  ICounterpartyFormData,
  ICounterpartyItem,
  ICounterpartyUpdatingFormData,
} from 'interfaces/widgets/counterparties';

import ModalForm from 'components/modals/ModalForm';
import { omit } from 'lodash';

const onSubmit = (values: ICounterpartyUpdatingFormData, dispatch: Dispatch) => {
  if (values.group === 0) {
    values = omit(values, `group`);
  }

  dispatch(counterpartiesActionCreators.update(values));
};

const CounterpartyUpdating = (props: InjectedFormProps<ICounterpartyFormData>): ReactElement => {
  const modal: IWidgetModal = useSelector(widgetModalSelector(WidgetType.COUNTERPARTIES));

  const [t] = useTranslation();

  const data: ICounterpartyItem = useSelector(widgetItemSelector(WidgetType.COUNTERPARTIES) || {});

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    props.initialize({ group: data?.group || 0, available_scrapers: data?.applied_scrapers || [] });
  }, [data?.group]);

  const availableScarpers = map(data?.available_scarpers, ({ id, name }) => ({ text: name, value: id }));

  const group: Array<{ text: string; value: number }> = [
    {
      text: `Не определена`,
      value: 0,
    },
    {
      text: `A`,
      value: 1,
    },
    {
      text: `B`,
      value: 2,
    },
    {
      text: `C`,
      value: 3,
    },
    {
      text: `D`,
      value: 4,
    },
  ];

  const fields = [
    {
      name: `identifier`,
      label: t(`counterparties.identifier`),
      required: true,
      maxLength: 190,
    },
    {
      name: `group`,
      label: t(`counterparties.group`),
      type: `select`,
      data: group,
    },
    {
      name: `applied_scarpers`,
      title: t(`counterparties.scrapers`),
      data: availableScarpers,
      fieldType: FieldType.CHECKBOX_GROUP,
    },
  ];

  useUpdatingForm<ICounterpartyItem>({
    widget: WidgetType.COUNTERPARTIES,
    actionCreators: counterpartiesActionCreators,
    initialize: props.initialize,
    payload: modal?.payload?.id,
    itemAdapter: ({ identifier, applied_scarpers, id, group }) => ({ applied_scarpers, identifier, id, group }),
  });

  return (
    <ModalForm
      {...props}
      onSubmit={onSubmit}
      actionCreators={counterpartiesActionCreators}
      submitText="common.update"
      fields={fields}
      title="counterparties.counterpartyUpdating"
      widget={WidgetType.COUNTERPARTIES}
    />
  );
};

export default reduxForm({ form: UPDATE_COUNTERPARTY_FORM_NAME })(CounterpartyUpdating as any);
