import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import RemovingModal from '../../modals/RemovingModal';
import { WidgetType } from '../../../utils/enums';
import { intranetCurrencyActionCreators } from '../../../actions/widgets/currency';
import { IWidgetModal, widgetModalSelector } from '@kassma-team/kassma-toolkit';
import { useSelector } from 'react-redux';

const CurrencyDelete: FC = () => {
  const [t] = useTranslation();
  const modal: IWidgetModal = useSelector(widgetModalSelector(WidgetType.INTRANET_CURRENCIES));

  return (
    <RemovingModal
      title={t(`currencies.currencyRemoving`)}
      actionCreators={intranetCurrencyActionCreators}
      widget={WidgetType.INTRANET_CURRENCIES}
      content={`${t(`currencies.currencyWillBeRemovedPermanently`)} ${t(`currencies.areYouSureToDeleteCurrency`, {
        code: modal?.id,
      })}`}
    />
  );
};

export default CurrencyDelete;
