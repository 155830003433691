import { toastr } from 'react-redux-toastr';

import { WidgetType } from 'utils/enums';
import widgetSagas from './index';
import refreshSaga from '../effects/refreshSaga';
import api from '../../api';

const listFetching: any = {
  [WidgetType.LOGS]: api.logs.loadLogList,
  [WidgetType.COUNTERPARTIES]: api.counterparty.loadCounterparties,
  [WidgetType.CURRENCIES]: api.currencies.loadCurrencyList,
  [WidgetType.INTRANET_CURRENCIES]: api.currencies.loadNewCurrencyList,
  [WidgetType.FILTER_COUNTERPARTY_LIST]: api.counterparty.loadCounterpartiesFilterList,
  [WidgetType.FILTER_LIST_PAYMENT_SYSTEMS]: api.paymentSystems.loadPSFilterList,
  [WidgetType.DOMAINS]: api.domain.loadDomains,
  [WidgetType.SERVERS]: api.server.loadServers,
  [WidgetType.DELETED_SERVERS]: api.server.loadDeletedServers,
  [WidgetType.TIMEZONES]: api.timezones.loadTimezoneList,
  [WidgetType.HIDDEN_SETTINGS]: api.hiddenSettings.loadHiddenSettings,
  [WidgetType.HIDDEN_FIELDS]: api.hiddenFields.loadFields,
  [WidgetType.PAYMENT_SYSTEMS]: api.translations.fetchPaymentSystems,
};

const itemFetching: any = {
  [WidgetType.COUNTERPARTIES]: api.counterparty.loadCounterparty,
  [WidgetType.SETTINGS]: api.settings.loadSettings,
  [WidgetType.DOMAINS]: api.domain.loadDomain,
  [WidgetType.INTRANET_CURRENCIES]: api.currencies.loadNewCurrencyList,
  [WidgetType.HIDDEN_SETTINGS]: api.hiddenSettings.loadHiddenSettings,
};

const createFetching: any = {
  [WidgetType.COUNTERPARTIES]: api.counterparty.createCounterparty,
  [WidgetType.DOMAINS]: api.domain.createDomain,
  [WidgetType.SERVERS]: api.server.createServer,
  [WidgetType.INTRANET_CURRENCIES]: api.currencies.createCurrency,
  [WidgetType.HIDDEN_FIELDS]: api.hiddenFields.createFields,
};

const updateListItemFetching: any = {
  [WidgetType.COUNTERPARTIES]: api.counterparty.updateCounterparty,
  [WidgetType.DOMAINS]: api.domain.updateDomain,
  [WidgetType.INTRANET_CURRENCIES]: api.currencies.updateCurrency,
};

const deleteItemFetching: any = {
  [WidgetType.DOMAINS]: api.domain.deleteDomain,
  [WidgetType.SERVERS]: api.server.deleteServer,
  [WidgetType.COUNTERPARTIES]: api.counterparty.deleteCounterparty,
  [WidgetType.INTRANET_CURRENCIES]: api.currencies.removeCurrency,
  [WidgetType.HIDDEN_FIELDS]: api.hiddenFields.deleteField,
};

const updateItemFetching: any = {
  [WidgetType.SETTINGS]: api.settings.saveSettings,
  [WidgetType.HIDDEN_SETTINGS]: api.hiddenSettings.updateHiddenSettings,
};

export default widgetSagas({
  listFetching,
  itemFetching,
  createFetching,
  updateListItemFetching,
  deleteItemFetching,
  updateItemFetching,
  refreshSaga,
  toastr,
});
