import { ReactElement } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import ReduxToastr from 'react-redux-toastr';

import store from 'store';
import rootHistory from 'rootHistory';

import Root from 'routes/Root';

const App = (): ReactElement => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={rootHistory}>
        <>
          <Root />
          <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="top-left"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
          />
        </>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
