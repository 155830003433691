import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import useArrayFields from './useArrayFields';

const useValueCastsList = () => {
  const [t] = useTranslation();

  const fields = useCallback(
    () => [
      {
        placeholder: t(`settings.value`),
        name: `value_cast`,
        wrapClassName: `w-90 mr-5`,
        withModalScroll: true,
        required: true,
        itemComponent: (value: string) => {
          return value;
        },
        handleChange: (input: Record<string, any>) => (item: any) => {
          input.onChange(item);
        },
      },
    ],
    []
  );

  return useArrayFields({
    fields: fields,
    title: t(`settings.value`),
    name: `value_cast`,
  });
};

export default useValueCastsList;
