import { widgetItemSelector, IWidgetActionCreators } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { useDispatch, useSelector } from 'react-redux';
import useMount from 'react-use/lib/useMount';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';

interface IParams<Item = any> {
  widget: WidgetType;
  actionCreators: IWidgetActionCreators;
  initialize: (values: Record<string, unknown>) => void;

  payload?: any;
  itemAdapter?: (item: Item) => any;
}

const useUpdatingForm = <Item = any>({ itemAdapter, widget, actionCreators, initialize, payload }: IParams<Item>) => {
  const item: any = useSelector(widgetItemSelector(widget));

  const dispatch = useDispatch();

  useMount(() => {
    dispatch(actionCreators.getItem(payload));
  });

  useUpdateEffect(() => {
    if (item) {
      if (itemAdapter) {
        initialize(itemAdapter(item));
      } else {
        initialize(item);
      }
    }
  }, [item]);
};

export default useUpdatingForm;
