import { ReactNode } from 'react';

interface IProps {
  children: ReactNode;
}

const MobileTableRow = ({ children }: IProps) => {
  return (
    <tr>
      <td className="no-padding no-border">
        <div className="mobile-inner-table">
          <table className="table inner-table">
            <tbody>{children}</tbody>
          </table>
        </div>
      </td>
    </tr>
  );
};

export default MobileTableRow;
