import React, { FC } from 'react';
import { Lang } from 'api/translations/TranslationsApi';
import { SelectLang } from '../SelectLang';
import { RemoveButton } from '../../components/RemoveButton';
import './Field.scss';
import { useTranslation } from 'react-i18next';
import TranslateField from './TranslateField';
import { convertFromHTML, EditorState, ContentState } from 'draft-js';

type Props = {
  lang: string;
  text: string;
  onRemove: () => void;
  onChangeLang: (option: { value: string; text: string } | string) => void;
  onChangeText: (text: EditorState) => void;
  langs: Array<Lang>;
  selectedLangs: Array<Lang>;

  isRemoveBtnShowed?: boolean;
  isFormSubmitted?: boolean;

  children?: never;
};

export const Field: FC<Props> = (props) => {
  const {
    lang,
    langs,
    text,
    isRemoveBtnShowed = true,
    onRemove,
    onChangeLang,
    onChangeText,
    selectedLangs,
    isFormSubmitted,
  } = props;

  const mText = (text: string) => {
    const converted = convertFromHTML(text);

    return EditorState.createWithContent(
      ContentState.createFromBlockArray(converted.contentBlocks, converted.entityMap)
    );
  };

  const [t] = useTranslation();

  const getTextError = (text: string) => {
    const isEmptyError = !text.length || text === `<br>`;
    const isMaxSymbolsError = text.length > 300;

    if (isEmptyError && isFormSubmitted) return <p className="Field__message">{t(`errors.requiredField`)}</p>;
    if (isMaxSymbolsError && isFormSubmitted)
      return <p className="Field__message">{t(`errors.maxFieldSize`, { len: 300 })}</p>;
  };

  return (
    <>
      <div className="Field">
        <SelectLang
          availableLangs={langs}
          lang={lang}
          error={
            !lang &&
            isFormSubmitted && <p className="Field__message Field__message--lang">{t(`errors.requiredField`)}</p>
          }
          selectedLangs={selectedLangs}
          onChangeLang={onChangeLang}
        />
        <TranslateField text={mText(text)} error={getTextError(text)} onChangeText={onChangeText} />
        <RemoveButton hidden={!isRemoveBtnShowed} onRemove={onRemove} />
      </div>
    </>
  );
};
