import React, { FC, memo, ReactNode } from 'react';
import { Lang } from 'api/translations/TranslationsApi';
import { useTranslation } from 'react-i18next';

import { DropdownSelect } from '@kassma-team/kassma-toolkit';

import './SelectLang.scss';

type Props = {
  availableLangs: Array<Lang>;
  selectedLangs: Array<Lang>;
  onChangeLang: (option: { value: string; text: string } | string) => void;
  lang: string;

  error?: ReactNode;
  children?: never;
};

export const SelectLang: FC<Props> = memo((props) => {
  const { availableLangs, onChangeLang, selectedLangs, lang, error } = props;
  const [t] = useTranslation();

  const getFreeLangs = (allLangs: Array<Lang>, selectedLangs: Array<Lang>) =>
    allLangs.filter((lang) => !selectedLangs.includes(lang));

  return (
    <div className="SelectLang">
      <DropdownSelect
        id="select-lang"
        data={getFreeLangs(availableLangs, selectedLangs).map((el) => ({ value: el, text: el }))}
        placeholder={t(`settings.language`)}
        onChange={onChangeLang}
        value={{ value: lang, text: lang }}
        className="SelectLang"
        wrapClassName="SelectLang"
      />
      {error && error}
    </div>
  );
});
