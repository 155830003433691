import { widgetActionCreators, createWidgetAction } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { CREATE_COUNTERPARTY_FORM_NAME, UPDATE_COUNTERPARTY_FORM_NAME } from 'utils/constants';
import {
  COUNTERPARTY_CREATING_DATA_REQUESTED,
  REFRESH_COUNTERPARTY_REQUESTED,
  RESET_COUNTERPARTY_CREATING_DATA,
} from 'actionTypes';
import { IWidgetsMeta, WidgetOperations } from '../types';

const meta: IWidgetsMeta = {
  widget: WidgetType.COUNTERPARTIES,
  labels: { listLabel: `counterparties`, itemLabel: `counterparty` },
  creatingForm: CREATE_COUNTERPARTY_FORM_NAME,
  listItemUpdatingForm: UPDATE_COUNTERPARTY_FORM_NAME,
};

export const counterpartiesActionCreators = widgetActionCreators({
  ...meta,
  supportNewFormat: {
    [WidgetOperations.ITEM_CREATING]: true,
    [WidgetOperations.LIST_ITEM_UPDATING]: true,
  },
});

export const refreshCounterparty = createWidgetAction(meta)(REFRESH_COUNTERPARTY_REQUESTED);

export const filterCounterpartyListActionCreators = widgetActionCreators({
  widget: WidgetType.FILTER_COUNTERPARTY_LIST,
  labels: { listLabel: `counterparties` },
});

export const getCounterpartyCreatingData = createWidgetAction({
  widget: WidgetType.COUNTERPARTIES,
})(COUNTERPARTY_CREATING_DATA_REQUESTED);

export const resetCounterpartyCreatingData = createWidgetAction({
  widget: WidgetType.COUNTERPARTIES,
})(RESET_COUNTERPARTY_CREATING_DATA);
