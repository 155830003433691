import { ReactNode, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { InjectedFormProps, reduxForm, SubmitHandler } from 'redux-form';
import map from 'lodash/map';
import { WidgetContainer, PageContainer, WIDGET_ITEM_SUCCEEDED } from '@kassma-team/kassma-toolkit/lib';

import { HIDDEN_SETTINGS_FORM_NAME } from 'utils/constants';
import { hiddenSettingsActionCreators } from 'actions/widgets/settings';
import { ModalType, Permission, WidgetType } from 'utils/enums';
import { IHiddenSettingsFormData, ISettingsFormData } from 'interfaces/widgets/settings';
import useList from 'hooks/widgets/useList';
import { filterCounterpartyListActionCreators } from 'actions/widgets/counterparties';
import useFormSubmissionHandler from 'hooks/widgets/useFormSubmissionHandler';
import withPermissionCheck from 'hocs/withPermissionCheck';

import SettingsBlockWrap from 'pages/widgets/settings/commonSettings/SettingsBlockWrap';
import HiddenSettingsList from 'pages/widgets/HiddenSettings/hiddenSettings/HiddenSettingsList';
import Header from 'components/header/Header';
import RegularForm from 'components/form/RegularForm';
import { useDispatch, useSelector } from 'react-redux';
import { userPermissionsSelector } from '../../../../selectors/auth';
import { replace } from 'connected-react-router';
import RouteTabs from 'components/widgets/RouteTabs';
import { hiddenSettingsRouteTabs } from '../hiddenSettingsRouteTabs';

let Form: any = (props: InjectedFormProps<ISettingsFormData>): ReactNode => {
  const [t] = useTranslation();

  const { items: counterparties } = useList({
    widget: WidgetType.FILTER_COUNTERPARTY_LIST,
    actionCreators: filterCounterpartyListActionCreators,
    resetOnUnmount: false,
    loadOnlyIfNecessary: true,
  });

  const onSubmit = (values: IHiddenSettingsFormData, dispatch: Dispatch) => {
    const meta = {
      widget: WidgetType.HIDDEN_SETTINGS,
      labels: { itemLabel: `counterparty` },
    };

    dispatch({ type: WIDGET_ITEM_SUCCEEDED, meta, payload: { counterparty: values?.counterparty } });
    dispatch(hiddenSettingsActionCreators.getList(values?.counterparty));
  };

  const handleSubmit = useFormSubmissionHandler({ form: HIDDEN_SETTINGS_FORM_NAME, onSubmit });

  const counterpartiesList = useMemo(
    () =>
      map(counterparties, ({ text, value }) => ({
        value,
        text,
      })),
    [counterparties]
  );

  return (
    <RegularForm
      {...props}
      fields={[
        {
          name: `counterparty`,
          type: `select`,
          required: true,
          label: t(`settings.counterparty`),
          data: counterpartiesList,
        },
      ]}
      submitText={t(`common.apply`)}
      handleSubmit={handleSubmit as SubmitHandler}
    />
  );
};

Form = reduxForm<IHiddenSettingsFormData>({
  form: HIDDEN_SETTINGS_FORM_NAME,
})(Form as any) as any;

const HiddenSettings = (): ReactNode => {
  const [t] = useTranslation();
  const permissions: string[] | null = useSelector(userPermissionsSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hiddenSettingsActionCreators.getList());
  }, []);

  useEffect(() => {
    if (!permissions.includes(Permission.HIDDEN_SETTINGS_UPDATE)) {
      dispatch(replace(`/no-permissions`)); // todo use withPermissionCheck HOC
    }
  }, []);

  return (
    <PageContainer>
      <Helmet>
        <title>{t(`settings.hiddenSettings`)}</title>
      </Helmet>
      <Header />
      <WidgetContainer title="settings.hiddenSettings">
        <RouteTabs routeTabs={hiddenSettingsRouteTabs} />
        <div className="block p-20">
          <Form />
          <SettingsBlockWrap title="settings.hiddenSettingsList">
            <HiddenSettingsList />
          </SettingsBlockWrap>
        </div>
      </WidgetContainer>
    </PageContainer>
  );
};

export default withPermissionCheck(HiddenSettings, [
  {
    permissions: [
      {
        permission: Permission.HIDDEN_SETTINGS_UPDATE,
      },
    ],
    redirectUrl: `/no-permissions`,
  },
]);
