import ModalForm from '../../modals/ModalForm';
import { WidgetType } from '../../../utils/enums';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { Dispatch } from 'redux';
import { intranetCurrencyActionCreators } from '../../../actions/widgets/currency';
import { IFormField } from '@kassma-team/kassma-toolkit';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { IIntranetCurrencyFormData, SymbolPosition } from '../../../interfaces/widgets/intranetCurrencies';
import useCurrencyFields from './../../../hooks/widgets/currencies/useCurrencyFields';

export const CURRENCY_CREATING_FORM = `currency-creating`;

const onSubmit = (values: IIntranetCurrencyFormData, dispatch: Dispatch) => {
  dispatch(intranetCurrencyActionCreators.create(values));
};

const CurrencyCreating = (props: InjectedFormProps) => {
  const values = useSelector(getFormValues(CURRENCY_CREATING_FORM));

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const is_crypto = values?.is_crypto;
  useEffect(() => {
    props.change(`symbol_position`, SymbolPosition.LEFT);
    props.change(`is_crypto`, false);
  }, []);

  const fields: IFormField[] = useCurrencyFields({
    is_crypto,
    creating: true,
  });

  return (
    <ModalForm
      {...props}
      actionCreators={intranetCurrencyActionCreators}
      submitText="common.create"
      fields={fields}
      title="currencies.currencyCreating"
      widget={WidgetType.INTRANET_CURRENCIES}
    />
  );
};

export default reduxForm({ form: CURRENCY_CREATING_FORM, onSubmit })(CurrencyCreating as any);
