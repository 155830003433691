import { useState, useEffect } from 'react';
import { getLocation } from 'connected-react-router';
import { useSelector } from 'react-redux';

import { IRootState } from 'interfaces/common';

const useRouteName = (): string | undefined => {
  const [route, setRoute] = useState<string>();

  const location = useSelector<IRootState, Record<string, string>>(getLocation as any);

  useEffect(() => {
    setRoute(location?.pathname);
  }, [location]);

  return route;
};

export default useRouteName;
