// Form names

export const CREATE_ROLE_FORM_NAME = `create-role`;
export const UPDATE_ROLE_FORM_NAME = `update-role`;

export const CREATE_USER_FORM_NAME = `create-user`;
export const UPDATE_USER_FORM_NAME = `update-user`;

export const CREATE_COUNTERPARTY_FORM_NAME = `create-counterparty`;
export const UPDATE_COUNTERPARTY_FORM_NAME = `update-counterparty`;

export const CREATE_DOMAIN_FORM_NAME = `create-domain`;
export const UPDATE_DOMAIN_FORM_NAME = `update-domain`;

export const CREATE_SERVER_FORM_NAME = `create-server`;

export const CREATE_HIDDEN_FIELDS_FORM_NAME = `create-hidden-fields`;
export const SETTINGS_FORM_NAME = `settings`;
export const HIDDEN_SETTINGS_FORM_NAME = `hidden-settings`;
export const HIDDEN_SETTINGS_LIST_FORM_NAME = `hidden-settings-list`;

export const UPDATE_WALLET_FORM_NAME = `update-wallet`;
export const CREATE_WALLET_FORM_NAME = `create-wallet`;
