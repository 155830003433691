const ru = {
  "translation": {
    "auth": {
      "login": "Логин",
      "password": "Пароль",
      "signIn": "Войти",
      "logout": "Выйти",
      "configError": "Ошибка заполения config",
      "noRequiredParameters": "Нет обязательных параметров"
    },
    "logs": {
      "logs": "Логи",
      "walletLogs": "Логи кошелька",
      "logList": "Список логов",
      "walletType": "Тип кошелька",
      "category": "Категория",
      "walletIdentifier": "Номер кошелька",
      "errorType": "Тип ошибки",
      "message": "Сообщение",
      "date": "Дата",
      "messageIsEmpty": "Нет сообщения",
      "debugging": "Отладка"
    },
    "domains": {
      "domainList": "Список доменов",
      "domains": "Домены",
      "addDomain": "Добавить домен",
      "domainName": "Доменное имя",
      "registerDate": "Дата регистрации",
      "expiration": "Срок действия",
      "domainMustBeRegistered": "Домен должен быть предварительно зарегистрирован",
      "free": "Свободен",
      "engaged": "Занят",
      "domainUpdating": "Редактирование домена",
      "domainCreating": "Добавление домена",
      "domainDeleting": "Удаление домена",
      "processing": "Обработка"
    },
    "servers": {
      "serverList": "Список серверов",
      "servers": "Серверы",
      "addServer": "Добавить сервер",
      "registerDate": "Дата регистрации",
      "expiration": "Срок действия",
      "ipAddress": "IP адрес",
      "specifications": "Технические характеристики",
      "addingServerInfo": "При автоматическом создании сервера, требуется указать его характеристики, сервер будет автоматически закуплен, его параметры появятся в таблице. При ручном добавлении, сервер должен быть предварительно закуплен.",
      "login": "Логин",
      "free": "Свободен",
      "engaged": "Занят",
      "notAvailable": "Недоступен",
      "serverAdding": "Добавление сервера",
      "ram": "RAM",
      "cpu": "CPU",
      "rom": "ROM",
      "serverDeleting": "Удаление сервера",
      "showOnlyDeleted": "Показать только удаленные",
      "deleted": "Удален",
      "ramFieldInfo": "Укажите требуемый объём оперативной памяти для сервера",
      "cpuFieldInfo": "Укажите требуемое количество ядер центрального процессора сервера",
      "romFieldInfo": "Укажите необходимое количество постоянной памяти сервера"
    },
    "currencies": {
      "currencies": "Валюты",
      "code": "Код",
      "name": "Название",
      "symbol": "Символ",
      "symbolPosition": "Позиция символа",
      "isCrypto": "Криптовалюта",
      "rate": "Курс",
      "rateUpdatedAt": "Дата изменения курса",
      "currencyRemoving": "Удаление валюты",
      "areYouSureToDeleteCurrency": "Вы уверены, что хотите удалить валюту {{code}}?",
      "startRateUpdating": "Запустить обновление курсов валют",
      "areYouSureToStartRateUpdate": "Вы уверены, что хотите запустить обновление курсов всех валют?",
      "currencyCreating": "Создание валюты",
      "currencyUpdating": "Редактирование валюты",
      "addCurrency": "Добавить валюту",
      "updateExchangeRates": "Обновить курсы валют",
      "currencyWillBeRemovedPermanently": "Валюта будет удалена навсегда из всех частей системы.",
      "updateExchangeRate": "Обновить курс валюты"
    },
    "counterparties": {
      "counterparties": "Контрагенты",
      "counterpartyList": "Список контрагентов",
      "identifier": "ID",
      "ip": "IP-адрес",
      "domainName": "Домен",
      "domainExpiration": "Срок действия домена",
      "active": "Работает",
      "disabled": "Не работает",
      "addCounterparty": "Добавить контрагента",
      "counterpartyCreating": "Добавление контрагента",
      "statusesHaveBeenSuccessfullyUpdated": "Статусы были успешно обновлены",
      "counterpartyUpdating": "Редактирование контрагента",
      "statusesLastUpdate": "Последнее обновление статусов: {{date}}",
      "login": "Логин",
      "role": "Роль",
      "noUsers": "Список пользователей пуст",
      "users": "Пользователи",
      "noPaymentSystems": "Список платежных систем пуст",
      "paymentSystems": "Платежные системы",
      "edit": "Редактировать",
      "deploy": "Разворот",
      "numberOfActiveScrapers": "Количество активных парсеров",
      "autoInstallation": "Автоматическое создание инстанса",
      "autoInstallationDescr": "При ручном добавлении система должна быть предварительно настроена. В данном окне лишь указываются параметры системы в информационных целях.",
      "identifierDescr": "Идентификатор системы - её название, если не указывать, то будет записан по домену.",
      "domainDescr": "Доменное имя должно быть заранее зарегистрировано, выберите из списка уже имеющихся или предварительно заведите новое доменное имя.",
      "serverDescr": "Сервер должен быть заранее зарегистрирован, выберите из списка уже имеющихся или предварительно заведите новый сервер.",
      "loginDescr": "Имя пользователя учетной записи, которую Вы хотите использовать для создания инстанса.",
      "server": "Сервер",
      "templates": "Шаблоны",
      "scrapers": "Парсеры",
      "awaitingConfirmation": "Ожидает подтверждения",
      "confirm": "Подтвердить",
      "counterpartyConfirmation": "Удаление контрагента",
      "areYouSureWantToConfirmCounterparty": "Вы действительно хотите удалить контрагента?",
      "group": "Группа"
    },
    "paymentSystems": {
      "paymentSystems": "Платёжные системы",
      "paymentSystemsList": "Список платежных систем",
      "counterparty": "Контрагент",
      "paymentSystem": "Платёжная система",
      "activeWallets": "Активные кошельки / счета",
      "balance": "Баланс",
      "active": "Работает",
      "disabled": "Не работает",
      "defaultSettings": "Настройки по умолчанию",
      "summaryInformation": "Сводная информация",
      "summaryInformationList": "Сводная информация по платежным системам",
      "translation": "Перевод",
      "chooseLanguage": "Необходимо выбрать язык",
      "chooseTranslate": "Необходимо указать перевод",
      "form": "Форма"
    },
    "errors": {
      "requiredField": "Обязательное поле",
      "invalidEmail": "Неверный формат почты",
      "onlyLatinDigitsAndSymbols": "Разрешены только латинские буквы, цифры и символы",
      "minFieldSize": "Минимальное допустимое кол-во символов: {{len}}",
      "maxFieldSize": "Максимальное допустимое кол-во символов: {{len}}",
      "maxFileSize": "Файл должен быть меньше {{size}} {{postfix}}",
      "fileFormatNotAllowed": "Недопустимый формат файла. Файл должен быть следующих форматов: {{formats}}",
      "invalidDateRange": "Неверный диапазон. Поле даты \"по\" должна быть больше \"с\"",
      "dateRangeIsRequired": "Необходимо заполнить диапазон дат: [с-по]",
      "invalidIpFormat": "Поле должно принимать вид или IP-адреса: от 0.0.0.0 до 255.255.255.255, или web-адреса  хоста.",
      "invalidPortFormat": "Port должен состоять только из цифр и его значение не должно превышать 65535",
      "invalidAmount": "Неверный формат суммы",
      "cannotActivatePaymentBecauseOfMinDeposit": "Для активации следующих платежных систем, задайте минимальные депозиты больше 0: {{payments}}",
      "minAmount": "Минимальное допустимое значение: {{amount}}",
      "maxAmount": "Максимальное допустимое значение: {{amount}}",
      "minAmountShouldBeGreater": "Значение должно быть больше {{amount}}",
      "setValueGreaterThan0ForThisPayment": "Данная платежная система активна. Вы не можете выставить минимальный депозит 0 для нее",
      "setValueRange": "Значение должно быть между 0 и 9999999999.9999999999",
      "invalidCardFormat": "Неверный формат номера карты. Номер карты должен состоять из 16 или 18 символов",
      "invalidPhoneFormat": "Номер телефона должен состоять из {{len}} цифр и начинаться с \"{{prefix}}\"",
      "invalidDate": "Неверная дата",
      "passwordMustNotContainSpaces": "Пароль не должен содержать пробелы",
      "invalidUrl": "Неверный формат ссылки",
      "invalidHttpsUrl": "Неверный формат ссылки. Необходимый протокол https://",
      "mustBeDifferentValues": "Нельзя задать одинаковое время для включения и отключения",
      "roleDeletionCounterpartyError": "Ошибка удаления роли на инстансах контрагентов:",
      "userDeletionCounterpartyError": "Ошибка удаления пользователя на инстансах контрагентов:",
      "roleCreationCounterpartyError": "Ошибка создания роли на инстансах контрагентов:",
      "selectAtLeastOneValue": "Необходимо выбрать хотя бы одно значение из списка",
      "invalidCodeFormat": "Неверный формат кода",
      "invalidNameFormat": "Неверный формат названия",
      "invalidCookieString": "Строка должна быть в JSON-формате"
    },
    "settings": {
      "settings": "Настройки",
      "common": "Общие",
      "commonSettings": "Общие настройки",
      "counterpartyUpdatingPeriod": "Период обновления статусов контрагентов",
      "profileSettings": "Настройки профиля",
      "language": "Язык",
      "ru": "Русский",
      "en": "Английский",
      "timeProfile": "Часовой профиль",
      "timezone": "Часовой пояс",
      "hiddenSettings": "Скрытые настройки",
      "title": "Название",
      "value": "Значение",
      "counterparty": "Контрагент",
      "hiddenSettingsList": "Список скрытых настроек"
    },
    "rbac": {
      "rbac": "Администрирование",
      "roles": "Роли",
      "users": "Пользователи",
      "name": "Название",
      "rights": "Права",
      "roleList": "Список ролей",
      "login": "Логин",
      "showRoles": "Показать роли",
      "userList": "Список пользователей",
      "showRights": "Показать права",
      "roleCreating": "Создание роли",
      "identifier": "Идентификатор",
      "addRole": "Добавить роль",
      "roleUpdating": "Редактирование роли",
      "roleRemoving": "Удаление роли",
      "addUser": "Добавить пользователя",
      "userCreating": "Добавление пользователя",
      "userUpdating": "Редактирование пользователя",
      "saRights": "Права СА",
      "counterpartiesRights": "Права контрагентов",
      "userRemoving": "Удаление пользователя",
      "roleAddingMessage": "Создаваемая роль имеет права доступа для сайта контрагентов. Создание роли у контрагентов может занять до 10 минут. Пока можете покинуть страницу. Рекомендуем проверить наличие роли у контрагентов по истечении времени.",
      "roleRemovingMessage": "Удаляемая роль имеет права доступа для сайта контрагентов. Удаление роли у контрагентов может занять до 10 минут. Пока можете покинуть страницу. Рекомендуем проверить отсутствие роли у контрагентов по истечении времени.",
      "roleCreatingStatus": "Статус создания роли"
    },
    "widgets": {
      "showThePassword": "Показать пароль",
      "hideThePassword": "Скрыть пароль",
      "listDataFetchingHasBeenFailed": "При загрузке списка произошла ошибка.",
      "itemFetchingHasBeenFailed": "При загрузке данных произошла ошибка.",
      "itemHasBeenSuccessfullyCreated": "Запись была успешно создана",
      "itemHasBeenSuccessfullyUpdated": "Запись была успешно обновлена",
      "itemHasBeenSuccessfullyDeleted": "Запись была успешно удалена",
      "itemCreatingHasBeenFailed": "При создании записи произошла ошибка.",
      "itemUpdatingHasBeenFailed": "При обновлении записи произошла ошибка.",
      "itemDeletingHasBeenFailed": "При удалении записи произошла ошибка.",
      "noRights": "Нет прав",
      "doYouReallyWantToDeleteThisItem": "Вы действительно хотите удалить запись?",
      "listIsEmpty": "Список пуст",
      "displayCells": "Показать",
      "noRoles": "Нет ролей",
      "goTo": "Перейти",
      "linesPerPage": "Строк на странице",
      "goToPage": "Перейти на страницу"
    },
    "statistics": {
      "counterparties": "Контрагенты",
      "paymentSystems": "Платежные системы",
      "indicators": "Показатели",
      "deposit": "Депозит",
      "deposits": "Депозиты",
      "depositAmount": "Сумма депозитов",
      "withdrawal": "Вывод",
      "withdrawals": "Выводы",
      "withdrawalAmount": "Сумма выводов",
      "commission": "Комиссия",
      "balance": "Баланс",
      "dateIntervalText": "За период с {{dateFrom}} по {{dateTo}}",
      "fetchDataError": "Ошибка получения данных",
      "statistics": "Статистика",
      "detailing": "Детализация",
      "byPaymentSystems": "По платежным системам",
      "byCounterparties": "По контрагентам",
      "commission_settlement": "Комиссия сеттлемента"
    },
    "mailings": {
      "mailingList": "Рассылки",
      "createMailingList": "Создание рассылки",
      "heading": "Заголовок",
      "text": "Текст",
      "recipientList": "Список получателей",
      "role": "Роль",
      "counterparty": "Контрагент",
      "recipients": "Получатели",
      "showList": "Показать список",
      "mailingsList": "Список рассылок",
      "creationDate": "Дата создания",
      "createMailing": "Создать рассылку"
    },
    "common": {
      "wallet": "Кошелек",
      "error": "Ошибка",
      "success": "Успех",
      "create": "Создать",
      "update": "Редактировать",
      "actions": "Действия",
      "delete": "Удалить",
      "cancel": "Отмена",
      "password": "Пароль",
      "status": "Статус",
      "save": "Сохранить",
      "filterSubmit": "Поиск",
      "reset": "Сбросить",
      "all": "Все",
      "comment": "Комментарий",
      "edit": "Редактировать",
      "upload": "Загрузить",
      "replaceFile": "Заменить файл",
      "dateFrom": "С",
      "dateTo": "По",
      "period": "Период",
      "currency": "Валюта",
      "filterReturnedNoResults": "По фильтру ничего не найдено",
      "gotIt": "Понятно",
      "capsLockIsOn": "Включен Caps lock",
      "add": "Добавить",
      "completed": "Завершено",
      "waiting": "Ожидание",
      "listIsEmpty": "Список пуст",
      "apply": "Применить",
      "noRights": "Нет прав",
      "name": "Название",
      "successUpdate": "Текстовые подсказки успешно обновлены",
      "errorUpdate": "При обновлении текстовых подсказок возникла ошибка",
      "engIsRequired": "Английский язык обязателен",
      "unsaveChanges": "Есть не сохраненные изменения. Закройте окно и сохраните перевод",
      "closeWindow": "Закрыть окно",
      "start": "Запустить",
      "left": "Слева",
      "right": "Справа"
    },
    "hiddenFields": {
      "type": "Тип",
      "value": "Значение",
      "id": "ID",
      "addField": "Добавить поле",
      "hiddenFields": "Скрытые поля",
      "hiddenFieldsList": "Список скрытых полей",
      "addingField": "Добавление поля",
      "description": "Описание",
      "string": "Строка",
      "integer": "Целое число",
      "boolean": "Логический",
      "json": "JSON",
      "array": "Массив",
      "fieldDeleting": "Удаление поля",
      "areYouSureYouWantToDeleteField": "Вы действительно хотите удалить поле {{ name }}?",
      "fieldsTab": "Поля"
    },
    "hiddenSettings": {
      "addingSetting": "Добавление настройки",
      "settings": "Настройки"
    }
  }
};

export default ru;
