import Widget from 'components/widgets/Widget';
import { ModalType, Permission, WidgetType } from 'utils/enums';
import { hiddenSettingsRouteTabs } from '../hiddenSettingsRouteTabs';
import { hiddenFieldsActionCreators } from 'actions/widgets/settings';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IWidgetHeadTitles } from 'interfaces/widgets/widgets';
import { IHiddenFieldRowProps } from 'interfaces/widgets/hiddenFields';
import { HiddenFieldsRow } from './HiddenFieldsRow';
import FieldCreating from 'components/widgets/hiddenFields/FieldCreating';
import FieldDeleting from 'components/widgets/hiddenFields/FieldDelete';
import withPermissionCheck from 'hocs/withPermissionCheck';

const headTitles: IWidgetHeadTitles[] = [
  {
    title: `hiddenFields.id`,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `common.name`,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `hiddenFields.type`,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `hiddenFields.description`,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `hiddenFields.value`,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `common.actions`,
    desktop: true,
    mobile: true,
    disabled: true,
  },
];

const modals = [
  {
    component: FieldCreating,
    widget: WidgetType.HIDDEN_FIELDS,
    type: ModalType.CREATE,
  },
  {
    component: FieldDeleting,
    widget: WidgetType.HIDDEN_FIELDS,
    type: ModalType.DELETE,
  },
];

const listMapping = (item: IHiddenFieldRowProps) => <HiddenFieldsRow {...item} key={item.id} />;

const HiddenFields = () => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const addHiddenField = () => {
    dispatch(hiddenFieldsActionCreators.showModal({ type: ModalType.CREATE }));
  };

  const onRefresh = () => {
    dispatch(hiddenFieldsActionCreators.getList());
  };

  const controlPanel = (
    <button onClick={addHiddenField} className="btn btn-alt-primary">
      {t(`hiddenFields.addField`)}
    </button>
  );

  return (
    <Widget
      widget={WidgetType.HIDDEN_FIELDS}
      actionCreators={hiddenFieldsActionCreators}
      pageTitle="hiddenFields.hiddenFields"
      tableTitle="hiddenFields.hiddenFieldsList"
      tableHeadTitles={headTitles}
      listMapping={listMapping}
      controlPanel={controlPanel}
      modals={modals}
      onRefresh={onRefresh}
      routeTabs={hiddenSettingsRouteTabs}
    />
  );
};

export default withPermissionCheck(HiddenFields, [
  {
    permissions: [
      {
        permission: Permission.HIDDEN_SETTINGS_UPDATE,
      },
    ],
    redirectUrl: `/no-permissions`,
  },
]);
