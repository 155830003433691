import { useEffect, useMemo } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { Dispatch } from 'redux';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import { widgetItemSelector } from '@kassma-team/kassma-toolkit/lib';

import useList from 'hooks/widgets/useList';
import { settingsActionCreators, timezonesActionCreators } from 'actions/widgets/settings';
import { ISettingsFormData, ITimezoneSettingsFormData } from 'interfaces/widgets/settings';
import { WidgetType } from 'utils/enums';

import RegularForm from 'components/form/RegularForm';

const onSubmit = (values: ISettingsFormData, dispatch: Dispatch) => {
  dispatch(settingsActionCreators.updateItem(values));
};

const TimezoneSettings = (props: InjectedFormProps<ITimezoneSettingsFormData>) => {
  const [t] = useTranslation();

  const item: ITimezoneSettingsFormData & ISettingsFormData = useSelector(widgetItemSelector(WidgetType.SETTINGS));

  useEffect(() => {
    props.initialize({ timezone: item?.timezone });
  }, [item]);

  const { items: timezoneList } = useList({
    widget: WidgetType.TIMEZONES,
    actionCreators: timezonesActionCreators,
    resetOnUnmount: false,
    loadOnlyIfNecessary: true,
  });

  const formattedTimezoneList = useMemo(
    () =>
      map(timezoneList, ({ name, value }) => ({
        text: name,
        value,
      })),
    [timezoneList]
  );

  return (
    <RegularForm
      {...props}
      fields={[
        {
          name: `timezone`,
          type: `select`,
          label: t(`settings.timezone`),
          data: formattedTimezoneList,
          required: true,
        },
      ]}
      submitText="common.save"
    />
  );
};

export default reduxForm<any>({ form: `timezone`, onSubmit })(TimezoneSettings);
