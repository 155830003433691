import { FC, useContext, createContext, useState, Dispatch, SetStateAction } from 'react';
import { FormType } from 'api/translations/TranslationsApi';

type CurrentFormTypeCtx = {
  currentFormType: FormType | null;
  setCurrentFormType: Dispatch<SetStateAction<FormType | null>>;
};

const currentFormTypeCtx = createContext<CurrentFormTypeCtx | undefined>(undefined);

export const CurrentFormTypeCtx: FC = ({ children }) => {
  const [currentFormType, setCurrentFormType] = useState<FormType | null>(null);

  const val: CurrentFormTypeCtx = {
    currentFormType,
    setCurrentFormType,
  };

  return <currentFormTypeCtx.Provider value={val}>{children}</currentFormTypeCtx.Provider>;
};

export function useCurrentFormTypeCtx(): CurrentFormTypeCtx {
  const context = useContext(currentFormTypeCtx);

  if (context === undefined) {
    throw new Error(`Is need to use useCurrentFormTypeContext inside a CurrentFormTypeContext component`);
  }

  return context;
}
