import { Reducer } from 'redux';
import map from 'lodash/map';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import size from 'lodash/size';
import has from 'lodash/has';
import roles from 'reducers/widgets/rbac/roles';
import users from 'reducers/widgets/rbac/users';

import {
  WIDGET_RESET_ERROR,
  WIDGET_RESET_MODAL,
  WIDGET_SET_ERROR,
  WIDGET_SET_MODAL,
  WIDGET_CREATE_REQUESTED,
  WIDGET_CREATE_SUCCEEDED,
  WIDGET_LIST_REQUESTED,
  WIDGET_LIST_SUCCEEDED,
  WIDGET_REMOVE_REQUESTED,
  WIDGET_REMOVE_SUCCEEDED,
  WIDGET_LIST_ITEM_UPDATE_REQUESTED,
  WIDGET_LIST_ITEM_UPDATE_SUCCEEDED,
  WIDGET_SET_PAGE,
  WIDGET_ITEM_REQUESTED,
  WIDGET_ITEM_SUCCEEDED,
  WIDGET_LIST_FAILED,
  WIDGET_ITEM_FAILED,
  WIDGET_SET_MODAL_ERROR,
  WIDGET_RESET_MODAL_ERROR,
  WIDGET_SET_SUBMITTING_STATUS,
  WIDGET_LIST_RESET,
  WIDGET_SET_ANY_DATA,
  WIDGET_SET_ITEM,
  WIDGET_SYNC_REMOVE,
  IRootWidgetsState,
  IWidgetsState,
} from '@kassma-team/kassma-toolkit/lib';

import counterparties from 'reducers/widgets/counterpartires';

const widgetActions = new Set([
  WIDGET_LIST_REQUESTED,
  WIDGET_LIST_SUCCEEDED,
  WIDGET_LIST_FAILED,
  WIDGET_LIST_RESET,
  WIDGET_SET_MODAL,
  WIDGET_RESET_MODAL,
  WIDGET_SET_ERROR,
  WIDGET_RESET_ERROR,
  WIDGET_CREATE_REQUESTED,
  WIDGET_CREATE_SUCCEEDED,
  WIDGET_REMOVE_REQUESTED,
  WIDGET_REMOVE_SUCCEEDED,
  WIDGET_SYNC_REMOVE,
  WIDGET_LIST_ITEM_UPDATE_REQUESTED,
  WIDGET_LIST_ITEM_UPDATE_SUCCEEDED,
  WIDGET_SET_PAGE,
  WIDGET_ITEM_REQUESTED,
  WIDGET_ITEM_SUCCEEDED,
  WIDGET_ITEM_FAILED,
  WIDGET_SET_ITEM,
  WIDGET_SET_MODAL_ERROR,
  WIDGET_SET_SUBMITTING_STATUS,
  WIDGET_RESET_MODAL_ERROR,
  WIDGET_SET_ANY_DATA,
]);

export const widgetDefaultState: IWidgetsState = {
  items: null,
  item: null,
  listLoading: false,
  itemLoading: false,
  error: null,
  modalError: null,
  modal: null,
  page: 1,
  offset: 0,
  offsetPage: 0,
  nextPage: false,
  paginationLoading: false,
  submitting: false,
};

export const widgetReducer: Reducer<IWidgetsState, any> = (
  state = widgetDefaultState,
  { type, payload = {}, meta = {} }
) => {
  const incPage = meta?.incPage;
  const listLabel = meta?.labels?.listLabel;
  const itemLabel = meta?.labels?.itemLabel;
  const withPagination = meta?.withPagination;

  switch (type) {
    case WIDGET_LIST_REQUESTED:
      return { ...state, listLoading: true, paginationLoading: incPage };
    case WIDGET_LIST_SUCCEEDED:
      if (withPagination) {
        const data = payload?.data;

        return {
          ...state,
          items: incPage && isArray(data) ? [...(state.items || []), ...data] : data,
          nextPage: payload.code ? payload.paginate.total < payload.paginate.offset : has(payload, `next_page_url`),
          offset: payload.paginate.offset,
          paginationLoading: false,
          listLoading: false,
          lastPage: 6 + state.offsetPage <= payload.paginate.total ? 6 + state.offsetPage : payload.paginate.total,
        };
      }

      return {
        ...state,
        listLoading: false,
        items: listLabel ? payload[listLabel] : null,
      };
    case WIDGET_LIST_FAILED:
      return { ...state, listLoading: false };
    case WIDGET_LIST_RESET:
      return { ...state, items: null, nextPage: false, offset: 0, paginationLoading: false };
    case WIDGET_ITEM_REQUESTED:
      return { ...state, itemLoading: true, item: null };
    case WIDGET_ITEM_SUCCEEDED:
      return {
        ...state,
        itemLoading: false,
        item:
          itemLabel === `permissions`
            ? {
                [itemLabel]: payload[itemLabel],
                cp_permissions: payload.cp_permissions,
              }
            : itemLabel
            ? payload[itemLabel]
            : null,
      };
    case WIDGET_ITEM_FAILED:
      return { ...state, itemLoading: false };
    case WIDGET_SET_ITEM:
      return { ...state, item: find(state.items, (item) => get(item, `id`) === payload) || null };
    case WIDGET_SET_MODAL:
      return { ...state, modal: { ...payload, submitting: false } };
    case WIDGET_SET_SUBMITTING_STATUS:
      return { ...state, submitting: payload };
    case WIDGET_RESET_MODAL:
      return { ...state, modal: null };
    case WIDGET_SET_ERROR:
      return { ...state, error: payload };
    case WIDGET_RESET_ERROR:
      return { ...state, error: null };
    case WIDGET_SET_MODAL_ERROR:
      return { ...state, modalError: payload };
    case WIDGET_RESET_MODAL_ERROR:
      return { ...state, modalError: null };
    case WIDGET_CREATE_SUCCEEDED:
      const createdItem = itemLabel ? payload[itemLabel] : null;

      if (!createdItem) {
        return state;
      }

      return { ...state, items: state.items ? [createdItem, ...state.items] : [createdItem] };
    case WIDGET_LIST_ITEM_UPDATE_SUCCEEDED:
      const updatedItem = itemLabel ? payload[itemLabel] : null;
      const id = updatedItem?.id;

      if (updatedItem && (isNumber(id) || (isString(id) && size(id) > 0))) {
        return { ...state, items: map(state.items, (item) => (item.id === id ? { ...item, ...updatedItem } : item)) };
      }

      return state;
    case WIDGET_REMOVE_SUCCEEDED:
    case WIDGET_SYNC_REMOVE:
      return {
        ...state,
        items: filter(state.items, (item) => item?.id !== payload),
      };
    case WIDGET_SET_PAGE:
      return { ...state, page: payload, offsetPage: payload };
    case WIDGET_SET_ANY_DATA:
      return isObject(payload) ? { ...state, ...payload } : state;
    default:
      return state;
  }
};

const extraWidgets: any = [counterparties, roles, users];

export const widgets: Reducer<IRootWidgetsState, any> = (state = {}, action) => {
  const widget = get(action, `meta.widget`);

  if (widget) {
    if (widgetActions.has(action.type)) {
      return { ...state, [widget]: widgetReducer(state[widget], action) };
    } else {
      const widgetData = find(extraWidgets, ({ actionTypes }) => actionTypes.has(action.type));
      if (widgetData) {
        return { ...state, [widget]: widgetData.reducer(state[widget], action) };
      }
    }
  }

  return state;
};
