import { Action } from 'redux';
import { WidgetType } from '../utils/enums';
import { AxiosError } from 'axios';

export interface IWidgetsLabel {
  listLabel?: string;
  itemLabel?: string;
}

export enum WidgetOperations {
  LIST_LOADING = `listLoading`,
  ITEM_LOADING = `itemLoading`,
  ITEM_CREATING = `itemCreating`,
  LIST_ITEM_UPDATING = `listItemUpdating`,
  ITEM_UPDATING = `itemUpdating`,
  ITEM_DELETING = `itemDeleting`,
  MULTI_DELETING = `multiDeleting`,
}

export type IToastrErrorOptions = {
  [key in WidgetOperations]: {
    onlyValue?: boolean;
  };
};

export interface IWidgetsMeta {
  labels?: IWidgetsLabel;
  withPagination?: boolean;
  widget?: WidgetType;
  incPage?: boolean;
  statusBar?: boolean;

  onSuccess?: (resp: any) => void;
  onError?: (err: AxiosError) => void;
  onFinally?: () => void;

  creatingForm?: string;
  listItemUpdatingForm?: string;
  itemUpdatingForm?: string;

  succeededCreatingMessage?: string;
  succeededUpdatingMessage?: string;
  succeededDeletingMessage?: string;

  toastrErrorOptions?: IToastrErrorOptions;

  warningDeletingMessage?: string;

  getExtraListPayload?: (payload: any) => Record<string, unknown>;

  uniqKey?: string;
  itemIsUniqKey?: boolean;
  uniqList?: boolean;
  notChangeBodyOverflowForModal?: IAction;
  fieldAsErrorBlock?: string[];

  withoutPayloadWrap?: boolean;

  supportNewFormat?: {
    [key in WidgetOperations]?: boolean;
  };
}

interface IDateField {
  name: string;
  fromName: string;
  toName: string;
}

export interface IFiltersMeta {
  dateFields?: IDateField[];
  dateFormat?: string;
  form: string;
}

export interface IActionMeta extends IWidgetsMeta, IFiltersMeta {}

export interface IAction<Payload = any, Meta = IActionMeta> extends Action {
  payload?: Payload;
  meta?: Meta;
}
