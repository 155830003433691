import { ReactNode } from 'react';
import { Dispatch } from 'redux';
import { RemovingModal as KassmaRemovingModal, widgetModalSelector } from '@kassma-team/kassma-toolkit/lib';
import { useDispatch, useSelector } from 'react-redux';
import { modes } from 'react-transition-group/SwitchTransition';

interface IProps {
  widget?: string;
  actionCreators: Record<string, any>;
  title: string;
  size?: number;
  footer?: ReactNode;
  loading?: boolean;
  footerClassName?: string;
  content?: string;
  confirmText?: string;
  modal?: any;
  dispatch?: Dispatch;
}

const RemovingModal = ({ widget, ...props }: IProps) => {
  const modal = useSelector(widgetModalSelector(widget));

  console.log(modal);

  const dispatch = useDispatch();

  return <KassmaRemovingModal {...props} modal={modal} dispatch={dispatch} />;
};

export default RemovingModal;
