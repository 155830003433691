import { BaseApi } from '../common/BaseApi';
import { AxiosPromise } from 'axios';

export class WalletApi extends BaseApi {
  loadWalletList = (queryParams: Record<string, unknown>): AxiosPromise<{ data: [] }> => {
    const { withoutQuery } = queryParams || {};
    let params = {};
    if (!withoutQuery) {
      params = {
        is_active: 1,
        removed: 0,
        ...queryParams,
      };
    }

    return this.makeFetch(`wallet`, {
      queryParams: params,
    });
  };
}

const walletApi = new WalletApi(``);

export default walletApi;
