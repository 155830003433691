import { BaseApi } from '../common/BaseApi';
import { AxiosPromise } from 'axios';

interface IUpdateHiddenSettingsData {
  id: number;
  data: Record<string, string>[];
}

export class HiddenSettingsApi extends BaseApi {
  loadHiddenSettings = (id: number): AxiosPromise => this.makeFetch(`{id}`, {}, { id });

  updateHiddenSettings = ({ id, data }: IUpdateHiddenSettingsData): AxiosPromise =>
    this.makeFetch(`{id}`, { data, method: `PUT` }, { id });
}

const hiddenSettingsApi = new HiddenSettingsApi(`hidden-settings`);

export default hiddenSettingsApi;
