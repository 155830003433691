import { Permission } from 'utils/enums';

export const hiddenSettingsRouteTabs = (permissions: string[] | null) => [
  {
    title: `hiddenSettings.settings`,
    path: `/hidden_settings`,
    hasAccess: permissions?.includes(Permission.HIDDEN_SETTINGS_UPDATE),
  },
  {
    title: `hiddenFields.fieldsTab`,
    path: `/hidden_fields`,
    hasAccess: permissions?.includes(Permission.HIDDEN_SETTINGS_UPDATE),
  },
];
