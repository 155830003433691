import { AxiosPromise } from 'axios';
import {
  sameDomainNameForApiSelector,
  apiSubdomainSelector,
  apiProtocolSelector,
  apiUrlSelector,
  ICreateApiFetchOptions,
} from '@kassma-team/kassma-toolkit/lib';

import store from '../store';
import createApiFetch, { AxiosMiddleware } from './createApiFetch';
import { newResponseOptionsMiddleware } from './middlewares/newResponseOptionsMiddleware';
import { isPathSupportNewResponse } from './newResponseSupport';

const getURL = (baseUrl: string, path: string, pathOptions?: Record<string, string | number>): string => {
  let url = `${baseUrl}/${path}`;
  if (pathOptions) {
    for (const param in pathOptions) {
      url = url.replace(`{${param}}`, pathOptions[param].toString());
    }
  }

  return url;
};

const apiFetch = (
  ...args: [string, string, Record<string, string | number>?, ICreateApiFetchOptions?]
): AxiosPromise => {
  const state = store.getState();
  const useTheSameDomainNameForApi = sameDomainNameForApiSelector(state);
  const apiSubdomain = apiSubdomainSelector(state);
  const apiProtocol = apiProtocolSelector(state);
  const apiUrl = apiUrlSelector(state);

  const [baseUrl, path, pathOptions, options] = args;

  const responsePath = `${baseUrl}/${path}`;

  const URL = getURL(baseUrl, path, pathOptions);

  const middlewares: AxiosMiddleware[] = [];

  if (isPathSupportNewResponse(responsePath)) {
    middlewares.push(newResponseOptionsMiddleware);
  }

  return createApiFetch({
    apiUrlParams: {
      apiUrl: apiUrl,
      useTheSameDomainNameForApi: useTheSameDomainNameForApi,
      subDomain: apiSubdomain,
      protocol: apiProtocol,
    },
    store,
    middlewares,
  })(URL, options);
};

export default apiFetch;
