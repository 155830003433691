import { ReactElement } from 'react';
import { IModalData } from '@kassma-team/kassma-toolkit/lib';

import { ModalType, WidgetType } from 'utils/enums';
import { deletedServersActionCreators } from 'actions/widgets/servers';
import { IServerRowProps } from 'interfaces/widgets/servers';

import Widget from 'components/widgets/Widget';
import counterpartiesRouteTabs from 'pages/widgets/counterparties/counterpartiesRouteTabs';
import ServerRow from 'components/widgets/servers/ServerRow';
import ServerCreating from 'components/widgets/servers/ServerCreating';
import useServerWidgetParams from 'hooks/widgets/servers/useServerWidgetParams';

const modals: IModalData[] = [
  {
    widget: WidgetType.SERVERS,
    component: ServerCreating,
    type: ModalType.CREATE,
  },
];

// eslint-disable-next-line
const listMapping = (item: Omit<IServerRowProps, 'widget'>) => (
  <ServerRow {...item} key={item.id} widget={WidgetType.DELETED_SERVERS} />
);

const Servers = (): ReactElement => {
  const { titles, controlPanel } = useServerWidgetParams(WidgetType.DELETED_SERVERS);

  return (
    <Widget
      widget={WidgetType.DELETED_SERVERS}
      actionCreators={deletedServersActionCreators}
      pageTitle="counterparties.counterparties"
      tableTitle="servers.serverList"
      headTitle="servers.serverList"
      tableHeadTitles={titles}
      listMapping={listMapping}
      routeTabs={counterpartiesRouteTabs}
      modals={modals}
      controlPanel={controlPanel}
      activePath="/servers"
    />
  );
};

export default Servers;
