import React, { FC, useEffect } from 'react';
import { usePaymentSystems } from '../hooks/usePaymentSystems';
import PaymentSystemsList from './PaymentSystemsList';
import FormTypesList from './FormTypes';
import { useCurrentPaymentSystemCtx } from '../ctx';
import TranslatesList from './TranslatesList';

export const DefaultTranslatesScreen: FC = () => {
  const { paymentSystems } = usePaymentSystems({ isSorted: true });

  const { currentPaymentSystem, setCurrentPaymentSystem } = useCurrentPaymentSystemCtx();

  useEffect(() => {
    // Необходимо спрятать кнопку Refresh. Из лейаута toolkit
    document.getElementById(`refresh_list_btn`)?.setAttribute(`hidden`, `true`);
  }, []);

  useEffect(() => {
    // По умолчанию должна быть выбрана 1 система.
    if (paymentSystems.length > 0 && !currentPaymentSystem) {
      setCurrentPaymentSystem(paymentSystems[0]);
    }
  }, [paymentSystems, setCurrentPaymentSystem, currentPaymentSystem]);

  return (
    <>
      <PaymentSystemsList />
      <FormTypesList />
      <TranslatesList />
    </>
  );
};
