import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import includes from 'lodash/includes';
import { Dropdown, Tooltip } from '@kassma-team/kassma-toolkit/lib';

import { ModalType, Permission } from 'utils/enums';
import { counterpartiesActionCreators } from 'actions/widgets/counterparties';
import { ICounterpartyRowProps } from 'interfaces/widgets/counterparties';

import MobileTableRow from 'components/widgets/MobileTableRow';

import { ReactComponent as Details } from 'assets/media/icons/details.svg';
import { ReactComponent as User } from 'assets/media/icons/user.svg';
import { ReactComponent as Scrapers } from 'assets/media/icons/scrapers.svg';
import { hasAccessSelector, userPermissionsSelector } from '../../../selectors/auth';
import { configSelector } from '@kassma-team/kassma-toolkit';
import { END_TO_END_AUTHORIZED } from '../../../actionTypes';

const getGroup = (group: number) => {
  switch (group) {
    case 1:
      return `A`;
    case 2:
      return `B`;
    case 3:
      return `C`;
    case 4:
      return `D`;
    default:
      return `Не определена`;
  }
};

const CounterpartyRow = ({
  id,
  identifier,
  desktopSize,
  domain_ip,
  domain,
  link,
  count,
  group,
}: ICounterpartyRowProps): ReactElement => {
  const [t] = useTranslation();
  const config: { apiUrl: string } = useSelector(configSelector);

  const dispatch = useDispatch();
  const manageAccess = useSelector(hasAccessSelector(Permission.COUNTERPARTY_MANAGE));

  const permissions: string[] | null = useSelector(userPermissionsSelector);

  const onEditClick = useCallback(() => {
    dispatch(counterpartiesActionCreators.showModal({ type: ModalType.UPDATE, payload: { id, domain } }));
  }, [dispatch, id]);

  const onDeleteClick = useCallback(() => {
    dispatch(counterpartiesActionCreators.showModal({ type: ModalType.DELETE, id }));
  }, [dispatch, id]);

  const controls = (
    <div className="counterparty-btn__group">
      {/* <button type="button" className="counterparty-btn__item initialCursor">
        <Tooltip insideContent={<span>{t(`counterparties.users`)}</span>}>
          <div className="counterparty-btn__body">
            <User />
            <span className="counterparty-btn__count">{count?.users || 0}</span>
          </div>
        </Tooltip>
      </button> */}
      <Tooltip insideContent={<span>{t(`counterparties.numberOfActiveScrapers`)}</span>}>
        <div className="counterparty-btn__body">
          <Scrapers />
          <span className="counterparty-btn__count">{count?.scrapers || 0}</span>
        </div>
      </Tooltip>
      {includes(permissions, Permission.COUNTERPARTY_MANAGE) && (
        <Dropdown
          className="counterparty-btn__item--right"
          insideContent={(onClose: () => void) => (
            <>
              <button
                className="dropdown__list-button"
                onClick={() => {
                  onEditClick();
                  onClose();
                }}
              >
                <i className="fa fa-pencil" />
                {t(`counterparties.edit`)}
              </button>
              {manageAccess && (
                <>
                  <button
                    className="dropdown__list-button"
                    onClick={() => {
                      onDeleteClick();
                      onClose();
                    }}
                  >
                    <i className="fa fa-trash" />
                    {t(`common.delete`)}
                  </button>
                </>
              )}
            </>
          )}
        >
          <button type="button" className="counterparty-btn__item counterparty-btn__item--right">
            <Details />
          </button>
        </Dropdown>
      )}
    </div>
  );

  const handleRedirect = (old_front: 1 | 0) => {
    dispatch({
      type: END_TO_END_AUTHORIZED,
      meta: {
        url: config.apiUrl,
        old_front,
        data: {
          counterparty_id: id,
        },
      },
    });
  };

  const handleClick = () => {
    handleRedirect(0);
  };

  const domainField = (
    <div className="domain-wrapper">
      <div className="domain-field" onClick={() => handleRedirect(0)}>
        {domain}
      </div>
      <div className="domain-field" onClick={() => handleRedirect(1)}>
        OLD
      </div>
    </div>
  );

  if (desktopSize) {
    return (
      <tr>
        <td>{identifier}</td>
        <td>{domain_ip}</td>
        <td>{domainField}</td>
        <td>{getGroup(group)}</td>
        <td>{controls}</td>
      </tr>
    );
  }

  return (
    <MobileTableRow>
      <tr className="first-mobile-row">
        <td>{t(`counterparties.identifier`)}</td>
        <td>{identifier}</td>
      </tr>
      <tr>
        <td>{t(`counterparties.ip`)}</td>
        <td>{domain_ip}</td>
      </tr>
      <tr>
        <td>{t(`counterparties.domainName`)}</td>
        <td>{domainField}</td>
      </tr>
      <tr>
        <td>{t(`counterparties.group`)}</td>
        <td>{getGroup(group)}</td>
      </tr>
      <tr>
        <td>{t(`common.actions`)}</td>
        <td>{controls}</td>
      </tr>
    </MobileTableRow>
  );
};

export default CounterpartyRow;
