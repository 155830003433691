import { Reducer } from 'redux';
import { USER_REMOVE_REQUESTED, USER_REMOVE_SUCCEEDED } from 'actionTypes';

const actionTypes = new Set([USER_REMOVE_REQUESTED, USER_REMOVE_SUCCEEDED]);

const reducer: Reducer = (state = {}, { type, payload = {}, meta = {} }) => {
  switch (type) {
    case USER_REMOVE_REQUESTED:
      return {
        ...state,
        modal: {
          ...state?.modal,
          submitting: true,
        },
      };
    case USER_REMOVE_SUCCEEDED:
      return {
        ...state,
        modal: {
          ...state?.modal,
          submitting: false,
        },
      };
    default:
      return state;
  }
};

const roles = { actionTypes, reducer };

export default roles;
