import { BaseApi } from '../common/BaseApi';
import { AxiosPromise } from 'axios';
import { ISettingsFormData, ISettingsResponseData } from '../../interfaces/widgets/settings';

export class SettingsApi extends BaseApi {
  loadSettings = (): AxiosPromise<ISettingsResponseData> => this.makeFetch(``);

  saveSettings = (data: ISettingsFormData): AxiosPromise => this.makeFetch(``, { data, method: `PUT` });
}

const settingsApi = new SettingsApi(`settings`);

export default settingsApi;
