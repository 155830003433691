import { FC, useContext, createContext, ReactNode } from 'react';
import { usePaymentSystems, UsePaymentSystems } from '../hooks';

type usePaymentSystemsCtx = UsePaymentSystems;
type PaymentSystemsCtx = { children: ReactNode; isPaymentSystemsListSorted?: boolean };

const ctx = createContext<usePaymentSystemsCtx | undefined>(undefined);

export const PaymentSystemsCtx: FC<PaymentSystemsCtx> = ({ children, isPaymentSystemsListSorted = true }) => {
  const { paymentSystems, isLoading } = usePaymentSystems({ isSorted: isPaymentSystemsListSorted });

  const value: usePaymentSystemsCtx = {
    paymentSystems,
    isLoading,
  };

  return <ctx.Provider value={value}>{children}</ctx.Provider>;
};

export function usePaymentSystemsCtx(): usePaymentSystemsCtx {
  const context = useContext(ctx);

  if (context === undefined) {
    throw new Error(`Is need to use usePaymentSystemsCtx inside a HintCtx component`);
  }

  return context;
}
