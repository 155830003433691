import axios from 'axios';
import { useState, useEffect } from 'react';
import api, { Hint, UpdateParams, CreateParams } from 'api/translations/TranslationsApi';
import { toastr } from 'react-redux-toastr';
import { useDispatch } from 'react-redux';
import { useModifiedHintsCtx } from '../ctx/useModifiedHintsCtx';
import { useTranslation } from 'react-i18next';
import { useFormWasSubmittedCtx } from '../ctx';

export type UseHints = {
  isLoading: boolean;
  hints: Array<Hint>;
  handeDeleteHint: (id: string) => () => Promise<void>;
  handleUpdateHint: (id: string, body: UpdateParams) => () => Promise<void>;
  handleCreateHint: (body: CreateParams) => () => Promise<void>;

  isHintUpdating: boolean;
  isHintDeleting: boolean;
  isHintCreating: boolean;
};

export function useHints(): UseHints {
  const [isLoading, setIsLoading] = useState(false);
  const [isHintDeleting, setIsHintDeleting] = useState(false);
  const [isHintUpdating, setIsHintUpdating] = useState(false);
  const [isHintCreating, setIsHintCreating] = useState(false);
  const [hints, setHints] = useState<UseHints[`hints`]>([]);
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { setModifiedHints } = useModifiedHintsCtx();
  const { setFormSubmitted } = useFormWasSubmittedCtx();

  const fetchHints = async () => {
    try {
      setIsLoading(true);

      const { data } = await api.fetchHints();

      if (data.status === `success`) {
        setHints(data.data);
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.log(e);
        dispatch(toastr.error(e.name, e.message));
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHints();
  }, []);

  const handeDeleteHint = (id: string) => async () => {
    try {
      setIsHintDeleting(true);

      api.deleteHint(id);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.log(e);
        dispatch(toastr.error(e.name, e.message));
      }
    } finally {
      setIsHintDeleting(false);
    }
  };

  const handleUpdateHint = (id: string, body: UpdateParams) => async () => {
    try {
      setIsHintUpdating(true);
      const trimmed: UpdateParams = {
        name: body.name,
        // Убираем клиентский uuid (Поле clientId)
        translations: body.translations.map(({ language, text }) => {
          return {
            language,
            text,
          };
        }),
      };

      const { data } = await api.updateHint(id, trimmed);

      if (data.status === `success`) {
        setModifiedHints([]);
        setFormSubmitted(false);
        await fetchHints();

        dispatch(toastr.success(t(`common.success`), t(`common.successUpdate`)));
      }

      if (data.status === `failed` || data.status === `error`) {
        dispatch(toastr.error(t(`common.error`), t(`common.errorUpdate`)));
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        dispatch(toastr.error(e.name, e.message));
        console.log(e);
      }
    } finally {
      setIsHintUpdating(false);
    }
  };

  const handleCreateHint = (body: CreateParams) => async () => {
    try {
      setIsHintCreating(true);
      const { data } = await api.createHint(body);

      if (data.status === `success`) {
        setModifiedHints([]);
        await fetchHints();
        dispatch(toastr.success(t(`common.success`), t(`common.successUpdate`)));
      }

      if (data.status === `error` || data.status === `failed`) {
        dispatch(toastr.error(data.status, data.error_message));
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        dispatch(toastr.error(e.name, e.message));
      }
    } finally {
      setIsHintCreating(false);
    }
  };

  return {
    isLoading,
    hints,

    handeDeleteHint,
    handleUpdateHint,
    handleCreateHint,

    isHintUpdating,
    isHintDeleting,
    isHintCreating,
  };
}
