import { getFormValues, reduxForm } from 'redux-form';
import React, { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IFormField } from '@kassma-team/kassma-toolkit';
import { InputType } from '@kassma-team/kassma-toolkit/lib/utils/enums';
import { FieldCast, WidgetType } from 'utils/enums';
import { CREATE_HIDDEN_FIELDS_FORM_NAME } from 'utils/constants';
import { hiddenFieldsActionCreators } from 'actions/widgets/settings';
import ModalForm from 'components/modals/ModalForm';
import { FieldType } from '@kassma-team/kassma-toolkit/lib';
import useValueCastsList from '../hooks/useValueCastsList';

const onSubmit = (values: any, dispatch: any) => {
  const submitValues = { ...values };
  const { val, cast } = submitValues;
  submitValues.value = val;
  if (cast === FieldCast.BOOLEAN) {
    submitValues.value = val ? `1` : `0`;
  } else if (cast === FieldCast.JSON) {
    submitValues.value = JSON.parse(val);
  } else if (submitValues.value_cast === FieldCast.ARRAY) {
    submitValues.value = submitValues.value_cast_list.map((elem: { value_cast: string }) => elem.value_cast);
  }
  delete submitValues.val;
  delete submitValues.value_cast_list;
  dispatch(hiddenFieldsActionCreators.create(submitValues));
};
const FieldCreating = (props: any): ReactElement => {
  const [t] = useTranslation();

  const values = useSelector(getFormValues(props.form));

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const cast: FieldCast | undefined = values?.value_cast;

  const renderWallets = useValueCastsList();

  const valueField: IFormField = useMemo(() => {
    switch (cast) {
      case FieldCast.STRING:
        return {
          name: `val`,
          label: t(`hiddenFields.value`),
          required: true,
        };
      case FieldCast.JSON:
        return {
          name: `val`,
          label: t(`hiddenFields.value`),
          jsonFields: [],
          required: true,
          type: InputType.TEXTAREA,
        };
      case FieldCast.INTEGER:
        return {
          name: `val`,
          label: t(`hiddenFields.value`),
          numeric: true,
          required: true,
        };
      case FieldCast.BOOLEAN:
        return {
          name: `val`,
          label: t(`hiddenFields.value`),
          type: InputType.CHECKBOX,
        };
    }
  }, [cast]);

  const fields = useMemo(() => {
    const arr: IFormField[] = [
      {
        name: `name`,
        label: t(`common.name`),
        required: true,
        regexMatch: {
          regex: /^[-._a-zA-Z0-9]+$/,
          message: t(`errors.invalidNameFormat`),
        },
      },
      {
        name: `description`,
        label: t(`hiddenFields.description`),
        type: InputType.TEXTAREA,
        required: true,
      },
      {
        name: `value_cast`,
        label: t(`hiddenFields.type`),
        type: InputType.SELECT,
        data: [
          {
            value: FieldCast.STRING,
            text: `hiddenFields.string`,
          },
          {
            value: FieldCast.INTEGER,
            text: `hiddenFields.integer`,
          },
          {
            value: FieldCast.BOOLEAN,
            text: `hiddenFields.boolean`,
          },
          {
            value: FieldCast.JSON,
            text: `hiddenFields.json`,
          },
          {
            value: FieldCast.ARRAY,
            text: `hiddenFields.array`,
          },
        ],
        translateKeys: true,
        required: true,
      },
      ...(cast === FieldCast.ARRAY
        ? [
            {
              name: `value_cast_list`,
              label: t(`settings.value`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderWallets,
            },
          ]
        : []),
    ];

    if (cast) {
      arr.push(valueField);
    }

    return arr;
  }, [valueField, t, cast]);

  return (
    <ModalForm
      {...props}
      actionCreators={hiddenFieldsActionCreators}
      submitText="common.create"
      fields={fields}
      title={`hiddenFields.addingField`}
      widget={WidgetType.HIDDEN_FIELDS}
    />
  );
};

export default reduxForm({
  form: CREATE_HIDDEN_FIELDS_FORM_NAME,
  forceUnregisterOnUnmount: true,
  onSubmit,
})(FieldCreating as any);
