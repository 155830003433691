import { i18n } from '@kassma-team/kassma-toolkit/lib';
import { initReactI18next } from 'react-i18next';

import ru from 'i18n/ru';
import en from 'i18n/en';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      ru,
      en,
    },
    lng: `ru`,
    fallbackLng: `ru`,
  });

export default i18n;
