import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import rootReducer from 'reducers';
import rootHistory from 'rootHistory';
import rootSaga from 'sagas';

const sagaMiddleware = createSagaMiddleware();

const store: Store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(routerMiddleware(rootHistory), sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export default store;
