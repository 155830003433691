import { BaseApi } from '../common/BaseApi';
import { AxiosPromise } from 'axios';
import { IPaymentSystemListResponse } from '../../interfaces/widgets/paymentSystems';
import { IIntranetCurrencyFormData } from '../../interfaces/widgets/intranetCurrencies';

export class CurrenciesApi extends BaseApi {
  loadCurrencyList = (): AxiosPromise<IPaymentSystemListResponse> => this.makeFetch(``);
  loadNewCurrencyList = (queryParams: Record<string, unknown>): AxiosPromise => this.makeFetch(`list`, { queryParams });
  removeCurrency = (code: string): AxiosPromise =>
    this.makeFetch(``, { method: `DELETE`, queryParams: { codes: [code] } });
  createCurrency = (data: IIntranetCurrencyFormData): AxiosPromise => this.makeFetch(``, { method: `POST`, data });
  updateCurrency = (id: string, data: IIntranetCurrencyFormData): AxiosPromise =>
    this.makeFetch(``, { data, method: `PUT` });
  updateCurrenciesRate = (codes?: string[]): AxiosPromise => {
    const queryParams = codes && { codes };

    return this.makeFetch(`init-rate-update`, { queryParams });
  };
}

const currenciesApi = new CurrenciesApi(`currencies`);

export default currenciesApi;
