import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';

import { WalletType } from 'api/translations/TranslationsApi';
import { useSaveModalCtx, useCurrentPaymentSystemCtx, useModifiedHintsCtx, usePaymentSystemsCtx } from '../../ctx';
import { SaveModal } from '../../components/SaveModal';

import './PaymentSystemList.scss';

export const PaymentSystemList: FC = () => {
  const { paymentSystems, isLoading: isLoadingPaymentSystems } = usePaymentSystemsCtx();
  const { currentPaymentSystem, setCurrentPaymentSystem } = useCurrentPaymentSystemCtx();

  console.log({ paymentSystems });

  const { modifiedHints } = useModifiedHintsCtx();
  const { handleToggleModal, handleSave, isSaveModalOpen } = useSaveModalCtx();

  const [t] = useTranslation();

  const handleCheckSystem = (ps: WalletType) => () => {
    if (modifiedHints.length) {
      handleToggleModal(true)();

      return;
    }

    setCurrentPaymentSystem(ps);
  };

  /**
   * isChecked
   *
   * Определяает состояние checked для ПС в списке.
   */
  const isChecked = (firstId: string, secondId?: string) => firstId === secondId;

  return (
    <section className="PaymentSystemsList">
      <h2 className="PaymentSystemsList__title">{t(`paymentSystems.paymentSystem`)}</h2>
      <ul className="PaymentSystemsList__list">
        {isLoadingPaymentSystems ? (
          <Skeleton count={40} />
        ) : (
          paymentSystems.map((ps) => {
            return (
              <li
                key={ps.code}
                className={cn(`PaymentSystemsList__item`, {
                  'PaymentSystemsList__item--checked': isChecked(ps.code, currentPaymentSystem?.code),
                })}
                onClick={handleCheckSystem(ps)}
              >
                {ps.name}
              </li>
            );
          })
        )}
      </ul>
      {isSaveModalOpen && <SaveModal onClose={handleToggleModal(false)} onSave={handleSave} />}
    </section>
  );
};
