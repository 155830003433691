import {
  COUNTERPARTY_CREATING_DATA_FAILED,
  COUNTERPARTY_CREATING_DATA_REQUESTED,
  COUNTERPARTY_CREATING_DATA_SUCCEEDED,
  RESET_COUNTERPARTY_CREATING_DATA,
} from 'actionTypes';
import { Reducer } from 'redux';
import { ICounterpartiesState } from 'interfaces/widgets/counterparties';
import { widgetDefaultState } from 'reducers/widgets/widgets';

const actionTypes = new Set([
  COUNTERPARTY_CREATING_DATA_REQUESTED,
  COUNTERPARTY_CREATING_DATA_SUCCEEDED,
  COUNTERPARTY_CREATING_DATA_FAILED,
  RESET_COUNTERPARTY_CREATING_DATA,
]);

const reducer: Reducer<ICounterpartiesState | undefined, any> = (state = widgetDefaultState, { type, payload }) => {
  switch (type) {
    case COUNTERPARTY_CREATING_DATA_REQUESTED:
      return {
        ...state,
        creatingData: null,
        creatingDataLoading: true,
      };
    case COUNTERPARTY_CREATING_DATA_FAILED:
      return {
        ...state,
        creatingData: null,
        creatingError: payload,
        creatingDataLoading: false,
      };
    case COUNTERPARTY_CREATING_DATA_SUCCEEDED:
      return {
        ...state,
        creatingData: payload,
        creatingDataLoading: false,
      };
    case RESET_COUNTERPARTY_CREATING_DATA:
      return {
        ...state,
        creatingData: null,
        creatingDataLoading: false,
        creatingError: null,
      };
    default:
      return state;
  }
};

const counterparties = { actionTypes, reducer };

export default counterparties;
