import { RouteTabs as KassmaRouteTabs, GetRouteTabs } from '@kassma-team/kassma-toolkit/lib';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import useRouteName from 'hooks/useRouteName';
import { userPermissionsSelector } from '../../selectors/auth';

const RouteTabs = ({ routeTabs }: GetRouteTabs) => {
  const routeName = useRouteName();
  const permissions = useSelector(userPermissionsSelector);

  return <KassmaRouteTabs routeTabs={routeTabs} routeName={routeName} permissions={permissions} ReactNavLink={Link} />;
};

export default RouteTabs;
