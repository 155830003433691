const en = {
  "translation": {
    "auth": {
      "login": "Login",
      "password": "Password",
      "signIn": "Sign in",
      "logout": "Log out",
      "configError": "Config error",
      "noRequiredParameters": "No requiered parameters"
    },
    "logs": {
      "logs": "Logs",
      "walletLogs": "Wallet logs",
      "logList": "Log list",
      "walletType": "Wallet type",
      "category": "Сategory",
      "walletIdentifier": "Wallet identifier",
      "errorType": "Error type",
      "message": "Message",
      "date": "Date",
      "messageIsEmpty": "Message is empty",
      "debugging": "Debugging"
    },
    "domains": {
      "domainList": "Domain list",
      "domains": "Domains",
      "addDomain": "Add domain",
      "domainName": "Domain name",
      "registerDate": "Registration date",
      "expiration": "Validity",
      "domainMustBeRegistered": "Domain must be pre-registered",
      "free": "Free",
      "engaged": "Engaged",
      "domainUpdating": "Domain updating",
      "domainCreating": "Domain creating",
      "domainDeleting": "Domain deleting",
      "processing": "Processing"
    },
    "servers": {
      "serverList": "Server list",
      "servers": "Servers",
      "addServer": "Add server",
      "registerDate": "Registration date",
      "expiration": "Validity",
      "ipAddress": "IP",
      "specifications": "Specification",
      "addingServerInfo": "When creating a server automatically, you need to specify its characteristics, the server will be automatically purchased, its parameters will appear in the table. If added manually, the server must be pre-purchased.",
      "login": "Login",
      "free": "Free",
      "engaged": "Engaged",
      "notAvailable": "Not available",
      "serverAdding": "Server adding",
      "ram": "RAM",
      "cpu": "CPU",
      "rom": "ROM",
      "serverDeleting": "Server deleting",
      "showOnlyDeleted": "Display only deleted",
      "deleted": "Deleted",
      "ramFieldInfo": "Specify the required amount of RAM for the server",
      "cpuFieldInfo": "Specify the required number of server CPU cores",
      "romFieldInfo": "Specify the required amount of persistent server memory"
    },
    "currencies": {
      "currencies": "Currencies",
      "code": "Code",
      "name": "Name",
      "symbol": "Symbol",
      "symbolPosition": "Symbol position",
      "isCrypto": "Is crypto",
      "rate": "Rate",
      "rateUpdatedAt": "Rate updated at",
      "currencyRemoving": "Removing a currency",
      "areYouSureToDeleteCurrency": "Are you sure you want to delete {{code}} currency?",
      "startRateUpdating": "Start updating exchange rates",
      "areYouSureToStartRateUpdate": "Are you sure you want to start updating the rates of all currencies?",
      "currencyCreating": "Creating a currency",
      "currencyUpdating": "Currency updating",
      "addCurrency": "Add currency",
      "updateExchangeRates": "Update exchange rates",
      "currencyWillBeRemovedPermanently": "The currency will be permanently removed from all parts of the system.",
      "updateExchangeRate": "Update exchange rate"
    },
    "counterparties": {
      "counterparties": "Counterparty",
      "counterpartyList": "Counterparty list",
      "identifier": "ID",
      "ip": "IP address",
      "domainName": "Domain",
      "domainExpiration": "Domain validity",
      "active": "Working",
      "disabled": "Not working",
      "addCounterparty": "Add counterparty",
      "counterpartyCreating": "Adding a counterparty",
      "statusesHaveBeenSuccessfullyUpdated": "Statuses have been successfully updated",
      "counterpartyUpdating": "Counterparty updating",
      "statusesLastUpdate": "Statuses last updated: {{date}}",
      "login": "Login",
      "role": "Role",
      "noUsers": "User list is empty",
      "users": "Users",
      "noPaymentSystems": "Payment system list is empty",
      "paymentSystems": "Payment systems",
      "edit": "Edit",
      "deploy": "Deploy",
      "numberOfActiveScrapers": "Number of active scrapers",
      "autoInstallation": "Auto instantiation",
      "autoInstallationDescr": "When adding manually, the system must be pre-configured. This window only indicates the system parameters for informational purposes.",
      "identifierDescr": "System identifier - its name, if not specified, it will be recorded by domain.",
      "domainDescr": "Domain name must be registered in advance, select from the list of existing ones or pre-enter a new domain name.",
      "serverDescr": "The server must be registered in advance, select from the list of existing ones or pre-start a new server.",
      "loginDescr": "The username of the account you want to use to create the instance.",
      "server": "Server",
      "templates": "Templates",
      "scrapers": "Scrapers",
      "awaitingConfirmation": "Awaiting confirmation",
      "confirm": "Confirm",
      "counterpartyConfirmation": "Counterparty deleting",
      "areYouSureWantToConfirmCounterparty": "Are you sure you want to delete the counterparty?",
      "group": "Group"
    },
    "paymentSystems": {
      "paymentSystems": "Payment systems",
      "paymentSystemsList": "Payment systems list",
      "counterparty": "Counterparty",
      "paymentSystem": "Payment system",
      "activeWallets": "Active wallets / accounts",
      "balance": "Balance",
      "active": "Works",
      "disabled": "Doesn't work",
      "defaultSettings": "Default settings",
      "summaryInformation": "Summary information",
      "summaryInformationList": "Summary information on payment systems",
      "translation": "Translation",
      "chooseLanguage": "Choose language",
      "chooseTranslate": "Choose translation",
      "form": "Form"
    },
    "errors": {
      "requiredField": "Required field",
      "invalidEmail": "Invalid email format",
      "onlyLatinDigitsAndSymbols": "Only latin letters, numbers and symbols are allowed",
      "minFieldSize": "Minimum allowed characters: {{len}}",
      "maxFieldSize": "Maximum allowed characters: {{len}}",
      "maxFileSize": "File should be less than {{size}} {{postfix}}",
      "fileFormatNotAllowed": "Invalid file format. The file must be in the following formats: {{formats}}",
      "invalidDateRange": "Invalid date range. \"Date to\" should be greater than \"Date from\" ",
      "dateRangeIsRequired": "Date range should be filled",
      "invalidIpFormat": "Field should either be IP address (from 0.0.0.0 to 255.255.255.255) or host's address",
      "invalidPortFormat": "Port shoud be a number and its value should not exceed 65535",
      "invalidAmount": "Invalid amount format",
      "cannotActivatePaymentBecauseOfMinDeposit": "To activate following payment systems, set non-zero minimum deposit: {{payments}}",
      "minAmount": "Minimum allowed value: {{amount}}",
      "maxAmount": "Maximum allowed value: {{amount}}",
      "minAmountShouldBeGreater": "Amount should be greater than {{amount}}",
      "setValueGreaterThan0ForThisPayment": "This payment system is active. You can't set minimum deposit to 0",
      "setValueRange": "Amount should be in range from 0 to 9999999999.9999999999",
      "invalidCardFormat": "Invalid card number format. Card number length must be 16 or 18",
      "invalidPhoneFormat": "Phone number must be {{len}} digits and start with \"{{prefix}}\"",
      "invalidDate": "Invalid date",
      "passwordMustNotContainSpaces": "Password must not contain spaces",
      "invalidUrl": "Invalid URL format",
      "invalidHttpsUrl": "Invalid URL format. Required protocol: https://",
      "mustBeDifferentValues": "Disabling and enabling time must be different",
      "roleDeletionCounterpartyError": "Role deletion error on counterparty instances:",
      "userDeletionCounterpartyError": "User deletion error on counterparty instances:",
      "roleCreationCounterpartyError": "Role creation error on counterparty instances:",
      "selectAtLeastOneValue": "You must select at least one value from the list",
      "invalidCodeFormat": "Invalid code format",
      "invalidNameFormat": "Invalid name format",
      "invalidCookieString": "Invalid JSON format"
    },
    "settings": {
      "settings": "Settings",
      "common": "General",
      "commonSettings": "General settings",
      "counterpartyUpdatingPeriod": "Counterparty statuses update period",
      "profileSettings": "Profile settings",
      "language": "Language",
      "ru": "Russian",
      "en": "English",
      "timeProfile": "Time profile",
      "timezone": "Timezone",
      "hiddenSettings": "Hidden settings",
      "title": "Title",
      "value": "Value",
      "counterparty": "Counterparty",
      "hiddenSettingsList": "Hidden settings list"
    },
    "rbac": {
      "rbac": "Administration",
      "roles": "Roles",
      "users": "Users",
      "name": "Name",
      "rights": "Rights",
      "roleList": "List of roles",
      "login": "Login",
      "showRoles": "Show roles",
      "userList": "User list",
      "showRights": "Show rights",
      "roleCreating": "Role creation",
      "identifier": "Identifier",
      "addRole": "Add role",
      "roleUpdating": "Role updating",
      "roleRemoving": "Role removing",
      "addUser": "Add user",
      "userCreating": "Adding user",
      "userUpdating": "User updating",
      "saRights": "SA rights",
      "counterpartiesRights": "Counterparties rights",
      "userRemoving": "User removing",
      "roleAddingMessage": "The created role has access rights for the site of counterparties. It can take up to 10 minutes to create a role for counterparties. You can leave the page for now. We recommend checking the role of counterparties after the expiration of time.",
      "roleRemovingMessage": "The role to be removed has access rights for the counterparties site. Removing a role from counterparties can take up to 10 minutes. You can leave the page for now. We recommend checking the role of counterparties after the expiration of the time.",
      "roleCreatingStatus": "Role creating status"
    },
    "widgets": {
      "showThePassword": "Show password",
      "hideThePassword": "Hide password",
      "listDataFetchingHasBeenFailed": "List data fetching has been failed.",
      "itemFetchingHasBeenFailed": "Item fetching has been failed.",
      "itemHasBeenSuccessfullyCreated": "Item has been successfully created",
      "itemHasBeenSuccessfullyUpdated": "Item has been successfully updated",
      "itemHasBeenSuccessfullyDeleted": "Item has been successfully deleted",
      "itemCreatingHasBeenFailed": "Item creating has been failed",
      "itemUpdatingHasBeenFailed": "Item updating has been failed",
      "itemDeletingHasBeenFailed": "Item deleted has been failed",
      "noRights": "No rights",
      "doYouReallyWantToDeleteThisItem": "Are you sure you want to delete this item?",
      "listIsEmpty": "List is empty",
      "displayCells": "Display",
      "noRoles": "No roles",
      "goTo": "Go to",
      "linesPerPage": "Lines per page",
      "goToPage": "Go to page"
    },
    "statistics": {
      "counterparties": "Counterparties",
      "paymentSystems": "Payment systems",
      "indicators": "Indicators",
      "deposit": "Deposit",
      "deposits": "Deposits",
      "depositAmount": "Deposit amount",
      "withdrawal": "Withdrawal",
      "withdrawals": "Withdrawals",
      "withdrawalAmount": "Withdrawal amount",
      "commission": "Commission",
      "balance": "Balance",
      "dateIntervalText": "For period from {{dateFrom}} to {{dateTo}}",
      "fetchDataError": "Receiving data error",
      "statistics": "Statistics",
      "detailing": "Detailing",
      "byPaymentSystems": "By payment systems",
      "byCounterparties": "By counterparties",
      "commission_settlement": "Commission settlement"
    },
    "mailings": {
      "mailingList": "Mailing list",
      "createMailingList": "Create a mailing list",
      "heading": "Heading",
      "text": "Text",
      "recipientList": "Recipient list",
      "role": "Role",
      "counterparty": "Counterparty",
      "recipients": "Recipients",
      "showList": "Show list",
      "mailingsList": "Mailings list",
      "creationDate": "Creation date",
      "createMailing": "Сreate mailing"
    },
    "common": {
      "wallet": "Wallet",
      "error": "Error",
      "success": "Success",
      "create": "Create",
      "update": "Update",
      "actions": "Actions",
      "delete": "Delete",
      "cancel": "Cancel",
      "password": "Password",
      "status": "Status",
      "save": "Save",
      "filterSubmit": "Submit",
      "reset": "Reset",
      "all": "All",
      "comment": "Comment",
      "edit": "Edit",
      "upload": "Upload",
      "replaceFile": "Replace file",
      "dateFrom": "Date from",
      "dateTo": "Date to",
      "period": "Period",
      "currency": "Currency",
      "filterReturnedNoResults": "The filter returned no results",
      "gotIt": "Got it",
      "capsLockIsOn": "Caps-Lock is on",
      "add": "Add",
      "completed": "Completed",
      "waiting": "Waiting",
      "listIsEmpty": "List is empty",
      "apply": "Apply",
      "noRights": "No rights",
      "name": "Name",
      "successUpdate": "Text hints have been successfully updated",
      "errorUpdate": "An error occured while updating text hints.",
      "engIsRequired": "English is required",
      "unsaveChanges": "There are unsaved changes. Close the window and save the translation",
      "closeWindow": "Close the window",
      "start": "Start",
      "left": "Left",
      "right": "Right"
    },
    "hiddenFields": {
      "type": "Type",
      "value": "Value",
      "id": "ID",
      "addField": "Add field",
      "hiddenFields": "Hidden fields",
      "hiddenFieldsList": "Hidden fields list",
      "addingField": "Adding field",
      "description": "Description",
      "string": "String",
      "integer": "Integer",
      "boolean": "Logical",
      "json": "JSON",
      "array": "Array",
      "fieldDeleting": "Deleting field",
      "areYouSureYouWantToDeleteField": "Are you sure you want to delete {{ name }} field?",
      "fieldsTab": "Fields"
    },
    "hiddenSettings": {
      "addingSetting": "Adding setting",
      "settings": "Settings"
    }
  }
};

export default en;
