import { useList as useKassmaList, widgetSelector, IWidgetActionCreators } from '@kassma-team/kassma-toolkit/lib';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

interface IWidgetProps<Item = any> {
  items: Item | null;
  listLoading: boolean;
}
interface IProps<Item = any> {
  items?: Item | null;
  listLoading?: boolean;
  actionCreators?: IWidgetActionCreators;
  widget?: string;
  queryParams?: Record<string, unknown>;
  resetOnUnmount?: boolean;
  loadOnlyIfNecessary?: boolean;
  disableLoading?: boolean;
  dispatch?: Dispatch;
}

const useList = <Item = any>({
  actionCreators,
  queryParams,
  resetOnUnmount,
  loadOnlyIfNecessary,
  disableLoading,
  widget,
}: IProps): IWidgetProps<Item> => {
  const { items, listLoading }: IWidgetProps = useSelector(widgetSelector(widget));

  const dispatch = useDispatch();

  return useKassmaList({
    actionCreators,
    queryParams,
    resetOnUnmount,
    loadOnlyIfNecessary,
    disableLoading,
    items,
    listLoading,
    dispatch,
  });
};

export default useList;
