import { IIntranetCurrencyRowProps, SymbolPosition } from '../../../interfaces/widgets/intranetCurrencies';
import MobileTableRow from '../MobileTableRow';
import { useTranslation } from 'react-i18next';
import { intranetCurrencyActionCreators } from '../../../actions/widgets/currency';
import { ModalType } from '../../../utils/enums';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import currenciesApi from '../../../api/currency/CurrenciesApi';
import useSearchParams from '../../../hooks/useSearchParams';
import { Tooltip } from '@kassma-team/kassma-toolkit';

const CurrencyRow = ({
  code,
  name,
  symbol,
  symbol_position,
  is_crypto,
  rate,
  desktopSize,
}: IIntranetCurrencyRowProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const onDeleteClick = useCallback(() => {
    dispatch(intranetCurrencyActionCreators.showModal({ type: ModalType.DELETE, id: code }));
  }, [dispatch]);

  const queryParams = useSearchParams();

  const onUpdateClick = () => {
    currenciesApi.updateCurrenciesRate([code]).then(() => {
      setTimeout(() => {
        dispatch(intranetCurrencyActionCreators.getList(queryParams));
      }, 4000);
    });
  };

  const onEditClick = useCallback(() => {
    dispatch(intranetCurrencyActionCreators.showModal({ type: ModalType.UPDATE, code }));
  }, [dispatch]);

  const controls = (
    <div className="btn-group">
      <Tooltip insideContent={t(`currencies.updateExchangeRate`)}>
        <button className="btn btn-secondary" type="button" onClick={onUpdateClick}>
          <i className="si si-refresh" />
        </button>
      </Tooltip>
      <Tooltip insideContent={t(`common.delete`)}>
        <button className="btn btn-secondary" type="button" onClick={onDeleteClick}>
          <i className="fa fa-trash" />
        </button>
      </Tooltip>
      <Tooltip insideContent={t(`common.update`)}>
        <button className="btn btn-secondary" type="button" onClick={onEditClick}>
          <i className="fa fa-pencil" />
        </button>
      </Tooltip>
    </div>
  );

  const isCryptoDisplay = is_crypto ? <i className="fa fa-plus" /> : <i className="fa fa-minus" />;
  const symbolPositionDisplay = symbol_position === SymbolPosition.LEFT ? t(`common.left`) : t(`common.right`);

  if (desktopSize) {
    return (
      <tr>
        <td>{code}</td>
        <td>{name || code}</td>
        <td>{symbol}</td>
        <td>{symbolPositionDisplay}</td>
        <td>{isCryptoDisplay}</td>
        <td>{rate?.rate}</td>
        <td>{rate?.updated_at}</td>
        <td>{controls}</td>
      </tr>
    );
  }

  return (
    <MobileTableRow>
      <tr className="first-mobile-row">
        <td>{t(`currencies.code`)}</td>
        <td>{code}</td>
      </tr>
      <tr>
        <td>{t(`currencies.name`)}</td>
        <td>{name || code}</td>
      </tr>
      <tr>
        <td>{t(`currencies.symbol`)}</td>
        <td>{symbol}</td>
      </tr>
      <tr>
        <td>{t(`currencies.symbolPosition`)}</td>
        <td>{symbolPositionDisplay}</td>
      </tr>
      <tr>
        <td>{t(`currencies.isCrypto`)}</td>
        <td>{isCryptoDisplay}</td>
      </tr>
      <tr>
        <td>{t(`currencies.rate`)}</td>
        <td>{rate?.rate}</td>
      </tr>
      <tr>
        <td>{t(`currencies.rateUpdatedAt`)}</td>
        <td>{rate?.updated_at}</td>
      </tr>
      <tr>
        <td>{t(`common.actions`)}</td>
        <td>{controls}</td>
      </tr>
    </MobileTableRow>
  );
};

export default CurrencyRow;
