import { ComponentType, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Header as KassmaHeader, INavListData } from '@kassma-team/kassma-toolkit/lib';
import LoadingBar from 'react-redux-loading-bar';

import useNavList from 'hooks/useNavList';

import logo from 'assets/media/logo.svg';

export interface IHeaderProps {
  logo?: ReactNode;
  list?: INavListData[];
  loadingBar?: ReactNode;
  ReactNavLink?: ComponentType<any>;
}

const renderLogo = <img src={logo} alt="" />;

const Header = (props: IHeaderProps) => {
  const list = useNavList();

  return (
    <KassmaHeader
      logo={(<span className="font-w700 mr-5">{renderLogo}</span>) as ReactNode}
      list={list}
      loadingBar={<LoadingBar className="progress-line-bar" showFastActions />}
      ReactNavLink={Link}
    />
  );
};

export default Header;
