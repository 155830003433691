import { IFormField, InputType } from '@kassma-team/kassma-toolkit';
import { useTranslation } from 'react-i18next';
import { SymbolPosition } from '../../../interfaces/widgets/intranetCurrencies';
import { useMemo } from 'react';

export type UseCurrencyFieldsProps = {
  is_crypto: boolean;
  creating: boolean;
};

const useCurrencyFields = ({ is_crypto, creating }: UseCurrencyFieldsProps): IFormField[] => {
  const [t] = useTranslation();

  return useMemo(() => {
    return [
      {
        name: `code`,
        label: t(`currencies.code`),
        onlyLatinDigitsAndSymbols: true,
        minLength: 3,
        maxLength: 8,
        required: true,
        regexMatch: {
          regex: /[A-Z]{3,8}/,
          message: t(`errors.invalidCodeFormat`),
        },
        disabled: !creating,
      },
      {
        name: `symbol`,
        label: t(`currencies.symbol`),
        required: true,
        trim: true,
        maxLength: 10,
      },
      {
        name: `symbol_position`,
        label: t(`currencies.symbolPosition`),
        type: InputType.SELECT,
        data: [
          {
            text: `common.left`,
            value: SymbolPosition.LEFT,
          },
          {
            text: `common.right`,
            value: SymbolPosition.RIGHT,
          },
        ],
        translateKeys: true,
      },
      {
        name: `is_crypto`,
        label: t(`currencies.isCrypto`),
        type: InputType.CHECKBOX,
      },
      {
        name: `name`,
        label: t(`currencies.name`),
        maxLength: 50,
        trim: true,
        disabled: !is_crypto,
      },
    ];
  }, [is_crypto, creating]);
};

export default useCurrencyFields;
