import { BaseApi } from '../common/BaseApi';
import { AxiosPromise } from 'axios';

type FailedResponse = {
  status: `error` | `failed`;
  error_message: string;

  data?: never;
};

type SuccessResponse<T> = {
  status: `success`;
  code: 20000;
  data: T;

  error_message?: never;
};

type Response<T> = FailedResponse | SuccessResponse<T>;

type Translation = {
  language: string;
  text: string;

  clientId?: string;
};

export type Lang = string;

export type FormType = {
  type: string;
  title: string;
};

export type WalletType = {
  code: string;
  uuid: string;
  payment_type: string;
  name: string;
};

export type Hint = {
  created_at: string;
  form_type: string;
  is_default: true;
  name: string;
  updated_at: string;
  uuid: string;
  wallet_type: string;
  translations: Array<Translation>;
};

type ListResponse = AxiosPromise<Response<Array<Hint>>>;

type HintResponse = AxiosPromise<Response<Hint>>;

export type CreateParams = {
  wallet_type: string;
  form_type: string;
  name: string;
  translations: Array<Translation>;
};

type CreateResponse = AxiosPromise<Response<{ uuid: string }>>;

export type UpdateParams = {
  name: string;
  translations: Array<Translation>;
};

type UpdateResponse = AxiosPromise<Response<unknown>>;

export class TranslationsApi extends BaseApi {
  fetchFormTypes = () =>
    this.makeFetch<Response<Array<FormType>>>(`form-type`, { headers: { 'x-version': `1.000.0` } });

  fetchLangs = () => this.makeFetch<Response<Array<Lang>>>(`language`, { headers: { 'x-version': `1.000.0` } });

  fetchPaymentSystems = () =>
    this.makeFetch<Response<Array<WalletType>>>(`wallet-types`, { headers: { 'x-version': `1.000.0` } });

  fetchHints = (): ListResponse => this.makeFetch(`hint`, { headers: { 'x-version': `1.000.0` } });

  fetchHint = (id: string): HintResponse => this.makeFetch(`hint/${id}`, { headers: { 'x-version': `1.000.0` } });

  deleteHint = (id: string): AxiosPromise =>
    this.makeFetch(`hint/${id}`, { method: `DELETE`, headers: { 'x-version': `1.000.0` } });

  createHint = (body: CreateParams): CreateResponse =>
    this.makeFetch(`hint`, { method: `POST`, data: body, headers: { 'x-version': `1.000.0` } });

  updateHint = (id: string, body: UpdateParams): UpdateResponse =>
    this.makeFetch(`hint/${id}`, { method: `PUT`, data: body, headers: { 'x-version': `1.000.0` } });
}

const translationsApi = new TranslationsApi(`translations`);

export default translationsApi;
