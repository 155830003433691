export const newResponseSupport: Record<string, Record<string, boolean>> = {
  [`auth`]: {
    [`refresh`]: false,
    [`logout`]: false,
    [`login`]: true,
  },
  [`wallet`]: {
    [`list-not-removed-wallets`]: false,
  },
  [`wallet-log`]: {
    [``]: false,
  },
  [`counterparty`]: {
    [``]: true,
    [`{id}`]: true,
    [`disapprove`]: false,
    [`approve`]: false,
    [`for-filter`]: false,
    [`deploy-components`]: false,
    [`refresh-status`]: false,
  },
  [`currencies`]: {
    [``]: false,
  },
  [`domain`]: {
    [``]: false,
    [`{id}`]: false,
  },
  [`hidden-settings`]: {
    [`{id}`]: false,
  },
  [`payment-systems`]: {
    [``]: false,
    [`for-filter`]: false,
  },
  [`server`]: {
    [``]: false,
    [`{id}`]: false,
    [`with-deleted`]: false,
  },
  [`settings`]: {
    [``]: false,
  },
  [`statistics`]: {
    [`dashboard`]: false,
    [`filter`]: false,
  },
  [`timezones`]: {
    [`list`]: false,
  },
  [`translations`]: {
    [`wallet-types`]: true,
  },
};

export const isPathSupportNewResponse = (path: string): boolean => {
  const parts = path.split(`/`);

  const part = parts[0];
  const route = parts.slice(1).join(`/`) || ``;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!newResponseSupport[part]) {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return newResponseSupport[part][route];
};
