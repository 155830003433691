import React, { useState } from 'react';
import get from 'lodash/get';
import size from 'lodash/size';
import useMount from 'react-use/lib/useMount';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IWidgetActionCreators, widgetModalSelector } from '@kassma-team/kassma-toolkit/lib';

import ModalElement from 'components/modals/ModalElement';

interface IProps {
  widget: string;
  actionCreators: IWidgetActionCreators;
}

const Message = ({ widget, actionCreators }: IProps) => {
  const [message, setMessage] = useState(null);
  const modal = useSelector(widgetModalSelector(widget));

  const [t] = useTranslation();

  useMount(() => {
    const modalMessage = get(modal, `message`);
    if (modalMessage) {
      setMessage(modalMessage);
    }
  });

  let content;
  if (!message || size(message) === 0) {
    content = <span className="text-center mb-10 display-block">{t(`logs.messageIsEmpty`)}</span>;
  } else {
    content = (
      <div>
        <p className="text-wrap-break-word">{message}</p>
      </div>
    );
  }

  return <ModalElement actionCreators={actionCreators} title="logs.message" content={content} />;
};

export default Message;
