import { useDispatch } from 'react-redux';
import { ErrorPage } from '@kassma-team/kassma-toolkit/lib';

interface IProps {
  errorCode: string;
  title: string;
  icon: string;
  text?: string;
}

const ErrorPageContainer = (props: IProps) => {
  const dispatch = useDispatch();

  return <ErrorPage {...props} dispatch={dispatch} />;
};

export default ErrorPageContainer;
