import { BaseApi } from '../common/BaseApi';
import { IDomainFormData } from '../../interfaces/widgets/domains';
import { AxiosPromise } from 'axios';

export class DomainApi extends BaseApi {
  createDomain = (data: IDomainFormData): AxiosPromise => this.makeFetch(``, { data, method: `POST` });

  loadDomains = (): AxiosPromise => this.makeFetch(``);

  loadDomain = (id: number): AxiosPromise => this.makeFetch(`{id}`, {}, { id });

  updateDomain = (id: number, data: IDomainFormData): AxiosPromise =>
    this.makeFetch(`{id}`, { data, method: `PUT` }, { id });

  deleteDomain = (id: number): AxiosPromise => this.makeFetch(`{id}`, { method: `DELETE` }, { id });
}

const domainApi = new DomainApi(`domain`);

export default domainApi;
