import { BaseApi } from '../common/BaseApi';
import { AxiosPromise } from 'axios';
import { ILogsQueryParams } from '../../interfaces/logs';

export class LogsApi extends BaseApi {
  loadLogList = (queryParams: ILogsQueryParams): AxiosPromise<{ data: [] }> => this.makeFetch(``, { queryParams });
  loadLogsCategoryList = (): AxiosPromise => this.makeFetch(`categories`);
}

const logsApi = new LogsApi(`wallet-log`);

export default logsApi;
