import React, { FC, useEffect, useMemo, useState } from 'react';

import Widget from 'components/widgets/Widget';
import { IFilterProps } from '../../../interfaces/widgets/widgets';
import { ModalType, Permission, WidgetType } from '../../../utils/enums';
import { logsActionCreators } from '../../../actions/widgets/logs';
import { PaymentSystemsCtx, usePaymentSystemsCtx } from '../DefaultTranslates/ctx/usePaymentSystemsCtx';
import { getFormValues, reduxForm } from 'redux-form';
import { getSubmitHandler, InputType } from '@kassma-team/kassma-toolkit';
import withPermissionCheck from '../../../hocs/withPermissionCheck';
import { useDispatch, useSelector } from 'react-redux';
import { hasAccessSelector } from '../../../selectors/auth';
import { useTranslation } from 'react-i18next';
import Filters from '../../../components/widgets/filters/Filters';
import walletApi from '../../../api/wallet/walletApi';
import logsApi from '../../../api/logs/LogsApi';
import { concat, isNull, toString } from 'lodash';
import map from 'lodash/map';
import LogRow from './components/LogRows';
import useLogsTableTitles from '../../../hooks/widgets/useLogsTableTitles';
import LogMessage from './components/LogMessage';
import 'styles/components/widgets/logs/log.scss';
import { change } from 'redux-form';

interface ISelectDataWithNull {
  value: string | number | null;
  text: string;
}

interface ICategory extends ISelectDataWithNull {
  value: string;
}

interface ILogFilter {
  category: string[];
}

const FORM_NAME = `log-filters`;

const FilterForm = reduxForm<any, IFilterProps>({
  form: FORM_NAME,
  onSubmit: getSubmitHandler({
    dateFields: [{ name: `date_range`, fromName: `date_from`, toName: `date_to` }],
  }),
})(Filters);

const filterDateFields = [
  {
    from: `date_from`,
    to: `date_to`,
    name: `date_range`,
  },
];

const parseWalletList = (
  list: any[] | null,
  { displayId = false, useIdentifierAsValue = false, showNullValue = true, emptyText = ``, withStatus = false } = {}
): ISelectDataWithNull[] | null => {
  if (isNull(list)) {
    return null;
  }

  const nullValue: ISelectDataWithNull[] = [{ value: null, text: emptyText }];

  return concat(
    showNullValue ? nullValue : [],
    map(list, ({ id, identifier, status }) => ({
      value: useIdentifierAsValue ? identifier : toString(id),
      text: displayId ? `${id} | ${identifier}` : identifier,
      ...(withStatus ? { status } : {}),
    }))
  );
};

const listMapping = (item: ILogRowProps, key: number) => <LogRow key={key} {...item} />;

const modals = [
  {
    type: ModalType.LOG_MESSAGE,
    widget: WidgetType.LOGS,
    component: LogMessage,
  },
];

const Logs: FC = () => {
  const paymentSystemAPI = usePaymentSystemsCtx();
  const [t] = useTranslation();
  const [walletsList, setWallitsList] = useState<[] | null>(null);
  const [categoryOptions, setCategoryOptions] = useState<ICategory[]>([]);
  const tableTitles = useLogsTableTitles();
  const dispatch = useDispatch();

  const logFilter = useSelector(getFormValues(FORM_NAME)) as ILogFilter | undefined;

  useEffect(() => {
    if (categoryOptions?.length && logFilter?.category?.length) {
      const filteredCategoryOptions = categoryOptions.filter((category) =>
        logFilter.category.includes(category?.value)
      );
      dispatch(change(FORM_NAME, `category`, filteredCategoryOptions));
    }
  }, [categoryOptions?.length]);

  const showWalletFilter = useSelector(hasAccessSelector(Permission.WALLET_LIST));

  const safePaymentSystemList = paymentSystemAPI?.paymentSystems?.map((el) => ({
    ...el,
    value: el.code,
    text: el.name,
  }));

  useEffect(() => {
    (async function () {
      const resp = await walletApi.loadWalletList({});
      setWallitsList(resp.data.data);
    })();
  }, []);

  useEffect(() => {
    (async function () {
      const resp = await logsApi.loadLogsCategoryList();
      setCategoryOptions(resp.data.data);
    })();
  }, []);

  const leftFilterFields = useMemo(
    () => [
      {
        name: `wallet_type`,
        type: `select`,
        data: [{ value: null, text: t(`common.all`) }, ...safePaymentSystemList],
        placeholder: t(`logs.walletType`),
      },
      ...[
        showWalletFilter
          ? {
              name: `wallet_id`,
              type: InputType.SELECT,
              data: parseWalletList(walletsList, {
                emptyText: t(`common.all`),
              }),
              placeholder: t(`common.wallet`),
              busy: !walletsList,
            }
          : undefined,
      ],
      {
        name: `category`,
        type: InputType.MULTI_SELECT,
        translateKeys: true,
        data: [{ value: null, text: t(`common.all`) }, ...(categoryOptions || [])],
        placeholder: t(`logs.category`),
        busy: !categoryOptions,
      },
      {
        name: `date_range`,
        type: InputType.DATE_RANGE,
      },
    ],
    [paymentSystemAPI]
  );

  return (
    <Widget
      headTitle="logs.logs"
      widget={WidgetType.LOGS}
      actionCreators={logsActionCreators}
      pageTitle="logs.walletLogs"
      tableTitle="logs.logList"
      tableHeadTitles={tableTitles}
      listMapping={listMapping}
      filterFormName={FORM_NAME}
      filterForm={<FilterForm leftFields={leftFilterFields} formName={FORM_NAME} dateFields={filterDateFields} />}
      modals={modals}
      withSuperAdminPagination
      statusBar
    />
  );
};

export default withPermissionCheck(
  () => (
    <PaymentSystemsCtx isPaymentSystemsListSorted={false}>
      <Logs />
    </PaymentSystemsCtx>
  ),
  [
    {
      permissions: [
        {
          permission: Permission.ADMIN_LOGS_LIST,
        },
      ],
      redirectUrl: `/no-permissions`,
    },
  ]
);

export interface ILogRowProps {
  desktopSize: boolean;
  error_type: string;
  message: string;
  created_at: string;
  wallet_uuid: string;
  wallet_type_code: string;
  wallet_identifier: string;
  hiddenColumns?: string[];
  category: string;
}
