export enum FieldType {
  INPUT,
  CHECKBOX_GROUP,
  FIELD_ARRAY = 2,
  SELECT,
}

export enum WidgetType {
  WALLETS = `wallets`,
  LOGS = `logs`,
  COUNTERPARTIES = `counterparties`,
  PAYMENT_SYSTEMS = `payment-systems`,
  FILTER_LIST_PAYMENT_SYSTEMS = `filter-list-payment-systems`,
  FILTER_COUNTERPARTY_LIST = `filter-list-counterparties`,
  CURRENCIES = `currencies`,
  INTRANET_CURRENCIES = `intranet-currencies`,
  SETTINGS = `settings`,
  DOMAINS = `domains`,
  SERVERS = `servers`,
  DELETED_SERVERS = `deleted-servers`,
  TIMEZONES = `timezones`,
  HIDDEN_SETTINGS = `hidden-settings`,
  HIDDEN_FIELDS = `hidden-fields`,
}

export enum ModalType {
  SHOW = 1,
  DELETE,
  CREATE,
  UPDATE,
  PERMISSION_LIST,
  ROLE_LIST,
  USER_LIST,
  PAYMENT_SYSTEM_LIST,
  STATUS_LIST,
  RATE_UPDATE,
  LOG_MESSAGE,
}

export enum Permission {
  COUNTERPARTY_LIST = `sa.counterparty.list`,
  COUNTERPARTY_MANAGE = `sa.counterparty.manage`,
  PAYMENT_SYSTEM_LIST = `sa.payment-system.list`,
  COUNTERPARTY_BALANCE = `sa.counterparty.balance`,
  VIEW_STATISTICS = `sa.statistics.view`,
  TIMEZONE_SET = `sa.settings.set-timezone`,
  HIDDEN_SETTINGS_UPDATE = `sa.hidden.settings.update`,
  CURRENCIES_MANAGE = `sa.currencies.manage`,
  WALLET_LIST = `admin.wallet.list`,
  ADMIN_LOGS_LIST = `admin.logs.list`,
}

export enum CounterpartyStatus {
  ACTIVE = `active`,
  DISABLED = `disabled`,
  DEPLOY = `deploy`,
  WAITED = `waited`,
}

export enum PaymentSystemStatus {
  ACTIVE = `true`,
  DISABLED = `false`,
}

export enum DomainStatus {
  FREE = `free`,
  ENGAGED = `engaged`,
  PROCESSING = `processing`,
}

export enum ServerStatus {
  FREE = `free`,
  ENGAGED = `engaged`,
  NOT_AVAILABLE = `not_available`,
  DELETED = `deleted`,
}

export enum RoleCounterpartyStatus {
  SUCCESS = `success`,
  FAIL = `fail`,
  WAIT = `wait`,
}

export enum RoleStatus {
  FINISH = `finish`,
  WAIT = `wait`,
}

export enum ResponseStatus {
  SUCCESS = `success`,
  ERROR = `error`,
}

export enum FieldCast {
  STRING = `1`,
  INTEGER = `2`,
  BOOLEAN = `3`,
  JSON = `4`,
  ARRAY = `5`,
}

export const LogsColumn = {
  WALLET_TYPE: `walletType`,
  IDENTIFIER: `identifier`,
  ERROR_TYPE: `errorType`,
  MESSAGE: `message`,
  DATE: `date`,
  CATEGORY: `category`,
};
