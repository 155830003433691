import { takeEvery, put } from 'redux-saga/effects';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { IAction } from '@kassma-team/kassma-toolkit/lib';
import { AxiosResponse } from 'axios';

import i18n from '../i18n';
import refreshSaga from 'sagas/effects/refreshSaga';

import {
  STATISTICS_DATA_FAILED,
  STATISTICS_DATA_REQUESTED,
  STATISTICS_DATA_SUCCEEDED,
  STATISTICS_FILTERS_REQUESTED,
  STATISTICS_FILTERS_SUCCEEDED,
} from 'actionTypes';
import { setStatisticsData } from 'actions/statistics';
import getInitialData from 'utils/statistics/getInitialData';
import { IStatisticsFiltersRequestBody, IStatisticsFiltersResponse } from 'interfaces/statistics';
import statisticsApi from '../api/statistics/StatisticsApi';

function* getStatistics({ payload }: IAction) {
  yield put(showLoading());

  const startDate = get(payload, `date.values.startDate`) || get(payload, `date.from`);
  const endDate = get(payload, `date.values.endDate`) || get(payload, `date.to`);

  const filters: IStatisticsFiltersRequestBody | any = {
    ...omit(payload, `date`),
    date: {
      from: moment(startDate).format(`YYYY-MM-DD HH:mm`),
      to: moment(endDate).format(`YYYY-MM-DD HH:mm`),
    },
  };

  yield refreshSaga({
    request: () => statisticsApi.getStatisticsData(filters),
    onSuccess: function* (resp: AxiosResponse) {
      yield put({ type: STATISTICS_DATA_SUCCEEDED, payload: resp?.data?.data });
    },
    onError: function* () {
      yield toastr.error(i18n.t(`common.error`), i18n.t(`statistics.fetchDataError`));
      yield put({ type: STATISTICS_DATA_FAILED });
    },
  });

  yield put(hideLoading());
}

function* getStatisticsFilters() {
  yield refreshSaga({
    request: () => statisticsApi.loadFilters(),
    onSuccess: function* (resp: AxiosResponse<IStatisticsFiltersResponse>) {
      const filters = resp?.data?.data;

      yield put({ type: STATISTICS_FILTERS_SUCCEEDED, payload: filters });
      yield put(setStatisticsData(getInitialData(filters as any)));
    },
    onError: function* () {
      yield toastr.error(i18n.t(`common.error`), i18n.t(`statistics.fetchDataError`));
    },
  });
}

const statisticsSagas = [
  takeEvery<IAction>(STATISTICS_DATA_REQUESTED, getStatistics),
  takeEvery<IAction>(STATISTICS_FILTERS_REQUESTED, getStatisticsFilters),
];

export default statisticsSagas;
