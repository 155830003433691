import React, { FC } from 'react';
import './FormTypes.scss';
import {
  useCurrentFormTypeCtx,
  useFormTypeCtx,
  useModifiedHintsCtx,
  useSaveModalCtx,
  useCurrentPaymentSystemCtx,
} from '../../ctx';
import { useTranslation } from 'react-i18next';
import { FormType } from 'api/translations/TranslationsApi';
import { Hint } from 'api/translations/TranslationsApi';
import cn from 'classnames';

export const FormTypes: FC = () => {
  const { handleToggleModal } = useSaveModalCtx();
  const { currentFormType, setCurrentFormType } = useCurrentFormTypeCtx();
  const { currentPaymentSystem } = useCurrentPaymentSystemCtx();
  const { formTypes } = useFormTypeCtx();
  const [t] = useTranslation();

  const { modifiedHints } = useModifiedHintsCtx();

  const handleCheckFormType = (formType: Hint[`form_type`]) => () => {
    if (modifiedHints.length) {
      handleToggleModal(true)();

      return;
    }

    const findedFormType: FormType | undefined = formTypes.find(({ type }) => type === formType);

    if (findedFormType) {
      setCurrentFormType(findedFormType);
    }
  };

  return (
    <section className="FormTypes">
      <div className="FormTypes__header">
        <h2 className="FormTypes__title">{t(`paymentSystems.form`)}</h2>
      </div>

      <ul className="FormTypes__list">
        {formTypes.map((el, i) => {
          // Для апи платежек недоступна форма подтверждения оплаты
          if (currentPaymentSystem?.payment_type === `api` && el.type === `payment`) {
            return;
          }

          return (
            <li
              key={`${el.type}-${i}`}
              className={cn(`FormTypes__item`, {
                'FormTypes__item--active': el.type === currentFormType?.type,
              })}
            >
              <button onClick={handleCheckFormType(el.type)} type="button">
                {el.title}
              </button>
            </li>
          );
        })}
      </ul>
    </section>
  );
};
