import { counterpartiesActionCreators } from '../../../actions/widgets/counterparties';
import ConfirmationModal from '../../modals/ConfirmationModal';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { IWidgetModal, widgetModalSelector } from '@kassma-team/kassma-toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { ICounterpartyUsersModalPayload } from '../../../interfaces/widgets/counterparties';
import { WidgetType } from '../../../utils/enums';
import counterpartyApi from '../../../api/counterparties/CounterpartyApi';

const CounterpartyConfirm: FC = () => {
  const modal: IWidgetModal = useSelector(
    widgetModalSelector<ICounterpartyUsersModalPayload>(WidgetType.COUNTERPARTIES)
  );

  const dispatch = useDispatch();
  const [t] = useTranslation();

  const id = modal?.id;

  const handleConfirmClick = () => {
    counterpartyApi.approveCounterparty(id);
    dispatch(counterpartiesActionCreators.hideModal());
  };

  return (
    <ConfirmationModal
      title={t(`counterparties.counterpartyConfirmation`)}
      actionCreators={counterpartiesActionCreators}
      content={t(`counterparties.areYouSureWantToConfirmCounterparty`)}
      onConfirm={handleConfirmClick}
      confirmClassName={`btn-alt-primary`}
      confirmText={t(`counterparties.confirm`)}
    />
  );
};

export default CounterpartyConfirm;
