import map from 'lodash/map';
import { useTranslation } from 'react-i18next';
import { InjectedFormProps } from 'redux-form';
import { FormSubmissionButton, IFormField } from '@kassma-team/kassma-toolkit/lib';

import FormField from 'components/form/FormField';

interface IProps extends InjectedFormProps {
  fields: IFormField[];
  submitText: string;
}

const RegularForm = ({ fields, submitText, submitting, handleSubmit }: IProps) => {
  const [t] = useTranslation();

  return (
    <form onSubmit={handleSubmit} className="col-xl-6 col-md-7">
      {map(fields, (field, key) => (
        <FormField {...field} key={key} />
      ))}
      <FormSubmissionButton submitting={submitting} disabled={submitting}>
        {t(submitText)}
      </FormSubmissionButton>
    </form>
  );
};

export default RegularForm;
