import { BaseApi } from '../common/BaseApi';
import { AxiosPromise } from 'axios';
import { ILoginFormData } from '@kassma-team/kassma-toolkit';

export class AuthApi extends BaseApi {
  checkAuth = (): AxiosPromise => this.makeFetch(`me`);

  login = (data: ILoginFormData): AxiosPromise =>
    this.makeFetch(`login`, { data, withToken: false, method: `POST`, needRefresh: false });

  logout = (refresh_token: string): AxiosPromise =>
    this.makeFetch(`logout`, { method: `POST`, data: { refresh_token } });

  refreshToken = (refresh_token: string): AxiosPromise =>
    this.makeFetch(`refresh`, { method: `POST`, data: { refresh_token }, withToken: false });
}

const authApi = new AuthApi(`auth`);

export default authApi;
