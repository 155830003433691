import React, { FC } from 'react';

import Widget from 'components/widgets/Widget';
import { paymentSystemActionCreators } from 'actions/widgets/paymentSystems';
import { DefaultTranslatesScreen } from './screens/DefaultTranslatesScreen';
import { DefaultTranslates } from './ctx';

import './DefaultTranslates.scss';

export const DefaultSettings: FC = () => {
  return (
    <Widget
      headTitle="paymentSystems.defaultSettings"
      routeTabs={() => []}
      pageTitle="paymentSystems.defaultSettings"
      tableTitle="paymentSystems.defaultSettings"
      actionCreators={paymentSystemActionCreators}
      controlPanel={
        <div className="DefaultTranslates">
          <DefaultTranslates>
            <DefaultTranslatesScreen />
          </DefaultTranslates>
        </div>
      }
    />
  );
};
