import { ReactElement } from 'react';
import RemovingModal from '../../modals/RemovingModal';
import { WidgetType } from '../../../utils/enums';
import { hiddenFieldsActionCreators } from '../../../actions/widgets/settings';
import { useSelector } from 'react-redux';
import { widgetModalSelector } from '@kassma-team/kassma-toolkit';
import { useTranslation } from 'react-i18next';

const FieldDeleting = (): ReactElement => {
  const modal = useSelector(widgetModalSelector(WidgetType.HIDDEN_FIELDS));

  const [t] = useTranslation();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const name = modal?.name;

  return (
    <RemovingModal
      actionCreators={hiddenFieldsActionCreators}
      widget={WidgetType.HIDDEN_FIELDS}
      content={t(`hiddenFields.areYouSureYouWantToDeleteField`, { name })}
      title="hiddenFields.fieldDeleting"
    />
  );
};

export default FieldDeleting;
