import React, { FC, useState, ReactNode } from 'react';
import { Editor, EditorState, RichUtils } from 'draft-js';
import './TranslateField.scss';
import 'draft-js/dist/Draft.css';

type Props = {
  text: EditorState;
  onChangeText: (text: EditorState) => void;

  error?: ReactNode;
};

export const TranslateField: FC<Props> = ({ text, error, onChangeText }) => {
  const [editorState, setEditorState] = useState<EditorState>();

  const handleKeyCommand = (command: string, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      setEditorState(newState);
      onChangeText(newState);

      return `handled`;
    }

    return `not-handled`;
  };

  const handle = (state: EditorState) => {
    onChangeText(state);
    setEditorState(state);
  };

  return (
    <div className="TranslateField">
      <Editor editorState={editorState ? editorState : text} handleKeyCommand={handleKeyCommand} onChange={handle} />
      {error && error}
    </div>
  );
};
