import { FC, useContext, createContext } from 'react';
import { useFormWasSubmitted, UseFormWasSubmitted } from '../hooks';

type UseFormCtx = UseFormWasSubmitted;

const ctx = createContext<UseFormCtx | undefined>(undefined);

export const FormWasSubmittedCtx: FC = ({ children }) => {
  const { isFormWasSubmitted, setFormSubmitted } = useFormWasSubmitted();

  const value: UseFormCtx = {
    isFormWasSubmitted,
    setFormSubmitted,
  };

  return <ctx.Provider value={value}>{children}</ctx.Provider>;
};

export function useFormWasSubmittedCtx(): UseFormCtx {
  const context = useContext(ctx);

  if (context === undefined) {
    throw new Error(`Is need to use useFormWasSubmittedCtx inside a FormWasSubmittedCtx component`);
  }

  return context;
}
