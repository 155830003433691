import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { put, select, takeEvery } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import { ToastrEmitter } from 'react-redux-toastr';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import size from 'lodash/size';
import filter from 'lodash/filter';
import every from 'lodash/every';
import { ForkEffect } from '@redux-saga/core/effects';
import {
  authCheckRequest,
  IHideColumnsData,
  IHideColumnsPayload,
  IHideColumnsRequestBody,
  ILoginFormData,
  ILoginRespData,
  LOGIN_REQUESTED,
  LOGIN_SUCCEEDED,
  LOGOUT_REQUESTED,
  LOGOUT_SUCCEEDED,
  UPDATE_HIDDEN_COLUMN_REQUESTED,
  updateHiddenColumnSucceeded,
} from '@kassma-team/kassma-toolkit';
import {
  AUTH_CHECK_SUCCEEDED,
  AUTH_ERROR,
  getErrorMessage,
  getRefreshSaga,
  i18n,
  IAction,
  LOGIN_FORM_NAME,
} from '@kassma-team/kassma-toolkit/lib';
import { IConfigState } from '../../interfaces/common';
import { INIT_APP_REQUESTED, INIT_APP_SUCCEEDED } from '@kassma-team/kassma-toolkit/lib/actionTypes';
import { IResponse } from '@kassma-team/kassma-toolkit/src/types/common';
import { ResponseStatus } from '../../utils/enums';
import { formatErrors } from '@kassma-team/kassma-toolkit/lib/utils';
import { refreshTokenSelector } from '../../selectors/auth';

type IRequiredKey = `apiUrl` | `pluginUrl` | `apiSubdomain` | `apiProtocol` | `useTheSameDomainNameForApi`;

export interface IAuthSagasParams {
  fetchDataOnLogin: () => Generator;
  login: (data: ILoginFormData) => AxiosPromise;
  refreshToken: (refresh_token: string) => AxiosPromise;
  logout: (refresh_token: string) => AxiosPromise;
  hideColumn: (data: IHideColumnsRequestBody) => AxiosPromise;
  toastr: ToastrEmitter;
  requiredKeys: IRequiredKey[];
}

const getAuthSagas = ({
  fetchDataOnLogin,
  login,
  refreshToken,
  logout,
  hideColumn,
  toastr,
  requiredKeys,
}: IAuthSagasParams): ForkEffect[] => {
  const refreshSaga = getRefreshSaga({ refreshToken, toastr });

  function* onLoggedIn() {
    yield fetchDataOnLogin();
  }

  function checkRequiredFields(config?: IConfigState) {
    if (!requiredKeys || !config) {
      return false;
    }

    return every(requiredKeys, (key) => config[key] !== undefined);
  }

  async function getConfig() {
    try {
      const response = await axios(`/config.json`);

      return response.data;
    } catch (e) {
      throw new Error(`Invalid response`);
    }
  }

  function* initApp(prop: IAction<IConfigState>) {
    let config = prop.payload;
    let isRequiredFieldsExists = checkRequiredFields(config);

    if (!isRequiredFieldsExists) {
      config = yield getConfig();
      isRequiredFieldsExists = checkRequiredFields(config);
    }

    if (isRequiredFieldsExists) {
      yield put({ type: INIT_APP_SUCCEEDED, payload: config });
      yield put(authCheckRequest());
    } else {
      yield toastr.error(i18n.t(`auth.configError`), i18n.t(`auth.noRequiredParameters`), {
        timeOut: 99999,
        showCloseButton: true,
        progressBar: false,
      });
    }
  }

  function* loginSaga({ payload }: IAction<ILoginFormData>) {
    try {
      if (!payload) {
        throw new Error(`Invalid payload`);
      }
      const resp: AxiosResponse<IResponse<ILoginRespData>> = yield login(payload);
      const {
        data: { data, status, error_message, errors },
      } = resp;
      if (status === ResponseStatus.SUCCESS) {
        if (!data || !data.token) {
          throw new Error(`Invalid response`);
        }
        const { token, refresh_token } = data;
        yield localStorage.setItem(`token`, token);
        yield localStorage.setItem(`refresh_token`, refresh_token);
        yield put({ type: LOGIN_SUCCEEDED, payload: { token, refresh_token } });
        yield put(authCheckRequest());
        yield onLoggedIn();
      } else if (status === `ok`) {
        const {
          data: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            token,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            user,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            refresh_token,
          },
        } = resp;
        yield localStorage.setItem(`token`, token);
        yield localStorage.setItem(`refresh_token`, refresh_token);
        yield put({ type: AUTH_CHECK_SUCCEEDED, payload: { user, password: payload.password, refresh_token } });
        yield put({ type: LOGIN_SUCCEEDED, payload: { token, refresh_token } });
        yield put(authCheckRequest());
        yield onLoggedIn();
      } else {
        const formattedErrors = formatErrors(errors);
        const errorMessage = error_message || `Login failed`;
        yield put(stopSubmit(LOGIN_FORM_NAME, { _error: errorMessage, ...formattedErrors }));
        yield put({ type: AUTH_ERROR, payload: errorMessage });
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errorMessage = getErrorMessage(e) || `Login failed`;
        yield put(stopSubmit(LOGIN_FORM_NAME, { _error: errorMessage }));
        yield put({ type: AUTH_ERROR, payload: errorMessage });
      }
    }
  }

  function* logoutSaga() {
    const refresh_token = yield select(refreshTokenSelector);
    yield refreshSaga({ request: () => logout(refresh_token) });
    yield localStorage.removeItem(`token`);
    yield localStorage.removeItem(`refresh_token`);
    yield put({ type: LOGOUT_SUCCEEDED });
  }

  function* updateHiddenColumnSaga({ payload }: IAction<IHideColumnsPayload>) {
    if (!hideColumn) {
      return;
    }

    if (!payload || !payload?.name) {
      throw new Error(`payload.name is a required field`);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const currentHiddenColumns = yield select(hiddenColumnsSelector(payload?.name));
    const newHiddenColumns = payload?.columns;

    let data: IHideColumnsData[] = [];
    if (size(newHiddenColumns) > size(currentHiddenColumns)) {
      const added = filter(newHiddenColumns, (item) => !currentHiddenColumns.includes(item));
      if (!isEmpty(added)) {
        data = map(added, (item) => ({
          name: payload.name,
          column: item,
          show: false,
        }));
      }
    } else {
      const removed = filter(currentHiddenColumns, (item) => !newHiddenColumns.includes(item));
      if (!isEmpty(removed)) {
        data = map(removed, (item) => ({
          name: payload.name,
          column: item,
          show: true,
        }));
      }
    }

    yield put(updateHiddenColumnSucceeded(payload));
    if (!isEmpty(data)) {
      yield refreshSaga({
        request: () => hideColumn({ data }),
        onSuccess: function () {
          toastr.success(i18n.t(`common.success`), i18n.t(`settings.columnUpdated`));
        },
        onError: function () {
          toastr.error(i18n.t(`common.error`), i18n.t(`settings.columnUpdatingFailed`));
        },
      });
    }
  }

  return [
    takeEvery(LOGIN_REQUESTED, loginSaga),
    takeEvery(LOGOUT_REQUESTED, logoutSaga),
    takeEvery(UPDATE_HIDDEN_COLUMN_REQUESTED, updateHiddenColumnSaga),
    takeEvery(INIT_APP_REQUESTED, initApp),
  ];
};

export default getAuthSagas;
