import { useCallback, useEffect, useMemo } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import map from 'lodash/map';
import keys from 'lodash/keys';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import {
  ErrorBlock,
  FormSubmissionButton,
  useDesktopSize,
  InputType,
  widgetSelector,
  IWidgetProps,
} from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { IWidgetHeadTitles } from 'interfaces/widgets/widgets';
import { hiddenSettingsActionCreators } from 'actions/widgets/settings';
import useFormSubmissionHandler from 'hooks/widgets/useFormSubmissionHandler';
import { HIDDEN_SETTINGS_LIST_FORM_NAME } from 'utils/constants';

import FormField from 'components/form/FormField';

interface IProps extends InjectedFormProps {
  fields?: Record<string, string>[];
}

const headTitles: IWidgetHeadTitles[] = [
  {
    title: `settings.title`,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `settings.value`,
    desktop: true,
    mobile: true,
    disabled: true,
    className: `mr-10`,
  },
];

const HiddenSettingsList = ({ initialize, error, change }: IProps) => {
  const [t] = useTranslation();
  const desktopSize = useDesktopSize();

  const widget: IWidgetProps = useSelector(widgetSelector(WidgetType.HIDDEN_SETTINGS));
  const items: Record<string, string>[] = widget?.items;
  const id: string = widget?.item;
  const formError: string = widget?.modalError || error;

  const getFieldType = useCallback((type: string) => {
    switch (type) {
      case `boolean`:
        return InputType.CHECKBOX;
      case `json`:
        return InputType.TEXTAREA;
      default:
        return InputType.TEXT;
    }
  }, []);

  const parsedInitialData = useMemo(
    () =>
      items?.reduce<Record<string, string | boolean>>(
        (result, value) => ({ ...result, [value?.field]: value?.value }),
        {}
      ),
    [items]
  );

  useEffect(() => {
    initialize(parsedInitialData);
  }, [parsedInitialData]);

  const onSubmit = useCallback(
    (values: Record<string, string | boolean>, dispatch: Dispatch) => {
      const valueTransformer = (cast: any, value: any) => {
        if (cast === `boolean`) {
          return value ? `1` : `0`;
        } else if (cast === `json`) {
          return JSON.parse(value);
        }

        return value;
      };

      const data = [];
      for (const key of Object.keys(values)) {
        const item = items.find((item) => item?.field === key) || {};
        data.push({
          ...item,
          value: valueTransformer(item.value_cast, values[key]),
        });
      }

      dispatch(hiddenSettingsActionCreators.updateItem({ id, data }));
    },
    [items, id]
  );

  const handleSubmit = useFormSubmissionHandler({ form: HIDDEN_SETTINGS_LIST_FORM_NAME, onSubmit });

  const notStaticSettings = (items || []).filter((setting) => !setting?.is_static);

  useEffect(() => {
    notStaticSettings.forEach((field) => {
      if (field?.value_cast === `json`) {
        change(field?.field, JSON.stringify(field?.value));
      } else {
        change(field?.field, field.value);
      }
    });
  }, [items]);

  return !isEmpty(notStaticSettings) ? (
    <div className="p-20 w-100">
      <form onSubmit={handleSubmit}>
        <table className="w-75">
          {desktopSize && (
            <thead>
              <tr>
                {map(headTitles, ({ title }, key) => {
                  return <th key={key}>{t(title)}</th>;
                })}
              </tr>
            </thead>
          )}
          <tbody>
            {map(notStaticSettings, (field, i) => {
              return (
                <tr key={`${field?.name}-${i}`}>
                  <td className="font-weight-bold">{field?.field}</td>
                  <td className="mt-15">
                    <FormField
                      {...field}
                      name={field?.field}
                      value={field?.value_cast === `json` ? JSON.stringify(field?.value) : field?.value}
                      type={getFieldType(field?.value_cast as string)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {!!formError && <ErrorBlock error={`${t(`common.error`)} ${formError}`} />}
        <div className="mt-15">
          <FormSubmissionButton submitting={false} disabled={false}>
            {t(`common.save`)}
          </FormSubmissionButton>
        </div>
      </form>
    </div>
  ) : (
    <div className="p-20">{t(`widgets.listIsEmpty`)}</div>
  );
};

export default reduxForm({ form: HIDDEN_SETTINGS_LIST_FORM_NAME })(HiddenSettingsList);
