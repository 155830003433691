import { ICreateApiFetchOptions } from 'api/createApiFetch';
import apiFetch from 'api/apiFetch';
import { AxiosPromise } from 'axios';

export class BaseApi {
  private BASE_URL = ``;

  protected readonly makeFetch = <T = unknown>(
    path: string,
    options?: ICreateApiFetchOptions,
    pathOptions?: Record<string, string | number>
  ): AxiosPromise<T> => apiFetch(this.BASE_URL, path, pathOptions, options);

  constructor(baseUrl: string) {
    this.BASE_URL = baseUrl;
  }
}
