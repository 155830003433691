import { useTranslation } from 'react-i18next';
import { ISettingsBlockWrapProps } from 'interfaces/widgets/settings';

const SettingsBlockWrap = ({ title, children }: ISettingsBlockWrapProps) => {
  const [t] = useTranslation();

  return (
    <div className="block-content">
      <h3 className="content-heading pt-0">{t(title)}</h3>
      <div className="row">{children}</div>
    </div>
  );
};

export default SettingsBlockWrap;
