import { BaseApi } from '../common/BaseApi';
import { AxiosPromise } from 'axios';
import { IHiddenFieldForm } from 'interfaces/widgets/hiddenFields';

export class HiddenFieldsApi extends BaseApi {
  loadFields = (): AxiosPromise => this.makeFetch(``);
  createFields = (data: IHiddenFieldForm): AxiosPromise => this.makeFetch(``, { method: `POST`, data });
  deleteField = (id: string): AxiosPromise => this.makeFetch(`{id}`, { method: `DELETE` }, { id });
}

const hiddenFieldsApi = new HiddenFieldsApi(`hidden-settings/settings/fields`);

export default hiddenFieldsApi;
