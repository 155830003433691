// Counterparties

export const REFRESH_COUNTERPARTY_REQUESTED = `REFRESH_COUNTERPARTY_REQUESTED`;
export const END_TO_END_AUTHORIZED = `END_TO_END_AUTHORIZED`;

export const COUNTERPARTY_CREATING_DATA_REQUESTED = `COUNTERPARTY_CREATING_DATA_REQUESTED`;
export const COUNTERPARTY_CREATING_DATA_SUCCEEDED = `COUNTERPARTY_CREATING_DATA_SUCCEEDED`;
export const COUNTERPARTY_CREATING_DATA_FAILED = `COUNTERPARTY_CREATING_DATA_FAILED`;
export const RESET_COUNTERPARTY_CREATING_DATA = `RESET_COUNTERPARTY_CREATING_DATA`;

// Statistics

export const STATISTICS_DATA_REQUESTED = `STATISTICS_DATA_REQUESTED`;
export const STATISTICS_DATA_SUCCEEDED = `STATISTICS_DATA_SUCCEEDED`;
export const STATISTICS_DATA_FAILED = `STATISTICS_DATA_FAILED`;

export const STATISTICS_FILTERS_REQUESTED = `STATISTICS_FILTERS_REQUESTED`;
export const STATISTICS_FILTERS_SUCCEEDED = `STATISTICS_FILTERS_SUCCEEDED`;

// Roles

export const ROLE_REMOVE_REQUESTED = `ROLE_REMOVE_REQUESTED`;
export const ROLE_REMOVE_SUCCEEDED = `ROLE_REMOVE_SUCCEEDED`;

export const SHOW_ROLE_SUCCESS_MESSAGE = `SHOW_ROLE_SUCCESS_MESSAGE`;

export const CREATE_ROLE_REQUESTED = `CREATE_ROLE_REQUESTED`;
export const UPDATE_ROLE_REQUESTED = `UPDATE_ROLE_REQUESTED`;

export const ROLE_CREATING_COUNTERPARTY_STATUS_REQUESTED = `ROLE_CREATING_COUNTERPARTY_STATUS_REQUESTED`;
export const ROLE_CREATING_COUNTERPARTY_STATUS_SUCCEEDED = `ROLE_CREATING_COUNTERPARTY_STATUS_SUCCEEDED`;

// Users

export const USER_REMOVE_REQUESTED = `USER_REMOVE_REQUESTED`;
export const USER_REMOVE_SUCCEEDED = `USER_REMOVE_SUCCEEDED`;
