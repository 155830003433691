import { BaseApi } from '../common/BaseApi';
import { AxiosPromise } from 'axios';
import { IStatisticsFiltersRequestBody, IStatisticsFiltersResponse } from '../../interfaces/statistics';

export class StatisticsApi extends BaseApi {
  getStatisticsData = (data: IStatisticsFiltersRequestBody): AxiosPromise =>
    this.makeFetch(`filter`, { method: `POST`, data });

  loadFilters = (): AxiosPromise<IStatisticsFiltersResponse> => this.makeFetch(`dashboard`);
}

const statisticsApi = new StatisticsApi(`statistics`);

export default statisticsApi;
