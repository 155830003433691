import each from 'lodash/each';
import map from 'lodash/map';
import moment from 'moment';

import { IStatisticsFilters } from 'interfaces/statistics';

const keys: any = {
  counterparties: `counterparties_ids`,
  indicators: `indicators_ids`,
  [`payment-systems`]: `payment-systems_ids`,
};

const getInitialData = (filters: IStatisticsFilters, isForm = false) => {
  const filtersInitialState = () => {
    let initialFilters = { detailing: `counterparties` };

    each(filters, (item, key) => {
      const customKey = keys[key];
      const values = key === `indicators` ? [3] : map(item, ({ value }) => value);

      initialFilters = { ...initialFilters, [customKey]: values };
    });

    return initialFilters;
  };

  const date = isForm
    ? {
        values: {
          startDate: moment().subtract(7, `days`).hour(0).minute(0).second(0).toDate(),
          endDate: moment().hour(23).minute(59).second(0).toDate(),
        },
      }
    : {
        from: moment().subtract(7, `days`).hour(0).minute(0).second(0).format(`YYYY-MM-DD HH:mm`),
        to: moment().hour(23).minute(59).second(0).format(`YYYY-MM-DD HH:mm`),
      };

  return {
    ...filtersInitialState(),
    date,
  };
};

export default getInitialData;
