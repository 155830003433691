import ConfirmationModal from '../../modals/ConfirmationModal';
import { intranetCurrencyActionCreators } from '../../../actions/widgets/currency';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import currenciesApi from '../../../api/currency/CurrenciesApi';
import { widgetListSelector } from '@kassma-team/kassma-toolkit/lib';
import { WidgetType } from '../../../utils/enums';
import { IIntranetCurrency } from '../../../interfaces/widgets/intranetCurrencies';
import useSearchParams from '../../../hooks/useSearchParams';

const CurrencyRateUpdate = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const queryParams = useSearchParams();

  const currencies: IIntranetCurrency[] = useSelector(widgetListSelector(WidgetType.INTRANET_CURRENCIES));

  const handleConfirmClick = () => {
    currenciesApi.updateCurrenciesRate(currencies?.map((c: IIntranetCurrency) => c.code)).then(() => {
      dispatch(intranetCurrencyActionCreators.getList(queryParams));
      dispatch(intranetCurrencyActionCreators.hideModal());
    });
  };

  return (
    <ConfirmationModal
      actionCreators={intranetCurrencyActionCreators}
      title={t(`currencies.startRateUpdating`)}
      content={t(`currencies.areYouSureToStartRateUpdate`)}
      confirmText={t(`common.start`)}
      onConfirm={handleConfirmClick}
    />
  );
};

export default CurrencyRateUpdate;
