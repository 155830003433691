import { IWidgetHeadTitles } from '../../interfaces/widgets/widgets';
import { IIntranetCurrencyRowProps } from '../../interfaces/widgets/intranetCurrencies';
import CurrencyRow from '../../components/widgets/currencies/CurrencyRow';
import { IModalData } from '@kassma-team/kassma-toolkit';
import withPermissionCheck from '../../hocs/withPermissionCheck';
import { ModalType, Permission, WidgetType } from '../../utils/enums';
import Widget from '../../components/widgets/Widget';
import { intranetCurrencyActionCreators } from '../../actions/widgets/currency';
import CurrencyDelete from '../../components/widgets/currencies/CurrencyDelete';
import CurrencyRateUpdate from '../../components/widgets/currencies/CurrencyRateUpdate';
import CurrencyCreating from '../../components/widgets/currencies/CurrencyCreating';
import CurrencyUpdating from '../../components/widgets/currencies/CurrencyUpdating';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { hasAccessSelector } from '../../selectors/auth';
import { useCallback } from 'react';

const headTitles: IWidgetHeadTitles[] = [
  {
    title: `currencies.code`,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `currencies.name`,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `currencies.symbol`,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `currencies.symbolPosition`,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `currencies.isCrypto`,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `currencies.rate`,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `currencies.rateUpdatedAt`,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `common.actions`,
    desktop: true,
    mobile: true,
    disabled: true,
  },
];

const listMapping = (item: IIntranetCurrencyRowProps) => <CurrencyRow {...item} key={item.code} />;

const modals: IModalData[] = [
  {
    widget: WidgetType.INTRANET_CURRENCIES,
    type: ModalType.DELETE,
    component: CurrencyDelete,
  },
  {
    widget: WidgetType.INTRANET_CURRENCIES,
    type: ModalType.RATE_UPDATE,
    component: CurrencyRateUpdate,
  },
  {
    widget: WidgetType.INTRANET_CURRENCIES,
    type: ModalType.CREATE,
    component: CurrencyCreating,
  },
  {
    widget: WidgetType.INTRANET_CURRENCIES,
    type: ModalType.UPDATE,
    component: CurrencyUpdating,
  },
];

// const FILTER_FORM_NAME = `intranet-currencies-filters`;

// const FilterForm = reduxForm<any, any>({
//   form: FILTER_FORM_NAME,
//   onSubmit: getSubmitHandler(),
// })(Filters);

const Currencies = () => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const manageAccess = useSelector(hasAccessSelector(Permission.CURRENCIES_MANAGE));

  const onCreateRoleClick = useCallback(() => {
    dispatch(intranetCurrencyActionCreators.showModal({ type: ModalType.CREATE }));
  }, [dispatch]);

  const onUpdateExchangeRatesClick = useCallback(() => {
    dispatch(intranetCurrencyActionCreators.showModal({ type: ModalType.RATE_UPDATE }));
  }, [dispatch]);

  const controlPanel = manageAccess && (
    <div>
      <button onClick={onCreateRoleClick} className="btn btn-alt-primary mr-10">
        {t(`currencies.addCurrency`)}
      </button>
      <button onClick={onUpdateExchangeRatesClick} className="btn btn-alt-secondary">
        {t(`currencies.updateExchangeRates`)}
      </button>
    </div>
  );

  return (
    <Widget
      widget={WidgetType.INTRANET_CURRENCIES}
      actionCreators={intranetCurrencyActionCreators}
      pageTitle="currencies.currencies"
      tableHeadTitles={headTitles}
      listMapping={listMapping}
      controlPanel={controlPanel}
      // filterFormName={FILTER_FORM_NAME}
      // filterForm={<FilterForm leftFields={leftFilterFields} />}
      modals={modals}
    />
  );
};

export default withPermissionCheck(Currencies, [
  {
    permissions: [
      {
        permission: Permission.CURRENCIES_MANAGE,
      },
    ],
    redirectUrl: `/no-permissions`,
  },
]);
