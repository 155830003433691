import { useEffect } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { setLang, langSelector } from '@kassma-team/kassma-toolkit/lib';

import i18n from '../../../../i18n';
import { IProfileSettingsFormData } from 'interfaces/widgets/settings';

import RegularForm from 'components/form/RegularForm';

const onSubmit = (values: IProfileSettingsFormData, dispatch: Dispatch) => {
  const { lang } = values;

  localStorage.setItem(`lang`, lang);
  i18n.changeLanguage(lang);
  dispatch(setLang(lang));
};

const ProfileSettings = (props: InjectedFormProps<Partial<IProfileSettingsFormData>>) => {
  const [t] = useTranslation();

  const lang: string = useSelector(langSelector);

  useEffect(() => {
    props.initialize({ lang });
  }, [lang]);

  const data = [
    {
      value: `ru`,
      text: t(`settings.ru`),
    },
    {
      value: `en`,
      text: t(`settings.en`),
    },
  ];

  return (
    <RegularForm
      {...props}
      fields={[
        {
          name: `lang`,
          type: `select`,
          label: t(`settings.language`),
          data: data,
          required: true,
        },
      ]}
      submitText="common.save"
    />
  );
};

export default reduxForm<any>({ form: `profile`, onSubmit })(ProfileSettings);
