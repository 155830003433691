import {
  STATISTICS_DATA_FAILED,
  STATISTICS_DATA_REQUESTED,
  STATISTICS_DATA_SUCCEEDED,
  STATISTICS_FILTERS_SUCCEEDED,
} from 'actionTypes';

export const statisticsInitState = {
  data: {},
  filters: {},
};

const statistics = (state = statisticsInitState, { type, payload = {}, meta }: any) => {
  switch (type) {
    case STATISTICS_DATA_REQUESTED:
      return { ...state, submitting: true };
    case STATISTICS_DATA_FAILED:
      return { ...state, submitting: false };
    case STATISTICS_DATA_SUCCEEDED:
      return { ...state, data: payload, submitting: false };
    case STATISTICS_FILTERS_SUCCEEDED:
      return { ...state, filters: payload };
    default:
      return state;
  }
};

export default statistics;
