import { put, takeEvery } from 'redux-saga/effects';
import refreshSaga from 'sagas/effects/refreshSaga';
import { toastr } from 'react-redux-toastr';
import { AxiosResponse, AxiosError } from 'axios';
import { WIDGET_LIST_SUCCEEDED, WIDGET_SET_ANY_DATA, IAction, getErrorMessage } from '@kassma-team/kassma-toolkit/lib';

import {
  COUNTERPARTY_CREATING_DATA_FAILED,
  COUNTERPARTY_CREATING_DATA_REQUESTED,
  COUNTERPARTY_CREATING_DATA_SUCCEEDED,
  REFRESH_COUNTERPARTY_REQUESTED,
  END_TO_END_AUTHORIZED,
} from 'actionTypes';
import i18n from '../../i18n';
import { WidgetType } from 'utils/enums';
import counterpartyApi from '../../api/counterparties/CounterpartyApi';

interface IEndToEndAuthorizedSagaMeta {
  meta: {
    url: string;
    old_front: 0 | 1;
    data: {
      counterparty_id: number;
    };
  };
}

function* refreshCounterpartiesSaga({ meta }: IAction) {
  yield put({ type: WIDGET_SET_ANY_DATA, payload: { listLoading: true }, meta });

  yield refreshSaga({
    request: counterpartyApi.refreshCounterpartiesStatuses,
    onSuccess: function* (resp: AxiosResponse) {
      yield put({ type: WIDGET_LIST_SUCCEEDED, meta, payload: resp.data });
      yield put({ type: WIDGET_SET_ANY_DATA, meta, payload: { updated_at: resp.data?.updated_at } });
      yield toastr.success(i18n.t(`common.success`), i18n.t(`counterparties.statusesHaveBeenSuccessfullyUpdated`));
    },
    onFinally: function* () {
      yield put({ type: WIDGET_SET_ANY_DATA, payload: { listLoading: false }, meta });
    },
  });
}

function* counterpartyListSucceededSata({ meta, payload }: IAction) {
  if (meta?.widget === WidgetType.COUNTERPARTIES) {
    yield put({ type: WIDGET_SET_ANY_DATA, meta, payload: { updated_at: payload?.updated_at } });
  }
}

function* counterpartiesCreatingDataSaga({ meta }: IAction) {
  yield refreshSaga({
    request: counterpartyApi.loadCounterpartyCreatingData,
    onSuccess: function* (resp: AxiosResponse) {
      if (resp?.data?.data) {
        yield put({ type: COUNTERPARTY_CREATING_DATA_SUCCEEDED, payload: resp.data.data, meta });
      }
    },
    onError: function* (err: AxiosError) {
      const errMessage = getErrorMessage(err as AxiosError);
      yield put({ type: COUNTERPARTY_CREATING_DATA_FAILED, payload: errMessage, meta });
    },
  });
}

function* endToEndAuthorizedSaga({ meta }: IEndToEndAuthorizedSagaMeta) {
  yield refreshSaga({
    request: () => counterpartyApi.getEndToEndAuthorized(meta.data),
    onSuccess: function* (resp: AxiosResponse) {
      if (resp?.data.referral_id) {
        yield window.open(
          `${meta.url}/counterparty/login?referral_id=${resp?.data.referral_id}&old_front=${meta.old_front}`,
          `_blank`
        );
      }
    },
    onError: function* () {
      yield toastr.error(i18n.t(`common.error`), i18n.t(`common.errorUpdate`));
    },
    isEndToEndAuthorization: true,
  });
}

const counterpartiesSagas = [
  takeEvery<IAction>(REFRESH_COUNTERPARTY_REQUESTED, refreshCounterpartiesSaga),
  takeEvery<IAction>(WIDGET_LIST_SUCCEEDED, counterpartyListSucceededSata),
  takeEvery<IAction>(COUNTERPARTY_CREATING_DATA_REQUESTED, counterpartiesCreatingDataSaga),
  takeEvery<IAction>(END_TO_END_AUTHORIZED, endToEndAuthorizedSaga),
];

export default counterpartiesSagas;
