import { Dispatch } from 'redux';
import includes from 'lodash/includes';
import { logoutRequest, INavListData } from '@kassma-team/kassma-toolkit/lib';

import { Permission } from 'utils/enums';

interface IListDataParams {
  dispatch: Dispatch;
  username: string | null;
  permissions: string[] | null;
}

const listData = ({ permissions, username, dispatch }: IListDataParams): INavListData[] => [
  {
    title: `counterparties.counterparties`,
    path: `/`,
    hasAccess: includes(permissions, Permission.COUNTERPARTY_LIST),
    icon: `si si-users`,
  },
  {
    title: `logs.debugging`,
    path: `/logs`,
    hasAccess: includes(permissions, Permission.ADMIN_LOGS_LIST),
    icon: `si si-direction`,
  },
  {
    title: `paymentSystems.paymentSystems`,
    path: `/default-settings`,
    hasAccess: includes(permissions, Permission.PAYMENT_SYSTEM_LIST),
    icon: `si si-wallet`,
    children: [
      {
        title: `paymentSystems.defaultSettings`,
        path: `/default-settings`,
        hasAccess: includes(permissions, Permission.PAYMENT_SYSTEM_LIST),
        icon: `si si-settings`,
      },
    ],
  },
  {
    title: `settings.settings`,
    path: `/settings`,
    hasAccess: true, // Todo: Add rights' checking
    icon: `si si-settings`,
    children: [
      {
        title: `settings.common`,
        path: `/settings`,
        hasAccess: true, // Todo: Add rights' checking
        icon: `si si-settings`,
      },
    ],
  },
  {
    title: `currencies.currencies`,
    path: `/currencies`,
    icon: `fa fa-dollar`,
    hasAccess: true,
  },
  {
    title: username as string,
    icon: `si si-user`,
    hasAccess: true,
    path: `/`,
    children: [
      {
        title: `auth.logout`,
        onClick: () => dispatch(logoutRequest()),
        icon: `si si-logout`,
        hasAccess: true,
      },
    ],
  },
];

export default listData;
