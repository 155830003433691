import { useDispatch, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import {
  ModalForm as KassmaModalForm,
  widgetSubmittingSelector,
  IModalFormProps,
} from '@kassma-team/kassma-toolkit/lib';

import FormField from 'components/form/FormField';

const ModalForm = ({ widget, form, ...props }: IModalFormProps) => {
  const values = useSelector(getFormValues(form));
  const dispatch = useDispatch();

  const submitting = useSelector(widgetSubmittingSelector(widget));

  return (
    <KassmaModalForm {...props} values={values} dispatch={dispatch} submitting={submitting} FormField={FormField} />
  );
};

ModalForm.defaultProps = {
  disabled: false,
};

export default ModalForm;
