import { widgetActionCreators } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { CURRENCY_CREATING_FORM } from '../../components/widgets/currencies/CurrencyCreating';
import { INTRANET_CURRENCY_UPDATE_FORM } from '../../components/widgets/currencies/CurrencyUpdating';
import { useDispatch } from 'react-redux';

export const currencyPayloadLabels = { listLabel: `currencies` };

export const intranetCurrencyPayloadLabels = { listLabel: `data`, itemLabel: `data` };

export const currencyActionCreators = widgetActionCreators({
  widget: WidgetType.CURRENCIES,
  labels: currencyPayloadLabels,
});

export const intranetCurrencyActionCreators = widgetActionCreators({
  widget: WidgetType.INTRANET_CURRENCIES,
  labels: intranetCurrencyPayloadLabels,
  withPagination: true,
  creatingForm: CURRENCY_CREATING_FORM,
  listItemUpdatingForm: INTRANET_CURRENCY_UPDATE_FORM,
});
