import { getFormValues, Field, FormName } from 'redux-form';
import { useSelector } from 'react-redux';

import useSearchParams from 'hooks/useSearchParams';
import { Filters as KassmaFilters, IFiltersProps } from '@kassma-team/kassma-toolkit/lib';

import FormField from 'components/form/FormField';
import { useEffect } from 'react';

const Filters = (props: IFiltersProps) => {
  const values = useSelector(getFormValues(props.formName));

  // useEffect(() => {
  //   if (props.formName === `counterparty-filters`) {
  //     props.initialize({ group: `0` });
  //   }
  // }, [props.formName]);

  const searchParams = useSearchParams();

  return (
    <KassmaFilters
      {...props}
      FormField={FormField}
      values={values}
      searchParams={searchParams}
      ReduxFormField={Field}
    />
  );
};

export default Filters;
