import { put, takeEvery } from 'redux-saga/effects';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { ToastrEmitter } from 'react-redux-toastr';
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import get from 'lodash/get';
import pick from 'lodash/pick';
import map from 'lodash/map';
import difference from 'lodash/difference';
import { ForkEffect } from '@redux-saga/core/effects';

import {
  formSubmissionError,
  i18n,
  IRefreshSagaParams,
  MODAL_FADING_TIMEOUT,
  sleep,
  WIDGET_CREATE_REQUESTED,
  WIDGET_CREATE_SUCCEEDED,
  WIDGET_ITEM_REQUESTED,
  WIDGET_ITEM_SUCCEEDED,
  WIDGET_ITEM_UPDATE_REQUESTED,
  WIDGET_ITEM_UPDATE_SUCCEEDED,
  WIDGET_LIST_ITEM_UPDATE_REQUESTED,
  WIDGET_LIST_ITEM_UPDATE_SUCCEEDED,
  WIDGET_LIST_REQUESTED,
  WIDGET_LIST_SUCCEEDED,
  WIDGET_REMOVE_REQUESTED,
  WIDGET_REMOVE_SUCCEEDED,
  WIDGET_RESET_MODAL,
  WIDGET_SET_SUBMITTING_STATUS,
} from '@kassma-team/kassma-toolkit/lib';
import { normalizeErrorMessage } from '@kassma-team/kassma-toolkit/lib/utils';
import {
  WIDGET_REMOVE_MULTIPLE_ITEMS_REQUESTED,
  WIDGET_REMOVE_MULTIPLE_ITEMS_SUCCEEDED,
  WIDGET_RESET_SELECT_LIST,
  WIDGET_SET_MODAL,
} from '@kassma-team/kassma-toolkit/lib/actionTypes';
import { IAction, IWidgetsMeta, WidgetOperations } from '../../actions/types';
import { ResponseStatus, WidgetType } from '../../utils/enums';
import { formatErrors, getErrorMessage } from '../../utils';
import refreshSaga from '../effects/refreshSaga';
import { validateForm } from '@kassma-team/kassma-toolkit';

type ApiFetchingCallback = (...params: any) => AxiosPromise | undefined;

interface IApiFetching {
  [widget: string]: ApiFetchingCallback;
}

interface IListSagaParams {
  refreshSaga: (params: IRefreshSagaParams) => Generator;
  listFetching: IApiFetching;
  toastr: ToastrEmitter;
}

type ProcessResponseProps = {
  resp: any;
  meta: IWidgetsMeta;
  err: string;
  form: string;
  handleSuccess: any;
};

function* processResponse({ resp, meta, err, form, handleSuccess }: ProcessResponseProps) {
  const supportNewFormat = meta?.supportNewFormat?.[WidgetOperations.ITEM_CREATING];

  let data = resp?.data;
  if (supportNewFormat) {
    const {
      data: { data: rawData, status, error_message, errors },
    } = resp;
    data = rawData;
    if (status !== ResponseStatus.SUCCESS) {
      const formattedErrors = formatErrors(errors);
      const errorMessage = error_message || err;
      yield formSubmissionError({
        payload: { _error: errorMessage, ...formattedErrors },
        meta,
        form,
      });
      yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
    } else {
      yield handleSuccess(data);
    }
  } else {
    yield handleSuccess(data);
  }
}

const getListLoadingSaga = ({ refreshSaga, listFetching, toastr }: IListSagaParams) =>
  function* ({ payload, meta }: IAction) {
    if (!meta?.widget) {
      throw new Error(`meta.widget is required param`);
    }

    const request: ApiFetchingCallback = listFetching[meta?.widget];
    if (!request) {
      throw new Error(`Request callback does not found in listFetching by widget ${meta?.widget}`);
    }

    if (meta?.statusBar) {
      yield put(showLoading());
    }

    if (meta.widget === WidgetType.LOGS && !Array.isArray(payload.category)) {
      payload = { ...payload, category: [payload.category] };
    }

    yield refreshSaga({
      request: () => request(payload),
      onSuccess: function* (resp: AxiosResponse) {
        if (meta?.onSuccess) meta.onSuccess(resp);
        const data = resp.data;

        if (data.error_message && meta.widget === WidgetType.LOGS) {
          toastr.error(i18n.t(`common.error`), data.error_message);
        }

        yield put({ type: WIDGET_LIST_SUCCEEDED, meta, payload: data });
      },
      onError: function (err?: AxiosError) {
        if (meta.onError) meta.onError(err as AxiosError);
        const metaOptions = get(meta, `toastrErrorOptions.${WidgetOperations.LIST_LOADING}`) || {};
        toastr.error(
          i18n.t(`common.error`),
          getErrorMessage(err, {
            normalize: true,
            defaultValue: i18n.t(`widgets.listDataFetchingHasBeenFailed`),
            ...metaOptions,
          }) as string
        );
      },
      onFinally: function* () {
        if (meta?.onFinally) meta.onFinally();
        if (meta?.statusBar) {
          yield put(hideLoading());
        }
      },
    });
  };

interface IItemSagaParams {
  refreshSaga: (params: IRefreshSagaParams) => Generator;
  itemFetching: IApiFetching;
  toastr: ToastrEmitter;
}

const getItemLoadingSaga = ({ refreshSaga, itemFetching, toastr }: IItemSagaParams) =>
  function* ({ payload, meta }: IAction) {
    if (!meta?.widget) {
      throw new Error(`meta.widget is required param`);
    }

    const request = itemFetching[meta?.widget];
    if (!request) {
      throw new Error(`Request callback does not found in itemFetching by widget ${meta?.widget}`);
    }

    yield refreshSaga({
      request: () => request(payload),
      onSuccess: function* (resp: any) {
        if (meta?.onSuccess) meta.onSuccess(resp);
        yield put({ type: WIDGET_ITEM_SUCCEEDED, meta, payload: resp.data });
      },
      onError: function (err: any) {
        if (meta.onError) meta.onError(err as AxiosError);
        toastr.error(
          i18n.t(`common.error`),
          getErrorMessage(err as AxiosError, {
            normalize: true,
            defaultValue: i18n.t(`widgets.itemFetchingHasBeenFailed`),
          }) as string
        );
      },
      onFinally: meta?.onFinally,
    });
  };

interface ICreateItemSagaParams {
  refreshSaga: (params: IRefreshSagaParams) => Generator;
  createFetching: IApiFetching;
  toastr: ToastrEmitter;
}

const getItemCreatingSaga = ({ createFetching, refreshSaga, toastr }: ICreateItemSagaParams) =>
  function* ({ meta, payload }: IAction) {
    if (!meta?.creatingForm) {
      throw new Error(`meta.creatingForm is required param`);
    }

    if (!meta?.widget) {
      throw new Error(`meta.widget is required param`);
    }

    const request = createFetching[meta.widget];
    if (!request) {
      throw new Error(`Request callback does not found in creatingRequest by widget ${meta.widget}`);
    }

    const valid = yield validateForm({ form: meta?.creatingForm, meta });
    if (!valid) {
      return;
    }

    yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: true, meta });

    yield refreshSaga({
      request: () => request(payload),
      callErrorWhenNoPermissions: true,
      onSuccess: function* (resp: any) {
        function* handleSuccess(data: any) {
          yield put({ type: WIDGET_LIST_REQUESTED, meta });
          yield put({ type: WIDGET_CREATE_SUCCEEDED, meta, payload: data });
          yield put({ type: WIDGET_RESET_MODAL, meta });
          toastr.success(
            i18n.t(`common.success`),
            i18n.t(meta?.succeededCreatingMessage || `widgets.itemHasBeenSuccessfullyCreated`)
          );

          if (meta?.onSuccess) meta?.onSuccess(resp); // TODO need refactoring

          yield sleep(MODAL_FADING_TIMEOUT);
          yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
        }

        if (!resp.data.code || (resp.data.code && [`ok`, `success`].includes(resp.data.status))) {
          yield processResponse({
            resp,
            meta,
            err: i18n.t(`widgets.itemCreatingHasBeenFailed`),
            form: meta.creatingForm as string,
            handleSuccess,
          });
        } else if (resp.data.code && resp.data.status === `error`) {
          const payload = {
            _error: resp.data.error_message,
            ...resp.data.errors,
          };

          yield formSubmissionError({ payload, meta, form: meta.creatingForm as string });
          yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
        }
      },
      onError: function* (err: any) {
        if (meta.onError) meta.onError(err as AxiosError);

        const payload = yield getErrorMessage(err as AxiosError, {
          defaultValue: i18n.t(`widgets.itemCreatingHasBeenFailed`),
        });

        if (meta.fieldAsErrorBlock) {
          payload._error = normalizeErrorMessage(pick(payload, meta.fieldAsErrorBlock), true);
        }

        yield formSubmissionError({ payload, meta, form: meta.creatingForm as string });
        yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
      },
      onFinally: meta?.onFinally,
    });
  };

interface IUpdateListItemSagaParams {
  refreshSaga: (params: IRefreshSagaParams) => Generator;
  updateListItemFetching: IApiFetching;
  toastr: ToastrEmitter;
}

const getListItemUpdatingSaga = ({ refreshSaga, updateListItemFetching, toastr }: IUpdateListItemSagaParams) =>
  function* ({ meta, payload }: IAction) {
    if (!meta?.listItemUpdatingForm) {
      throw new Error(`meta.listItemUpdatingForm is required param`);
    }

    if (!meta?.widget) {
      throw new Error(`meta.widget is required param`);
    }

    if (isEmpty(payload)) {
      throw new Error(`payload must be a not-empty object in updateListItemSaga`);
    }

    const request = updateListItemFetching[meta.widget];
    if (!request) {
      throw new Error(`Request callback does not found in updatingListItemRequest by widget ${meta.widget}`);
    }

    const valid = yield validateForm({ form: meta?.listItemUpdatingForm, meta });
    if (!valid) {
      return;
    }

    yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: true, meta });

    const { id, ...data } = payload;

    yield refreshSaga({
      request: () => request(id, data),
      callErrorWhenNoPermissions: true,
      onSuccess: function* (resp: any) {
        if (meta?.onSuccess) meta?.onSuccess(resp); // Todo need refactoring

        function* handleSuccess() {
          yield put({ type: WIDGET_LIST_REQUESTED, meta });
          yield put({ type: WIDGET_LIST_ITEM_UPDATE_SUCCEEDED, meta, payload: data });
          yield put({ type: WIDGET_RESET_MODAL, meta });

          toastr.success(
            i18n.t(`common.success`),
            i18n.t(meta?.succeededUpdatingMessage || `widgets.itemHasBeenSuccessfullyUpdated`)
          );

          yield sleep(MODAL_FADING_TIMEOUT);
          yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
        }

        if (!resp.data.code || (resp.data.code && [`ok`, `success`].includes(resp.data.status))) {
          yield processResponse({
            resp,
            meta,
            err: i18n.t(`widgets.itemUpdatingHasBeenFailed`),
            form: meta.listItemUpdatingForm as string,
            handleSuccess,
          });
        } else if (resp.data.code && resp.data.status === `error`) {
          const payload = {
            _error: resp.data.error_message,
            ...resp.data.errors,
          };

          console.log(payload);

          yield formSubmissionError({ payload, meta, form: meta.listItemUpdatingForm as string });
          yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
        }
      },
      onError: function* (err: any) {
        if (meta.onError) meta.onError(err as AxiosError);

        const payload = yield getErrorMessage(err as AxiosError, {
          defaultValue: i18n.t(`widgets.itemUpdatingHasBeenFailed`),
        });

        if (meta.fieldAsErrorBlock) {
          payload._error = normalizeErrorMessage(pick(payload, meta.fieldAsErrorBlock), true);
        }

        yield formSubmissionError({ payload, meta, form: meta.listItemUpdatingForm as string });
        yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
      },
      onFinally: meta?.onFinally,
    });
  };

interface IUpdateItemSagaParams {
  refreshSaga: (params: IRefreshSagaParams) => Generator;
  updateItemFetching: IApiFetching;
  toastr: ToastrEmitter;
}

const getItemUpdatingSaga = ({ updateItemFetching, toastr }: IUpdateItemSagaParams) =>
  function* updateItemSaga({ meta, payload }: IAction) {
    if (!meta?.itemUpdatingForm) {
      throw new Error(`meta.itemUpdatingForm is required param`);
    }

    if (!meta?.widget) {
      throw new Error(`meta.widget is required param`);
    }

    const request = updateItemFetching[meta.widget];
    if (!request) {
      throw new Error(`Request callback does not found in itemUpdatingRequest by widget ${meta.widget}`);
    }

    const valid = yield validateForm({ form: meta?.itemUpdatingForm, meta });
    if (!valid) {
      return;
    }

    yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: true, meta });

    yield refreshSaga({
      request: () => request(payload),
      onSuccess: function* (resp: any) {
        if (meta?.onSuccess) meta?.onSuccess(resp); // Todo need refactoring

        const data = resp?.data;
        yield put({ type: WIDGET_ITEM_UPDATE_SUCCEEDED, meta, payload: data });
        yield put({ type: WIDGET_RESET_MODAL, meta });

        toastr.success(
          i18n.t(`common.success`),
          i18n.t(meta?.succeededUpdatingMessage || `widgets.itemHasBeenSuccessfullyUpdated`)
        );

        yield sleep(MODAL_FADING_TIMEOUT);
        yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
      },
      onError: function* (err: any) {
        if (meta.onError) meta.onError(err as AxiosError);

        const payload: any = getErrorMessage(err as AxiosError, {
          defaultValue: i18n.t(`widgets.itemUpdatingHasBeenFailed`),
        });
        yield formSubmissionError({ payload, meta, form: meta.itemUpdatingForm as string });
        yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
      },
      onFinally: meta?.onFinally,
    });
  };
interface IDeleteItemSagaParams {
  refreshSaga: (params: IRefreshSagaParams) => Generator;
  deleteItemFetching: IApiFetching;
  toastr: ToastrEmitter;
}

const getItemDeletingSaga = ({ refreshSaga, deleteItemFetching, toastr }: IDeleteItemSagaParams) =>
  function* ({ meta, payload }: IAction) {
    if (!meta?.widget) {
      throw new Error(`meta.widget is required param`);
    }

    const request = deleteItemFetching[meta.widget];
    if (!request) {
      throw new Error(`Request callback does not found in deletingRequest by widget ${meta.widget}`);
    }

    yield refreshSaga({
      request: () => request(payload),
      onSuccess: function* (resp: any) {
        if (meta?.onSuccess) meta.onSuccess(resp);

        yield put({ type: WIDGET_LIST_REQUESTED, meta });
        yield put({ type: WIDGET_REMOVE_SUCCEEDED, meta, payload });
        toastr.success(
          i18n.t(`common.success`),
          i18n.t(meta.succeededDeletingMessage || `widgets.itemHasBeenSuccessfullyDeleted`)
        );
      },
      onFinally: function* () {
        if (meta?.onFinally) meta.onFinally();

        yield put({ type: WIDGET_RESET_MODAL, meta });
      },
    });
  };

interface IDeleteListItemSagaParams {
  refreshSaga: (params: IRefreshSagaParams) => Generator;
  multiDeleteFetching: IApiFetching;
  toastr: ToastrEmitter;
}

const getMultiDeletingSaga = ({ multiDeleteFetching, refreshSaga, toastr }: IDeleteListItemSagaParams) =>
  function* ({ meta, payload }: IAction) {
    if (!meta?.widget) {
      throw new Error(`meta.widget is required param`);
    }

    const request = multiDeleteFetching[meta.widget];
    if (!request) {
      throw new Error(`Request callback does not found in deletingRequest by widget ${meta.widget}`);
    }

    const oneItemSent = size(payload) === 1;

    yield refreshSaga({
      request: () => request(payload),
      onSuccess: function* (resp: any) {
        if (meta?.onSuccess) meta.onSuccess(resp);

        let listToRemove = payload;

        const status = resp?.status;
        const errors = resp?.data?.errors;
        if (status === 220 && !isEmpty(errors)) {
          const notDeletedItems = map(errors, (error) => error?.target);
          listToRemove = difference(listToRemove, notDeletedItems);

          if (oneItemSent) {
            const errorMessage = get(errors, `[0].message`);
            toastr.error(i18n.t(`common.error`), i18n.t(meta.warningDeletingMessage || errorMessage));
          } else {
            toastr.warning(
              i18n.t(`common.warning`),
              i18n.t(meta.warningDeletingMessage || `widgets.someOfTheItemsHaveNotBeenDeleted`)
            );
          }
        } else {
          toastr.success(
            i18n.t(`common.success`),
            i18n.t(meta.succeededDeletingMessage || `widgets.itemsHaveBeenSuccessfullyDeleted`)
          );
        }
        if (!isEmpty(listToRemove)) {
          yield put({ type: WIDGET_REMOVE_MULTIPLE_ITEMS_SUCCEEDED, meta, payload: new Set(listToRemove) });
        }
        yield put({ type: WIDGET_RESET_SELECT_LIST, meta });
      },
      onFinally: function* () {
        if (meta?.onFinally) meta.onFinally();

        yield put({ type: WIDGET_RESET_MODAL, meta });
      },
    });
  };

const resetModalSideEffect = (payload: IAction) => {
  if (!payload?.meta?.notChangeBodyOverflowForModal) {
    document.body.style.overflow = `auto`;
  }
};

const setModalSideEffect = (payload: IAction) => {
  if (!payload?.meta?.notChangeBodyOverflowForModal) {
    document.body.style.overflow = `hidden`;
  }
};

export interface IWidgetSagasParams {
  refreshSaga: any;
  listFetching?: IApiFetching;
  itemFetching?: IApiFetching;
  createFetching?: IApiFetching;
  updateListItemFetching?: IApiFetching;
  updateItemFetching?: IApiFetching;
  deleteItemFetching?: IApiFetching;
  multiDeleteFetching?: IApiFetching;
  toastr: ToastrEmitter;
}

const widgetSagas = ({
  refreshSaga,
  listFetching = {},
  itemFetching = {},
  createFetching = {},
  updateListItemFetching = {},
  updateItemFetching = {},
  deleteItemFetching = {},
  multiDeleteFetching = {},
  toastr,
}: IWidgetSagasParams): ForkEffect[] => {
  const loadListSaga = getListLoadingSaga({ listFetching, refreshSaga, toastr });
  const loadItemSaga = getItemLoadingSaga({ itemFetching, refreshSaga, toastr });
  const createItemSaga = getItemCreatingSaga({ createFetching, refreshSaga, toastr });
  const updateListItemSaga = getListItemUpdatingSaga({ updateListItemFetching, refreshSaga, toastr });
  const updateItemSaga = getItemUpdatingSaga({ updateItemFetching, refreshSaga, toastr });
  const deleteItemSaga = getItemDeletingSaga({ deleteItemFetching, refreshSaga, toastr });
  const multiDeletingSaga = getMultiDeletingSaga({ multiDeleteFetching, refreshSaga, toastr });

  return [
    takeEvery<IAction>(WIDGET_LIST_REQUESTED, loadListSaga),
    takeEvery<IAction>(WIDGET_ITEM_REQUESTED, loadItemSaga),
    takeEvery<IAction>(WIDGET_CREATE_REQUESTED, createItemSaga),
    takeEvery<IAction>(WIDGET_LIST_ITEM_UPDATE_REQUESTED, updateListItemSaga),
    takeEvery<IAction>(WIDGET_ITEM_UPDATE_REQUESTED, updateItemSaga),
    takeEvery<IAction>(WIDGET_REMOVE_REQUESTED, deleteItemSaga),
    takeEvery<IAction>(WIDGET_REMOVE_MULTIPLE_ITEMS_REQUESTED, multiDeletingSaga),
    takeEvery<IAction>(WIDGET_RESET_MODAL, resetModalSideEffect),
    takeEvery<IAction>(WIDGET_SET_MODAL, setModalSideEffect),
  ];
};

export default widgetSagas;
