import React, { FC } from 'react';
import { useCurrentFormTypeCtx, CurrentFormTypeCtx } from './useCurrentFormTypeCtx';
import { useCurrentPaymentSystemCtx, CurrentPaymentSystemCtx } from './useCurrentPaymentSystemCtx';
import { useFormTypeCtx, FormTypeCtx } from './useFormTypesCtx';
import { useHintsCtx, HintsCtx } from './useHintsCtx';
import { useLanguagesCtx, LangsuagesCtx } from './useLanguagesCtx';
import { useModifiedHintsCtx, ModifiedHintsCtx } from './useModifiedHintsCtx';
import { usePaymentSystemsCtx, PaymentSystemsCtx } from './usePaymentSystemsCtx';
import { useSaveModalCtx, SaveModalCtx } from './useSaveModalCtx';
import { FormWasSubmittedCtx, useFormWasSubmittedCtx } from './useFormWasSubmitted';

export {
  useCurrentFormTypeCtx,
  useCurrentPaymentSystemCtx,
  useFormTypeCtx,
  useHintsCtx,
  useLanguagesCtx,
  useModifiedHintsCtx,
  usePaymentSystemsCtx,
  useSaveModalCtx,
  useFormWasSubmittedCtx,
};

export const DefaultTranslates: FC = ({ children }) => {
  return (
    <SaveModalCtx>
      <FormWasSubmittedCtx>
        <ModifiedHintsCtx>
          <CurrentPaymentSystemCtx>
            <CurrentFormTypeCtx>
              <FormTypeCtx>
                <LangsuagesCtx>
                  <HintsCtx>
                    <PaymentSystemsCtx>{children}</PaymentSystemsCtx>
                  </HintsCtx>
                </LangsuagesCtx>
              </FormTypeCtx>
            </CurrentFormTypeCtx>
          </CurrentPaymentSystemCtx>
        </ModifiedHintsCtx>
      </FormWasSubmittedCtx>
    </SaveModalCtx>
  );
};
