import { WidgetType } from '../../utils/enums';
import { widgetActionCreators } from '@kassma-team/kassma-toolkit';
import { IWidgetActionCreators } from '@kassma-team/kassma-toolkit/lib';
import { IWidgetsLabel } from '../types';
import { CREATE_WALLET_FORM_NAME, UPDATE_WALLET_FORM_NAME } from '../../utils/constants';

export const walletPayloadLabels: IWidgetLabels = { itemLabel: `data`, listLabel: `data` };

export const walletActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.WALLETS,
  labels: walletPayloadLabels,
  listItemUpdatingForm: UPDATE_WALLET_FORM_NAME,
  creatingForm: CREATE_WALLET_FORM_NAME,
  succeededDeletingMessage: `wallets.walletHasBeenSuccessfullyDisabled`,
});

interface IWidgetLabels extends IWidgetsLabel {
  withPagination?: boolean;
  listLabel?: string;
  itemLabel?: string;
}
