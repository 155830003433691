import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLocation, RouterRootState } from 'connected-react-router';
import {
  Widget as KassmaWidget,
  tablesPerPageSelector,
  widgetListLoadingSelector,
  widgetListSelector,
  hiddenColumnsSelector,
  PAGINATOR_FORM_NAME,
  widgetModalSelector,
  widgetSelector,
  IWidgetProps,
} from '@kassma-team/kassma-toolkit/lib';
import { getFormValues } from 'redux-form';

import useSearchParams from 'hooks/useSearchParams';

import FormField from 'components/form/FormField';
import Header from 'components/header/Header';
import ReduxFormPaginator from 'components/widgets/ReduxFormPaginator';
import RouteTabs from 'components/widgets/RouteTabs';

const Widget = ({ widget, routeTabs, ...props }: IWidgetProps) => {
  const dispatch = useDispatch();

  const perPage = useSelector(tablesPerPageSelector);
  const listLoading = useSelector(widgetListLoadingSelector(widget));
  const items = useSelector(widgetListSelector(widget));
  const widgetState = useSelector(widgetSelector(widget));

  const columnsSelector = useMemo(() => hiddenColumnsSelector(widget), [widget]);
  const hiddenColumns = useSelector(columnsSelector);

  const location = useSelector<RouterRootState<unknown>>(getLocation);
  const paginatorValues = useSelector(getFormValues(PAGINATOR_FORM_NAME)) || {};

  const modal = useSelector(widgetModalSelector(widget));

  const search = useSearchParams();

  return (
    <KassmaWidget
      {...props}
      dispatch={dispatch}
      perPage={perPage}
      listLoading={listLoading}
      items={items}
      hiddenColumns={hiddenColumns}
      location={location}
      paginatorValues={paginatorValues}
      modal={modal}
      search={search}
      FormField={FormField}
      widgetState={widgetState}
      header={<Header />}
      ReduxFormPaginator={ReduxFormPaginator}
      routeTabs={<RouteTabs routeTabs={routeTabs} />}
      widget={widget}
    />
  );
};

export default Widget;
