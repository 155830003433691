export enum SymbolPosition {
  LEFT = 1,
  RIGHT = 2,
}

export interface IIntranetCurrency extends IIntranetCurrencyFormData {
  created_at: string;
  updated_at: string;
  rate: {
    rate: string;
    source: number;
    created_at: string;
    updated_at: string;
  };
}

export interface IIntranetCurrencyRowProps extends IIntranetCurrency {
  desktopSize: boolean;
  hiddenColumns: string[];
}

export interface IIntranetCurrencyFormData {
  code: string;
  symbol: string;
  name: string;
  is_crypto: boolean;
  symbol_position: SymbolPosition;
}
