import axios, { AxiosRequestConfig } from 'axios';
import queryString from 'query-string';
import size from 'lodash/size';
import each from 'lodash/each';
import isArray from 'lodash/isArray';
import split from 'lodash/split';
import takeRight from 'lodash/takeRight';
import join from 'lodash/join';
import { Store } from 'redux';
import i18n from '../i18n';

import { tokenSelector } from 'selectors/auth';
import { getNormalizedUrl } from 'utils';

const getFormData = (data: Record<string, unknown> = {}): FormData => {
  const formData = new FormData();
  each(data, (value: any, key: string) => {
    if (isArray(value)) {
      value = JSON.stringify(value);
    }
    formData.append(key, value);
  });

  return formData;
};

interface IApiUrlParams {
  apiUrl: string;
  useTheSameDomainNameForApi?: boolean | `true` | `false`;
  subDomain?: string;
  protocol?: string;
}

const getApiUrl = ({ apiUrl, useTheSameDomainNameForApi, subDomain, protocol }: IApiUrlParams) => {
  if ((useTheSameDomainNameForApi === true || useTheSameDomainNameForApi === `true`) && subDomain && protocol) {
    let host: string = window.location.host;
    let hostData = split(host, `.`);
    hostData = takeRight(hostData, 2);
    host = join(hostData, `.`);

    return `${protocol}://${subDomain}.${host}/`;
  }

  return getNormalizedUrl(apiUrl);
};

export interface ICreateApiFetchOptions extends AxiosRequestConfig {
  withToken?: boolean;
  queryParams?: any;
  formData?: boolean;
  needRefresh?: boolean;
}

export type AxiosMiddleware = (options: ICreateApiFetchOptions, ...args: any[]) => ICreateApiFetchOptions;

export interface ICreateApiFetchParams {
  defaultParams?: AxiosRequestConfig;
  apiUrlParams: IApiUrlParams;
  store: Store;
  middlewares?: AxiosMiddleware[];
}

const createApiFetch =
  ({ defaultParams = {}, apiUrlParams, store, middlewares }: ICreateApiFetchParams) =>
  (url: string, options: ICreateApiFetchOptions = {}) => {
    const { withToken = true, queryParams = {}, formData = false, needRefresh = true, ...axiosRequestConfig } = options;

    const _queryParams = { ...queryParams };

    options = {
      method: `GET`,
      headers: { 'Content-Type': `application/json` },
      baseURL: getApiUrl(apiUrlParams),
      validateStatus: (status: number): boolean => (status >= 200 && status < 300) || (needRefresh && status === 401),
      ...defaultParams,
      ...axiosRequestConfig,
    };

    if (withToken) {
      const token = tokenSelector(store.getState()) || ``;
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    if (formData) {
      options.data = getFormData(options.data);
      options.headers = { ...options.headers, 'Content-Type': `multipart/form-data` };
    }

    if (i18n.language) {
      options.headers = {
        ...options.headers,
        'X-Current-Language': i18n.language,
      };
    }

    if (size(_queryParams) > 0) {
      url += `?${queryString.stringify(_queryParams, { arrayFormat: `bracket` })}`;
    }

    if (middlewares) {
      for (const middleware of middlewares) {
        options = middleware(options);
      }
    }

    return axios(url, options);
  };

export default createApiFetch;
