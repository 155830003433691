import { BaseApi } from '../common/BaseApi';
import { AxiosPromise } from 'axios';
import { IAxiosResponseData } from '@kassma-team/kassma-toolkit';

interface IPSFilterItem {
  id: number;
  name: string;
}

interface IPSFilterListResponse extends IAxiosResponseData {
  data: IPSFilterItem[];
}

export class PaymentSystemsApi extends BaseApi {
  loadPSFilterList = (): AxiosPromise<IPSFilterListResponse> => this.makeFetch(`for-filter`);
}

const paymentSystemsApi = new PaymentSystemsApi(`payment-systems`);

export default paymentSystemsApi;
