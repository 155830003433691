import { FC, useContext, createContext } from 'react';
import { useHints, UseHints } from '../hooks';

type useHintsCtx = UseHints;

const ctx = createContext<useHintsCtx | undefined>(undefined);

export const HintsCtx: FC = ({ children }) => {
  const {
    hints,
    isLoading,
    handeDeleteHint,
    isHintUpdating,
    handleUpdateHint,
    handleCreateHint,
    isHintCreating,
    isHintDeleting,
  } = useHints();

  const value: useHintsCtx = {
    hints,
    isLoading,
    handeDeleteHint,
    handleUpdateHint,
    handleCreateHint,
    isHintUpdating,
    isHintCreating,
    isHintDeleting,
  };

  return <ctx.Provider value={value}>{children}</ctx.Provider>;
};

export function useHintsCtx(): useHintsCtx {
  const context = useContext(ctx);

  if (context === undefined) {
    throw new Error(`Is need to use useHintsCtx inside a HintCtx component`);
  }

  return context;
}
