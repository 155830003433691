import { takeEvery, put, select } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import includes from 'lodash/includes';
import {
  setLang,
  AUTH_CHECK_REQUESTED,
  AUTH_CHECK_SUCCEEDED,
  AUTH_ERROR,
  SET_TOKEN,
} from '@kassma-team/kassma-toolkit/lib';

import i18n from '../i18n';
import getSystemLang from 'utils/getSystemLang';
import { Permission } from 'utils/enums';
import refreshSaga from 'sagas/effects/refreshSaga';
import { currencyActionCreators } from 'actions/widgets/currency';
import { filterCounterpartyListActionCreators } from 'actions/widgets/counterparties';
import { filterListPSActionCreators } from 'actions/widgets/paymentSystems';
import authApi from '../api/auth/AuthApi';
import getAuthSagas from './effects/getAuthSagas';
import { userPermissionsSelector } from '../selectors/auth';

const REQUIRED_CONFIG_KEYS = [`apiUrl`];

function* fetchDataOnLogin() {
  yield put(currencyActionCreators.getList());
  const permissions: string[] | null = yield select(userPermissionsSelector);
  if (includes(permissions, Permission.PAYMENT_SYSTEM_LIST)) {
    yield put(filterListPSActionCreators.getList());
    yield put(filterCounterpartyListActionCreators.getList());
  }
}

function* onLoggedIn() {
  yield fetchDataOnLogin();
}

function* authCheckSaga() {
  const lang = getSystemLang(localStorage.getItem(`lang`));
  i18n.changeLanguage(lang);
  yield put(setLang(lang));

  const token: string = yield localStorage.getItem(`token`);
  if (token) {
    yield put({ type: SET_TOKEN, payload: token });
    yield refreshSaga({
      request: authApi.checkAuth,
      onSuccess: function* (resp: any) {
        const { data } = resp;
        if (data.status === `error`) {
          yield put({ type: AUTH_ERROR, payload: data.error_message });
        } else if (!data || !data.user) {
          throw new Error(`Invalid response`);
        } else {
          const refresh_token = localStorage.getItem(`refresh_token`);
          yield put({ type: AUTH_CHECK_SUCCEEDED, payload: { user: data.user, refresh_token } });
          yield onLoggedIn();
        }
      },
      onError: function* (e: Error) {
        yield put({ type: AUTH_ERROR, payload: e?.message });
      },
    });
  } else {
    yield put({ type: AUTH_ERROR, payload: `No token` });
  }
}

const authSagas: any = [
  ...getAuthSagas({
    fetchDataOnLogin,
    login: authApi.login,
    refreshToken: authApi.refreshToken,
    logout: authApi.logout,
    toastr,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    requiredKeys: REQUIRED_CONFIG_KEYS,
  }),
  takeEvery(AUTH_CHECK_REQUESTED, authCheckSaga),
];

export default authSagas;
