import { ReactElement } from 'react';
import useMount from 'react-use/lib/useMount';
import { useDispatch } from 'react-redux';

import { initApp } from '@kassma-team/kassma-toolkit/lib';

const AuthChecking = (): ReactElement => {
  const dispatch = useDispatch();

  useMount(() => {
    dispatch(initApp());
  });

  return <div id="page-loader" className="show" />;
};

export default AuthChecking;
