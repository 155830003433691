import { FC, useContext, createContext } from 'react';
import { useLanguages, UseLanguages } from '../hooks';

type UseLanguagesCtx = UseLanguages;

const ctx = createContext<UseLanguagesCtx | undefined>(undefined);

export const LangsuagesCtx: FC = ({ children }) => {
  const { languages, isLoading } = useLanguages();

  const value: UseLanguagesCtx = {
    languages,
    isLoading,
  };

  return <ctx.Provider value={value}>{children}</ctx.Provider>;
};

export function useLanguagesCtx(): UseLanguagesCtx {
  const context = useContext(ctx);

  if (context === undefined) {
    throw new Error(`Is need to use useLangsCtx inside a LanguagesCtx component`);
  }

  return context;
}
