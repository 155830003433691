import React from 'react';

import { WidgetType } from 'utils/enums';
import Message from './Message';
import { logsActionCreators } from '../../../../actions/widgets/logs';

const LogMessage = () => {
  return <Message widget={WidgetType.LOGS} actionCreators={logsActionCreators} />;
};

export default LogMessage;
