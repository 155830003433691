import { ReactElement, useEffect, useState, useCallback } from 'react';
import { Dispatch } from 'redux';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import useMount from 'react-use/lib/useMount';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import useUnmount from 'react-use/lib/useUnmount';
import get from 'lodash/get';
import omit from 'lodash/omit';
import reduce from 'lodash/reduce';
import isString from 'lodash/isString';
import size from 'lodash/size';
import { Preloader, IFormField, FieldType, InputType } from '@kassma-team/kassma-toolkit/lib';
import classNames from 'classnames';

import { WidgetType } from 'utils/enums';
import { CREATE_COUNTERPARTY_FORM_NAME } from 'utils/constants';
import {
  counterpartiesActionCreators,
  getCounterpartyCreatingData,
  resetCounterpartyCreatingData,
} from 'actions/widgets/counterparties';

import { ICounterpartyCreatingFormData, ICounterpartyCreatingRequestData } from 'interfaces/widgets/counterparties';

import {
  counterpartyAvailableDomainsSelector,
  counterpartyAvailableServersSelector,
  counterpartyCreatingDataErrorSelector,
  counterpartyCreatingDataLoadingSelector,
  counterpartyCreatingDataSelector,
  counterpartyFileTemplateNamesSelector,
  counterpartyFileTemplatePathsSelector,
  counterpartyScrapersSelector,
} from 'selectors/widgets/counterparties';

import ModalForm from 'components/modals/ModalForm';
import ModalElement from 'components/modals/ModalElement';
import FormField from 'components/form/FormField';

const dataProvider = (data: ICounterpartyCreatingFormData): ICounterpartyCreatingRequestData | null => {
  if (isEmpty(data)) {
    return null;
  }

  let transformedData: ICounterpartyCreatingRequestData = { ...omit(data, [`templates`, `scrapers`]) };

  const templates = reduce(
    data.templates,
    (result, item, index) => {
      const template = item?.template;
      if (isString(template)) {
        return result;
      }

      return {
        ...result,
        [`template[${index}]`]: template,
      };
    },
    {}
  );
  if (!isEmpty(templates)) {
    transformedData = {
      ...transformedData,
      ...templates,
    };
  }

  const scrapers = reduce(
    data.scrapers,
    (result, scraper, index) => {
      return {
        ...result,
        [`scrapers[${index}]`]: scraper,
      };
    },
    {}
  );
  if (!isEmpty(scrapers)) {
    transformedData = {
      ...transformedData,
      ...scrapers,
    };
  }

  return transformedData;
};

const onSubmit = (values: ICounterpartyCreatingFormData, dispatch: Dispatch) => {
  dispatch(counterpartiesActionCreators.create(dataProvider(values)));
};

const modalTitle = `counterparties.counterpartyCreating`;

const useTemplateFields = () => {
  const [t] = useTranslation();

  const templateNames = useSelector(counterpartyFileTemplateNamesSelector);

  return useCallback(
    ({ fields }) => {
      return (
        <div className="mb-15">
          {fields.map((field: string, index: number) => {
            return (
              <div key={index} className={classNames(`flex space-between mb-10 align-items-start`)}>
                <FormField
                  arrayField
                  formGroup={false}
                  name={`${field}.template`}
                  type={InputType.SIMPLE_FILE}
                  defaultFileName={get(templateNames, index as number)}
                />
              </div>
            );
          })}
        </div>
      );
    },
    [t, templateNames]
  );
};

const CounterpartyCreating = (props: InjectedFormProps<ICounterpartyCreatingFormData>): ReactElement => {
  const dispatch = useDispatch();

  const [t] = useTranslation();

  const creatingErrorMessage = useSelector(counterpartyCreatingDataErrorSelector);
  const creatingData = useSelector(counterpartyCreatingDataSelector);
  const loading = useSelector(counterpartyCreatingDataLoadingSelector);
  const availableServers = useSelector(counterpartyAvailableServersSelector);
  const availableDomains = useSelector(counterpartyAvailableDomainsSelector);
  const scrapers = useSelector(counterpartyScrapersSelector);
  const templatePaths = useSelector(counterpartyFileTemplatePathsSelector);
  const templateNames = useSelector(counterpartyFileTemplateNamesSelector);

  useMount(() => {
    dispatch(getCounterpartyCreatingData());
  });

  useUnmount(() => {
    dispatch(resetCounterpartyCreatingData());
  });

  const [fields, setFields] = useState<IFormField[]>([]);

  const templates = creatingData?.templates;

  useEffect(() => {
    props.change(`templates`, templatePaths);
  }, [templatePaths]);

  const values = useSelector(getFormValues(props.form)) as ICounterpartyCreatingFormData;

  const renderTemplates = useTemplateFields();

  const auto_installation = values?.auto_installation;

  useEffect(() => {
    props.change(`domain`, null);
  }, [auto_installation]);

  useEffect(() => {
    if (!isEmpty(availableServers) && !isEmpty(availableDomains)) {
      const _fields: IFormField[] = [
        {
          name: `auto_installation`,
          type: `checkbox`,
          label: t(`counterparties.autoInstallation`),
          description: t(`counterparties.autoInstallationDescr`),
          disabled: true,
        },
        ...(auto_installation
          ? [
              {
                name: `identifier`,
                label: t(`counterparties.identifier`),
                description: t(`counterparties.identifierDescr`),
                maxLength: 190,
              },
              {
                name: `domain_id`,
                data: availableDomains,
                label: t(`counterparties.domainName`),
                required: true,
                description: t(`counterparties.domainDescr`),
                type: `select`,
              },
              {
                name: `server_id`,
                data: availableServers,
                label: t(`counterparties.server`),
                required: true,
                description: t(`counterparties.serverDescr`),
                type: `select`,
              },
              {
                name: `login`,
                required: true,
                maxLength: 190,
                label: t(`counterparties.login`),
                description: t(`counterparties.loginDescr`),
              },
              ...(size(templateNames) > 0
                ? [
                    {
                      component: renderTemplates as any,
                      name: `templates`,
                      fieldType: FieldType.FIELD_ARRAY,
                    },
                  ]
                : []),
              {
                name: `scrapers`,
                title: t(`counterparties.scrapers`),
                data: scrapers,
                fieldType: FieldType.CHECKBOX_GROUP,
              },
            ]
          : [
              {
                name: `identifier`,
                label: t(`counterparties.identifier`),
                maxLength: 190,
              },
              {
                name: `domain`,
                label: t(`counterparties.domainName`),
                required: true,
                link: true,
              },
            ]),
      ];

      if (!isEqual(_fields, fields)) {
        setFields(_fields);
      }
    }
  }, [availableServers, availableDomains, scrapers, templates, auto_installation, templateNames]);

  let content;
  let showForm = false;
  if (creatingErrorMessage && !creatingData) {
    content = <span className="text-danger text-center">{creatingErrorMessage}</span>;
  } else if (loading) {
    content = <Preloader />;
  } else if (!isEmpty(creatingData)) {
    showForm = true;
  }

  if (showForm) {
    return (
      <ModalForm
        {...props}
        onSubmit={onSubmit}
        actionCreators={counterpartiesActionCreators}
        submitText="common.create"
        fields={fields}
        title={modalTitle}
        widget={WidgetType.COUNTERPARTIES}
      />
    );
  } else {
    return <ModalElement title={modalTitle} actionCreators={counterpartiesActionCreators} content={content} />;
  }
};

export default reduxForm({ form: CREATE_COUNTERPARTY_FORM_NAME })(CounterpartyCreating as any);
