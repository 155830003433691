import { createSelector } from 'reselect';

import { Permission } from 'utils/enums';
import { IRootState } from 'interfaces/common';

export enum AuthStatus {
  LOGGED_IN,
  CHECKING,
  GUEST,
}

interface ITableHiddenColumns {
  [table: string]: string[];
}

export interface IAuthUser {
  id: number;
  name: string;
  roles: string[];
  hidden_columns: ITableHiddenColumns;
}

export interface IAuthState {
  user: IAuthUser | null;
  password: IAuthUser | null;
  token: string | null;
  refresh_token: string | null;
  status: AuthStatus;
  error: string | null;
  refreshTokenPromise: Promise<any> | null;
  lang?: string;
  perPage: number;
}

export const authSelector = (state: IRootState): IAuthState => state.auth;

export const tokenSelector = createSelector<IRootState, IAuthState, string | null>(authSelector, ({ token }) => token);

export const refreshTokenSelector = createSelector<IRootState, IAuthState, string | null>(
  authSelector,
  ({ refresh_token }) => refresh_token
);

export const userSelector = createSelector<IRootState, IAuthState, IAuthUser | null>(authSelector, ({ user }) => user);
export const passwordSelector = createSelector<IRootState, IAuthState, IAuthUser | null>(
  authSelector,
  ({ password }) => password
);

export const userPermissionsSelector = createSelector<IRootState, IAuthUser | null, string[]>(userSelector, (user) =>
  user ? user.roles : []
);

export const hasAccessSelector = (permission: Permission) =>
  createSelector<IRootState, string[] | null, boolean>(userPermissionsSelector, (roles) => {
    if (roles) {
      return roles.includes(permission);
    }

    return false;
  });
